import { Paper, Typography, Button, Stack, Box } from '@mui/material'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import NoDataYet from 'components/Atoms/NoDataYet';
import { chargeWalletModel, walletFilterByModel } from 'services/Modules/Models/Actions';
import { useDispatch } from 'react-redux';

export default function WalletCardAndListing({ transactionsData }) {
    const { blackTwo, grayfive, red, greenTwo } = useThemePalette()
    const dispatch = useDispatch()
    return <>
        <Paper sx={{ py: '48px', boxShadow: 'none', borderRadius: '20px', mb: '32px' }}>
            <Stack alignItems='center' color={blackTwo} rowGap='48px'>
                <Typography fontWeight='600' fontSize='22px' >Current balance</Typography>
                <Typography fontWeight='700' fontSize='42px' >{transactionsData?.balance} EGP</Typography>
                <Button onClick={_ => dispatch(chargeWalletModel())} variant='outlined' sx={{ px: '100px' }} >Charge wallet</Button>
        </Stack>
    </Paper >

        <Paper sx={{ py: '48px', boxShadow: 'none', borderRadius: '20px', px: '32px' }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                <Typography color={blackTwo} fontWeight='600' fontSize='22px' >Recent transactions</Typography>
                <Button
                    sx={{ fontSize: '17px' }}
                    endIcon={<KeyboardArrowDownIcon sx={{ ml: '43px' }} />}
                    variant='contained'
                    onClick={_ => dispatch(walletFilterByModel())}
                >
                    Filtered by:
                </Button>
            </Stack>
            {transactionsData?.transactions_history?.length == 0 ? <NoDataYet data='transactions' /> : transactionsData?.transactions_history?.map((data) =>
                <Box sx={{ border: `1px solid ${grayfive}`, p: '32px', borderRadius: '10px', my: '24px' }} >
                    <Stack direction='row' justifyContent='space-between' mb='16px' >
                        <Typography color={blackTwo} fontSize='22px' fontWeight='600' >Algebra and mathematics theories course </Typography>
                        <Typography color={data?.source == 'Refund' ? greenTwo : red} fontWeight='700' fontSize='22px'>{data?.source == 'Refund' ? '+' : '-'} {data?.amount} currency</Typography>
                    </Stack>
                    <Typography sx={{ color: blackTwo, opacity: '0.4', fontWeight: '600', fontSize: '22px' }} >{data?.created_at}</Typography>
                </Box>
            )}
        </Paper >
    </>
}
