import React from "react";
import FeaturesTemplate from "Template/LandingPageTemplate/Features/FeaturesTemplate";

export default function FeaturesPage() {
  
  
    return (
    <div>
      <FeaturesTemplate 
      /> 
    </div>
  );
};


