import Types from "./Types";

const ZOOM_INIT_STATE = {
  zoomCred: {},
  meetingInfo:{},
  isLoading: {
    type: "",
    loading: false,
  },
};

export function zoomReducer(state = ZOOM_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.ZOOM_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.GET_ZOOM_CRED_SUCCESS: {
      return {
        ...state,
        zoomCred: payload?.data,
      };
    }
    case Types.GET_MEETING_INFO_SUCCESS: {
      return {
        ...state,
        meetingInfo: payload?.data,
      };
    }
    default: {
      return state;
    }
  }
}
