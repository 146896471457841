/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import React from "react";
import CoursesCard from "../../Atoms/SharedComponents/Cards/CoursesCard";

export default function TopCategoriesCards({
  isActive,
  error,
  finalData,
  clickedSubject,
  studentSavedList,
}) {
  return (
    <Stack
      width="100%"
      flexDirection={{ sm: "column", md: "row" }}
      justifyContent="center"
      gap={2}
      alignItems="center"
      marginTop="2rem"
      flexWrap="wrap"
    >
      {/* -------------------------------- Maping into final data to display course details ------------------------ */}
      {finalData?.map((cardData, i) => (
        <Stack key={i} width={{ xs: "100%", md: "22%" }}>
          <CoursesCard
            studentSavedList={studentSavedList}
            url={`/website/courses-details/${cardData?.classified_product?.course_id}?subject_name=${clickedSubject?.name}&subject_id=${clickedSubject?.id}`}
            data={cardData}
          />
        </Stack>
      ))}
    </Stack>
  );
}
