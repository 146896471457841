const CoursesType = {
  GET_ALL_COURSES_SUCCESS: "GET_ALL_COURSES_SUCCESS",
  COURSES_LOADING: "COURSES_LOADING",
  GET_ALL_COURSES_ERROR: "GET_ALL_COURSES_ERROR",
  GET_STUDENT_COURSES_DETAILS_SUCCESS: "GET_STUDENT_COURSES_DETAILS_SUCCESS",
  GET_STUDENT_SECTIONS_SUCCESS: "GET_STUDENT_SECTIONS_SUCCESS",
  GET_STUDENT_SESSIONS_SUCCESS: "GET_STUDENT_SESSIONS_SUCCESS",
  GET_ALL_REVIEWS_SUSSESS: "GET_ALL_REVIEWS_SUSSESS",
  GET_STUDENT_SESSION_DETAILS_SUCCESS: "GET_STUDENT_SESSION_DETAILS_SUCCESS",
  GET_COURSES_LIST_SUCCESS: "GET_COURSES_LIST_SUCCESS",
  GET_COURSES_LIST_ERROR: "GET_COURSES_LIST_ERROR",
  GET_STUDENT_QUIZ_DETAILS_SUCCESS: "GET_STUDENT_QUIZ_DETAILS_SUCCESS",
  SUBMIT_ASSIGNMENT_SUCCESS: "SUBMIT_ASSIGNMENT_SUCCESS",
  GET_STUDENT_EVALUATION_SUCCESS: "GET_STUDENT_EVALUATION_SUCCESS",
  GET_STUDENT_ASSIGNMENT_DETAILS_SUCCESS: "GET_STUDENT_ASSIGNMENT_DETAILS_SUCCESS",
  STUDENT_START_QUIZ_SUCCESS: "STUDENT_START_QUIZ_SUCCESS",
  STUDENT_ANSWER_QUIZ_SUCCESS: "STUDENT_ANSWER_QUIZ_SUCCESS",
  CLEAR_STUDENT_ANSWER_QUIZ_SUCCESS: "CLEAR_STUDENT_ANSWER_QUIZ_SUCCESS",
  STUDENT_SUBMIT_QUIZ_SUCCESS: "STUDENT_SUBMIT_QUIZ_SUCCESS",
  GET_STUDENT_QUIZ_ATTEMPTS_SUCCESS: "GET_STUDENT_QUIZ_ATTEMPTS_SUCCESS",
  GET_GUEST_COUESE_DETAILS_SUCCESS: "GET_GUEST_COUESE_DETAILS_SUCCESS",
  COMPLETE_SESSION_SUCCESS: "COMPLETE_SESSION_SUCCESS",
  GET_STUDENT_SAVED_LIST_SUCCESS: "GET_STUDENT_SAVED_LIST_SUCCESS",
  ADD_COURSE_TO_SAVED_LIST_SUCCESS: "ADD_COURSE_TO_SAVED_LIST_SUCCESS",
  REMOVE_COUESE_FORM_SAVED_LIST_FORM_PRODUCT_SUCCESS: "REMOVE_COUESE_FORM_SAVED_LIST_FORM_PRODUCT_SUCCESS",
  REMOVE_COUESE_FORM_SAVED_LIST_FORM_PROFILE_SUCCESS: "REMOVE_COUESE_FORM_SAVED_LIST_FORM_PROFILE_SUCCESS",
  GET_STUDENT_COURSES_LIST_SUCCESS: "GET_STUDENT_COURSES_LIST_SUCCESS",
  SHOW_ALL_REVIEWS_FOR_CURRENT_COURSE_SUSSESS: 'SHOW_ALL_REVIEWS_FOR_CURRENT_COURSE_SUSSESS',
  ADD_REVIEW_SUSSESS: 'ADD_REVIEW_SUSSESS',
};
export default CoursesType;
