import React from "react";
import { Box, Stack, Typography, Container, List, ListItem, ListItemIcon } from "@mui/material";
import featureone from "../../../../Assets/Images/feature1.png";
import featuretwo from "../../../../Assets/Images/feature2.png";
import featurethree from "../../../../Assets/Images/feature3.png";
import featurefour from "../../../../Assets/Images/feature4.png";
import featurefive from "../../../../Assets/Images/feature5.png";
import featuresix from "../../../../Assets/Images/feature6.png";
import { useThemePalette } from "common/hooks/useThemePalatte";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";

export default function AllFeaturesContent() {
  const { warmGray } = useThemePalette();

  const features = [
    {
      id: 0,
      title: "Assignment System",
      subtitle:
        "Provide your students with the best learning experience by giving them the ability to check their progress, chat with their instructors, add their future courses to a wishlist.",
      img: featureone,
      isMobile: false
    },
    {
      id: 1,
      title: "Course Management",
      subtitle:
        "Manage your courses with alittle effort in just A few steps.As an instructor, you have the authrization to add, update, or delet courses each of which is divided into sections.",
      img: featuretwo,
      isMobile: false

    },
    {
      id: 2,
      title: "Quiz System",
      subtitle:
        "Provide your students with the best learning experience by giving them the ability to check assignments. Instructors can upload assignments.",
      img: featurethree,
      isMobile: true

    },
    {
      id: 3,
      title: "Easy payment Collection",
      subtitle: "We have provided an advanced evaluation system.You can upload quizzes and they will be graded automatically.",
      img: featurefour,
      isMobile: true

    },
    {
      id: 4,
      title: "Live session",
      subtitle:
        "We created afunctional, easy to use app including all our e-learning web application features.",
      img: featurefive,
      isMobile: true

    },
    {
      id: 5,
      title: "Secure Steaming Videos",
      subtitle:
        "We have awell qualified it team working hard to apply the highest level of security against fraud.In addition to encrypting all the private data, we lock the “screen recording” and “share screen” features on the user’s device while running videos to protect them from theft.",
      img: featuresix,
      isMobile: true
    },
  ];

  return (
    <CustomContainer>
      <Stack
        justifyContent="space-between"
        alignItems={{ lg: "center", xs: "flex-end" }}
      >
        <Stack >
          {features.map((feature, index) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              key={feature.id}
              sx={{
                width: "100%",
                flexDirection: { md: index % 2 === 0 ? "row" : "row-reverse", xs: "column" }
              }}
            >
            <Box   width={{ xs: "100%", md: "47%" }}>
                <img
                  src={feature.img}
                  alt={feature.title}
                  width={feature.isMobile? "70%" : "100%"}
                  height="auto"
                />
              </Box>
              <Stack width={{ xs: "100%", md: "47%" }} spacing={2} alignItems="flex-start">
                  <Typography variant='h2'
                    marginBottom='1rem'
                    fontSize={{ xs: "30px", md: "40px" }}
                    fontWeight='600'
                  >
                    {feature.title}
                  </Typography>
                  <List sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    "& .MuiListItemIcon-root": {
                      minWidth: "20px",
                    },
                    "& .MuiListItem-root": {
                      alignItems: "baseline",
                      px: 0,
                    },
                  }}
                    aria-label="contacts" >
                    <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography fontSize={{ xs: "16px", md: "24px" }}
                        textAlign='left'
                        fontWeight='400'
                        color={warmGray}>
                        {feature.subtitle}
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      </CustomContainer>
      );
}

