import { createTheme } from '@mui/material'

export const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
        ].join(','),
        h1: {
            fontFamily: [
                'Poppins',
            ].join(','),
            fontSize: '40px',
            fontWeight: '600'
        },
    },
    button: {
        fontFamily: [
            'Poppins',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#A41E22',
        },
        secondary: {
            main: '#DE47471A',
        },
        info: {
            main: '#FFFFFF',
        },
    },
    theme_palette: {
        primaryColor: '#A41E22',
        primaryGradient:'rgba(164, 30, 34, 1)',
        secondryColor: '#DE47471A',
        whiteColor: '#fff',
        warmGray: '#707070',
        warmGray2: '#938f8c',
        warmGray3: '#767676',
        warmGray4: '#9b9b9b',
        green: '#34B7F1',
        whiteTwo: '#f3f3f3',
        whiteThree: '#f8f8f8',
        black:'#000000',
        yellow: '#f7b52e',
        blackTwo: '#343434',
        white: '#fff',
        gray: '#7B7B7B',
        lightGreen: '#808080',
        blackThree: '#151414',
        transparentGray: 'rgba(155, 155, 155, 0.2)',
        transparentBlackTwo: 'rgba(52, 52, 52, 0.6)',
        grayTwo: '#CCCCCC',
        grayThree: '#ECECEC',
        grayFour: '#C7C5C5',
        gray5: '#D9D9D9',
        blackFour: '#525252',
        black5: '#2A313B',
        babyBlue: '#728397',
        transparentPrimaryColor: 'rgba(40, 161, 156, 0.5)',
        grayfive: '#B0B0B0',
        red: '#FF0000',
        greenTwo: '#00AF46',
        greySix:'#C4C4C4',
        
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1366,
            xl: 1536,
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    borderWidth: '2px',
                    "&:hover": {
                        borderWidth: '2px'
                    }
                },
                contained: {
                },
                root: {
                    "&.Mui-disabled": {
                    },
                    '&:hover': {
                    },
                    borderRadius: '5px',
                    fontSize: '16px',
                    fontWeight: '600',
                    textTransform: 'none'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: '#28a19c'
                    },
                }
            }
        },
    }
});

