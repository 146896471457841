import React from "react";
import LearningContent from "./LearningContent";
import FrequentSkelton from "../../Atoms/SharedComponents/FrequentSkelton";
import { Box, Button, Container } from "@mui/material";

export default function Learning() {
  return (
    <>
      {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
      <FrequentSkelton  backgroundColor="white"
        title="Learning Process"
        subtitle="Learn the latest skills to reach your professional goals"
      ></FrequentSkelton>
      {/* ------------------------------ Display Static Img and Title ----------------------- */}
      <Container sx={{ 
         maxWidth: { xl: "xl", md: "lg" },
       }}  
       disableGutters>
                <LearningContent />
      </Container>
    </>
  );
}
