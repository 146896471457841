import Types from "./Types";

export const signupSuccess = (payload) => ({
  type: Types.SIGN_UP_SUCCESS,
  payload,
});

export const authLoading = (payload) => ({
  type: Types.AUTH_LOADING,
  payload,
});
