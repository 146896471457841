import React from 'react'
import ModelBox from '../../Atoms/Model/ModalBox'
import { useDispatch, useSelector } from 'react-redux'
import { changePasswodModal } from '../../../services/Modules/Models/Actions'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useFormik } from 'formik';
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { useThemePalette } from '../../../common/hooks/useThemePalatte';
import { useState } from 'react';
import { changePasswordSchema } from './validation';
import { useSearchParams } from 'react-router-dom';
import { changePasswordRequest } from '../../../services/Modules/Auth';
import PaperComponent from '../../Atoms/Paper/PaperComponent';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import { useEffect } from 'react';

export default function ChangePasswordForm() {

    const { isChangePassword } = useSelector(state => state?.model)
    const dispatch = useDispatch()
    const { blackTwo } = useThemePalette();
    const [showCurrentPassword, setShowCurrentPassword] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmationPassword = () => setShowConfirmationPassword((show) => !show);
    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const [isPasswordChages, setIsPasswordChanged] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // const test = (event) => {
    //     event.preventDefault();
    // };

    // useEffect(() => {
    //     isPasswordChages && formik.setValues({ ...initialValues })
    // }, [isPasswordChages])

    const initialValues = {
        current_password: '',
        password: "",
        password_confirmation: '',
    }

    const onSubmit = (values) => {
        const obj = {
            "user": {
                "current_password": values?.current_password,
                "password": values?.password,
                "password_confirmation": values?.password_confirmation
            }
        }
        const action = () => {
            setIsPasswordChanged(true)
            setTimeout(() => {
                dispatch(changePasswodModal())
                setIsPasswordChanged(false)
            }, 1000);
            // formik.setValues({...initialValues})
        }
        dispatch(changePasswordRequest({ body: obj, action }))
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: changePasswordSchema
    })
    return <>
        <ModelBox
            openVar={isChangePassword}
            closeFunc={() => {
                dispatch(changePasswodModal())
                // formik?.setValues({...initialValues})
            }}
            title="Change password"
            titleStyle={{ fontSize: "32px", fontWeight: "700", mb: '32px' }}
            px='30px'
        >
            {!isPasswordChages ? <form onSubmit={formik?.handleSubmit}>
                <Stack sx={{ pb: '16px' }}>
                    <Typography sx={{ pb: '16px' }} color={blackTwo} fontWeight='500'>Enter current password</Typography>
                    <FormControl variant="outlined" >
                        <OutlinedInput
                            placeholder='Enter current password'
                            id="current-password"
                            onChange={formik?.handleChange}
                            name='current_password'
                            type={showCurrentPassword ? 'text' : 'password'}
                            value={formik?.values?.current_password}
                            error={formik?.errors?.error}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCurrentPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '&:hover': {
                                                bgcolor: 'transparent'
                                            }
                                        }}
                                    >
                                        {showCurrentPassword ? <VisibilityOutlinedIcon sx={{ color: 'black' }} /> : <VisibilityOffOutlinedIcon sx={{ color: 'black' }} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {formik?.touched?.current_password &&
                        Boolean(formik?.errors?.current_password) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.current_password}
                            </FormHelperText>
                        )}
                </Stack>
                <Stack sx={{ pb: '16px' }}>
                    <Typography sx={{ pb: '16px' }} color={blackTwo} fontWeight='500'>Enter new password</Typography>
                    <FormControl variant="outlined" >
                        <OutlinedInput
                            placeholder='Enter password'
                            id="outlined-adornment-password"
                            onChange={formik?.handleChange}
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            value={formik?.values?.password}
                            error={formik?.errors?.error}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '&:hover': {
                                                bgcolor: 'transparent'
                                            }
                                        }}
                                    >
                                        {showPassword ? <VisibilityOutlinedIcon sx={{ color: 'black' }} /> : <VisibilityOffOutlinedIcon sx={{ color: 'black' }} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {formik?.touched?.password &&
                        Boolean(formik?.errors?.password) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.password}
                            </FormHelperText>
                        )}
                </Stack>
                <Stack sx={{ pb: '16px' }}>
                    <Typography sx={{ pb: '16px' }} color={blackTwo} fontWeight='500'>Re-enter new password</Typography>
                    <FormControl variant="outlined" >
                        <OutlinedInput
                            placeholder='Re-enter new password'
                            id="password_confirmation"
                            onChange={formik?.handleChange}
                            name='password_confirmation'
                            type={showConfirmationPassword ? 'text' : 'password'}
                            value={formik?.values?.password_confirmation}
                            error={formik?.errors?.error}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmationPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '&:hover': {
                                                bgcolor: 'transparent'
                                            }
                                        }}
                                    >
                                        {showConfirmationPassword ? <VisibilityOutlinedIcon sx={{ color: 'black' }} /> : <VisibilityOffOutlinedIcon sx={{ color: 'black' }} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {formik?.touched?.password_confirmation &&
                        Boolean(formik?.errors?.password_confirmation) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.password_confirmation}
                            </FormHelperText>
                        )}
                </Stack>
                <Stack>
                    <Button type='submit' variant='contained'>Confirm</Button>
                </Stack>
            </form>
                : <PaperComponent>
                    <Stack direction='row' justifyContent='center' columnGap='10px' alignItems='center' p='30px' >
                        <SentimentVerySatisfiedOutlinedIcon sx={{ color: 'primary.main' }} />
                        <Typography color='primary.main' >Password Changes Successfully</Typography>
                    </Stack>
                </PaperComponent>}
        </ModelBox >
    </>
}
