import { Breadcrumbs, Stack, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumb = ({ data, breadcrumbsBgColor, iconColor }) => {

  return (
    <Stack direction='row' mb='20px' bgcolor={breadcrumbsBgColor} justifyContent='space-between' alignItems='center' fontWeight='700'>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" sx={{ color: iconColor }} />}
        aria-label="breadcrumb"
      >
        {data?.map((breadcrumbs, index) => {
          return (
            <NavLink to={breadcrumbs?.active ? breadcrumbs?.path : ''} style={{ textDecoration: 'none', }} key={index}>
              < Typography fontWeight='700' sx={{ cursor: breadcrumbs?.active ? 'pointer' : 'auto' }} color={breadcrumbs?.active ? breadcrumbs?.activeColor : breadcrumbs?.notActive} > {breadcrumbs?.title}</Typography >
            </NavLink >
          )
        })}
      </Breadcrumbs>
    </Stack>
  )
}
export default Breadcrumb