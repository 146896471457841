import React from 'react';
import { Stack } from "@mui/material";
import SignUp from 'components/Molecules/LandingPage/SignUp/SignUp';


const SignUpTemplate = () => {
    return (

        <Stack gap={10}>
          <SignUp/>
        </Stack>
      );

};

export default SignUpTemplate;
