import { useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, InputAdornment, IconButton, Typography } from "@mui/material";
import "./TextFieldInput.css";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
function TextFieldInput({
    type = "text",
    placeholder = "",
    StartAdornment = null,
    EndAdornment = null,
    multiline = false,
    rows,
    inputBorder = 'none',
    className,
    onChange,
    error,
    value,
    labelStyle,
    label,
    clickEnter,
    InputProps,
    disabled,
    justifyContent,
    gridClass,
    bgColor,
    inputBorderColor,
    sx,
    variant = 'outlined',
    ...props
}) {
    const { labelColor } = useThemePalette();
    const defaultStyle = {
        color: labelColor,
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "15px",
        my: 1,
    };

    const [showPassword, setshowPassword] = useState(false);

    const mainLabelStyle = useMemo(() => {
        return labelStyle ? labelStyle : defaultStyle;
    }, [labelStyle]);

    const handleClickShowPassword = () => {
        setshowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid
            container
            sx={{ position: "relative" }}
            justifyContent={justifyContent}
            className={gridClass}
        >
            {label && (
                <Typography as="span" sx={mainLabelStyle}>
                    {label}
                </Typography>
            )}
            <TextField
                sx={{
                    backgroundColor: bgColor,
                    "& .MuiInputBase-root:before": {
                        // border: inputBorder,
                        borderBottom: inputBorder
                    },
                    "& .MuiInputBase-root:after": {
                        // border: inputBorder,
                        borderBottom: inputBorder
                    },
                    "& .MuiInputBase-root:focus": {
                        // border: inputBorder,
                        borderBottom: inputBorder
                    },
                    "& .MuiInputBase-root:hover:not(.Mui-disabled):before": {
                        // border: inputBorder,
                        borderBottom: inputBorder
                    },
                    ...sx,
                }}
                disabled={disabled}
                fullWidth
                multiline={multiline}
                rows={rows}
                type={type === "password" ? (showPassword ? "text" : "password") : type}
                className={className}
                error={error ? true : false}
                placeholder={placeholder}
                variant={variant}
                value={value}
                onChange={onChange}
                onKeyDown={(e) => {
                    if (e.key === "Enter") clickEnter();
                }}
                InputProps={
                    InputProps
                        ? InputProps
                        : {
                            startAdornment: <InputAdornment position="start">{StartAdornment}</InputAdornment>,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {type !== "password" ? (
                                        EndAdornment
                                    ) : (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? (
                                                <Visibility className="password-icon" />
                                            ) : (
                                                <VisibilityOff className="password-icon" />
                                            )}
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }
                }
                {...props}
            />
        </Grid>
    );
}

TextFieldInput.propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    StartAdornment: PropTypes.element,
    EndAdornment: PropTypes.element,
    className: PropTypes.string,
};
export default TextFieldInput;
