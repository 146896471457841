import { Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 

export default function NavbarButtons() {
    const navigate = useNavigate(); 

    const handleSignUpClick = () => {
        navigate('/signup'); 
    };

    const handleGetStartedClick = () => {
        window.open('/website', '_blank'); 
    };

    return (
        <>
            {/* -------------------------------- Navbar button container --------------------------- */}
            <Box>
                <Stack
                    direction={{ xs: 'column', md: "row" }}
                    justifyContent="space-between"
                    width="100%"
                    spacing={{ xs: 1, md: 2 }}
                >
                    {/* ---------------------------- Get Started Button ----------------------------------- */}
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            borderRadius: '10px',
                            width: { xs: '100%', md: 'auto' }
                        }}
                        onClick={handleGetStartedClick} 
                    >
                        Get Started
                    </Button>

                    {/* ----------------------------- SignUp Button --------------------------------- */}
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                            borderRadius: '10px',
                            width: { xs: '100%', md: 'auto' }
                        }}
                        onClick={handleSignUpClick}
                    >
                        Sign Up
                    </Button>

                </Stack>
            </Box>
        </>
    )
}
