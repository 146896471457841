import { Stack, Typography } from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';

export default function LandingPageHeaderTitle({ title, subtitle }) {
    const {warmGray} = useThemePalette()
    return <>
        <Stack
            justifyContent='space-between'
            alignItems='center'
            sx={{ pb : '2.5rem' }}
        >
            <Typography
                variant='h2'
                marginBottom='1rem'
                color='primary.main'
                fontSize='40px'
                fontWeight='600'
            >
                {title}
            </Typography>
            <Typography
                fontSize='24px'
                textAlign='center'
                fontWeight='400'
                color={warmGray}
            >
                {subtitle}
            </Typography>
        </Stack>
    </>
}
