import Types from "./Types";

export const walletLoading = (payload) => ({
  type: Types.WALLET_LOADING,
  payload,
});

export const getListTransactionsSuccess = (payload) => ({
  type: Types.GET_LIST_TRANSACTIONS_SUCCESS,
  payload,
});

export const getListTransactionswithFilterSuccess = (payload) => ({
  type: Types.GET_LIST_TRANSACTIONS_WITH_FILTER_SUCCESS,
  payload,
});

export const rechargeByCodeSuccess = (payload) => ({
  type: Types.RECHARGR_BY_CODE_SUCCESS,
  payload,
});

export const rechargeByFawrySuccess = (payload) => ({
  type: Types.RECHARGR_BY_FAWRY_SUCCESS,
  payload,
});
export const rechargeSuccess = (payload) => ({
  type: Types.RECHARGR_SUCCESS,
  payload,
});
