import { Box, Button, Container } from "@mui/material";
import React from "react";
import CoursesDetailsList from "./CoursesDetailsList";
import learningIcon from "../../../../Assets/Images/learningIcon.svg";
import lockIcon from "../../../../Assets/Images/lockIcon.svg";
import { Stack } from "@mui/system";
import CoursesDetailsBoxTitle from "../../../Atoms/SharedComponents/CoursesDetailsBoxTitle";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import NoDataYet from "../../../Atoms/NoDataYet";

export default function CoursesDetailsQuizzes({
  studentCoursesDetails,
  handlePreviewClick,
}) {
  const { primaryColor } = useThemePalette();

  return (
    <>
      <Box
        id="Quizzes"
        sx={{ bgcolor: "#fff", mb: "32px" }}
        pt="32px"
        pb="40px"
        px={{ lg: "44px", xs: "20px" }}
      >
        <Stack pt="16px">
          <CoursesDetailsBoxTitle title="Quizzes" />
          {studentCoursesDetails?.classified_product?.quizzes.length > 0 ? (
            <>
              {studentCoursesDetails?.classified_product?.quizzes?.map(
                (quiz, i) => (
                  <CoursesDetailsList
                    data={quiz}
                    titleIcon={learningIcon}
                    listTitle={`Quiz ${i + 1}`}
                    descriptionIcon={lockIcon}
                    key={i}
                    studentCoursesDetails={studentCoursesDetails}
                    handlePreviewClick={handlePreviewClick}
                  />
                )
              )}
              <Box alignSelf="center" pb="32px">
                <Button
                  variant="outlined"
                  sx={{
                    px: { md: "98px" },
                    border: `1.66px solid ${primaryColor}`,
                    "&:hover": {
                      border: `1.66px solid ${primaryColor}`,
                    },
                  }}
                >
                  View All Quizzes
                </Button>
              </Box>
            </>
          ) : (
            <NoDataYet data="Quizzes" />
          )}
        </Stack>
      </Box>
    </>
  );
}
