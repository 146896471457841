import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactMediaRecorder } from "react-media-recorder";
import * as dayjs from "dayjs";
import AssignmentAnswer from "../../../Molecules/Courses/AssignmentContent/AssignmentAnswer";
import StudentSubmitAnswer from "../../../Molecules/Courses/AssignmentContent/StudentSubmitAnswer";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import { getPresignedUrlRequest } from "../../../../services/Modules/UploadFiles";

export default function AssignmentContent({ sessionChossen }) {
  const { blackTwo, warmGray4 } = useThemePalette();
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ audio: true });
  const [toggleRecording, setToggleRecording] = useState(false);
  const [voices, setVoices] = useState([]);
  const { submittedAssignment } = useSelector((state) => state?.courses);
  const [isAssignmentSubmitted, setIsAssignmentSubmitted] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState();

  useEffect(() => {
    toggleRecording && startRecording();
    !toggleRecording && stopRecording();
  }, [toggleRecording]);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleDeleteVoices = (i) => {
    voices.splice(i, 1);
    setVoices([...voices]);
  };
  const handleDeleteFile = (i) => {
    fileName.splice(i, 1);
    setFileName([...fileName]);
  };

  const handleUploadFile = (e) => {
    let formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    dispatch(
      getPresignedUrlRequest(
        e.target.files[0],
        e.target.files[0]?.name.substring(
          e.target.files[0]?.name.lastIndexOf(".") + 1,
          e.target.files[0]?.name?.length
        )
      )
    );
    setFileName([fileName, e.target.files[0]?.name].flat(1));
  };
  useEffect(() => {
    mediaBlobUrl && setVoices([voices, mediaBlobUrl].flat(2));
  }, [mediaBlobUrl]);

  return (
      <Stack gap={1}>
        <Stack direction="row" borderBottom={`1px solid ${warmGray4}`}>
          <Typography
            fontSize={{ md: "32px", xs: "20px" }}
            mb="20px"
            color={blackTwo}
            fontWeight="500"
          >
            Assignment
          </Typography>
          <Typography
            fontSize={{ md: "32px", xs: "20px" }}
            color={blackTwo}
            fontWeight="400"
            mx={1}
          >
            : {sessionChossen.title}
          </Typography>
        </Stack>
        <Stack borderBottom={`1px solid ${warmGray4}`} pb={1} gap={2}>
          <Stack direction="row">
            <Typography
              fontSize={{ md: "24px", xs: "18px" }}
              color={blackTwo}
              fontWeight="600"
            >
              Due Date
            </Typography>
            <Typography
              mx={1}
              fontSize={{ md: "24px", xs: "18px" }}
              color={blackTwo}
            >
              : {dayjs(sessionChossen?.due_date).format("MMM D, h:mm A")}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              fontSize={{ md: "24px", xs: "18px" }}
              color={blackTwo}
              fontWeight="600"
            >
              Grade
            </Typography>
            <Typography
              fontSize={{ md: "24px", xs: "18px" }}
              mx={1}
              color={blackTwo}
            >
              : To pass 80% or higher
            </Typography>
          </Stack>
        </Stack>
        <Stack borderBottom={`1px solid ${warmGray4}`} pb={1} gap={2}>
          <Typography
            fontSize={{ md: "28px", xs: "22px" }}
            color={blackTwo}
            fontWeight="600"
          >
            Answer The following question :{" "}
          </Typography>
          <Typography fontSize={{ md: "22px", xs: "18px" }} color={blackTwo}>
            {sessionChossen.title}
          </Typography>
        </Stack>
        <Stack
          borderBottom={sessionChossen?.feedback && `1px solid ${warmGray4}`}
        >
          <Typography
            fontSize={{ md: "28px", xs: "22px" }}
            py="15px"
            color={blackTwo}
            fontWeight="600"
          >
            Answer :{" "}
          </Typography>
          {isAssignmentSubmitted ? (
            <AssignmentAnswer
              submittedAssignment={submittedAssignment}
              value={value}
              voices={voices}
              mediaBlobUrl={mediaBlobUrl}
              fileName={fileName}
              handleDeleteVoices={handleDeleteVoices}
            />
          ) : (
            <StudentSubmitAnswer
              setToggleRecording={setToggleRecording}
              toggleRecording={toggleRecording}
              setValue={setValue}
              value={value}
              sessionChossen={sessionChossen}
              mediaBlobUrl={mediaBlobUrl}
              clearBlobUrl={clearBlobUrl}
              status={status}
              voices={voices}
              submittedAssignment={submittedAssignment}
              setIsAssignmentSubmitted={setIsAssignmentSubmitted}
              handleUploadFile={handleUploadFile}
              fileName={fileName}
              files={files}
              // setFiles={setFiles}
              handleDeleteVoices={handleDeleteVoices}
              handleDeleteFile={handleDeleteFile}
            />
          )}
        </Stack>
      </Stack>
  );
}
