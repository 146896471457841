import React from "react";
import BlogTemplate from "Template/LandingPageTemplate/Blog/BlogTemplate";
import blogone from "../../../Assets/Images/blog1.png";
import blogtwo from "../../../Assets/Images/blog2.png";
import blogthree from "../../../Assets/Images/blog3.png";
import blogfour from "../../../Assets/Images/blog4.png";
import blogfive from "../../../Assets/Images/blog5.png";


export default function BlogPage() {
    const BlogData =  [
        {
            img: blogone,
            title: "New and upcoming WhatsApp features",
            date: "15/12/2022",
            description:
              "The most well-known and commonly used instant messaging service in the world, WhatsApp, is always being improved. The messaging platform keeps developing new features and enhancing the user interface to give its users fresh, simple ways to interact and communicate with others. A new feature called Polls and the option to speak with yourself were just added to WhatsApp. Whatsapp has also revealed a number of new features that will be added to the instant messaging service. This article examines the new WhatsApp features that were introduced in 2022. We’ll also look at a few brand-new features that are currently in development that the community has",
          },
          {
            img: blogtwo,
            title: "Apple rolls out an emergency satellite alert service",
            date: "16/11/2022",
            description: `Apple aims to provide some level of security for iPhone users even when they are in an area without cellular coverage.
            Emergency SOS via satellite, which enables users to text emergency services when they’re off the grid, such as while camping in the mountains or traveling in a rural place at night, was launched by the firm on Tuesday. Customers of the iPhone 14 can access the service, which is free for the first two years.
            When it introduced the iPhone 14 series in September, Apple also revealed the emergency feature. Apple announced last week that it would invest $450 million`,
          },
          ];
  
    return (
    <div>
        <BlogTemplate BlogData={BlogData}/>
    </div>
  );
};


