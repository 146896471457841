import React from "react";
import { useThemePalette } from "common/hooks/useThemePalatte";
import { Typography, Stack, Box } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";

function InclassNumbers({achievednumbers}) {
    const {  whiteColor } = useThemePalette();

    return (
        <Stack
            sx={{backgroundColor: 'primary.main' }}
        >
        <CustomContainer>
        <Stack
            sx={{ width: '100%' ,
                  flexDirection: { xs: 'column', md: 'row' }, 
                }}
            direction='row'
            justifyContent='space-between'
            alignItems= 'center'
            
            >
    
                {achievednumbers?.map((data, index) =>
                    <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        paddingTop: '50px',
                        py: '50px'
                    }}
                    >
                        <Typography
                            variant="h2"
                            color={whiteColor}
                            sx={{
                                fontSize: '46px',
                                fontWeight: 700,
                                lineHeight: '69px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                            }}
                        >
                            {data.number} +
                        </Typography>
                        <Typography
                            variant="h3"
                            color={whiteColor}
                            sx={{
                                fontSize: '15px',
                                fontWeight: 500,
                                textAlign: 'center',
                            }}
                        >
                            {data.title}
                        </Typography>
                    </Box>
                )}
                </Stack>
         </CustomContainer>
     </Stack>
    );
}

export default InclassNumbers;
