import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesRequest,
  getStudentSavedListRequest,
} from "services/Modules/Courses";
import { useState } from "react";
import SavedListTemplate from "../../Template/Profile/SavedList.Template";

export default function SavedListPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCoursesRequest({ page_number: 1, page_size: 4, school_class_id: 25 })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getStudentSavedListRequest({ body: { page: { number: 1, size: 2 } } })
    );
  }, []);

  const { courses } = useSelector((state) => state.courses);
  const [coursesArr, setCoursesArr] = useState([]);

  useEffect(() => {
    let extractedData = [];
    const myCoursesResponse = courses;
    for (const key in myCoursesResponse) {
      extractedData.push(...myCoursesResponse[key]);
    }
    setCoursesArr(extractedData);
  }, [courses]);

  return <SavedListTemplate courses={coursesArr} />;
}
