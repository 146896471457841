import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../../Assets/Images/inclassLogo.jpg";
import { Button, MenuItem, Stack, Tooltip, Typography,Tabs, Tab } from "@mui/material";
import { useThemePalette } from "common/hooks/useThemePalatte";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";
import NavbarButtons from "components/Molecules/LandingPage/Navbar/NavbarButtons";

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { white, primaryColor, secondaryColor, red, blackTwo } = useThemePalette();
  const [menuIcon, setMenuIcon] = useState(<MenuIcon sx={{ fontSize: "40px", color: "#000" }} />);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  const pages = [
    { title: `Home`, path: "/" },
    { title: `Features`, path: "/features/" },
    { title: `Pricing`, path: "/pricing/" },
    { title: `About`, path: "/about" },
    { title: `Blog`, path: "/blog/" },
    { title: `Contact`, path: "/contact/" },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setMenuIcon(<CloseIcon sx={{ fontSize: "40px", color: "#000", cursor: "pointer" }} />);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setMenuIcon(<MenuIcon sx={{ fontSize: "40px", color: "#000" }} />);
  };

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClick = (page) => {
    navigate(page.path);
    handleCloseNavMenu()

  };
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AppBar
    position="fixed"
    sx={{
      bgcolor: white,
      boxShadow: { md: "0px 2px 4px rgba(0, 0, 0, 0.16)", xs: "none" },
      py: "10px",
    }}
    >
      <Box py="10px">
        <Toolbar style={{ paddingRight: 0, paddingLeft: 0 }}>
      <CustomContainer>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
              {/* ------------------------------- Web LOGO -------------------------------- */}
              <Box
                nowrap="true"
                component="a"
                href="/"
                sx={{
                  display: { md: "flex" },
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
            <img src={logo} style={{ width: '100%', height: 'auto' }} alt="logo" />
              </Box>
              {/* ------------------------------- Tabs and Buttons ------------------------------- */}
                  <Stack
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    columnGap={{ xl: "10px" }}
                  >
                    <Box sx={{ flex: 1, display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "center"  }}>
                      <Tabs value={value} onChange={handleChange}>
                        {pages.map((page, i) => (
                          <Tab
                            disableRipple
                            key={i}
                            label={page.title}
                            sx={{
                              color: pathname === page.path ? primaryColor : 'black',
                              padding: { lg: '11px 20px', md: '11px 10px' },
                              fontWeight: 700,
                              fontSize: { lg: '18px', xs: '14px' },
                              ml: page.title === 'Contact us' && '30px',
                              '&:hover': {
                                bgColor: '#CB2127 !important',
                              },
                            }}
                            onClick={(e) => handleClick(page)}
                          />
                        ))}
                      </Tabs>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                      <NavbarButtons/>
                    </Box>
                  </Stack>
              
              {/* ------------------------------- Responsive Menu Icon ---------------------- */}
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                >
                  {menuIcon}
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiPaper-root": {
                      width: { sm: '100%', xs: '95%' },
                      boxShadow: "none",
                      padding: '10px'
                    },
                  }}
                >
                  <Stack
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width="100%"
                  >
                    {pages?.map((page, i) => (
                      <Box
                        key={i}
                        sx={{
                          width: "100%",
                          borderBottom: i !== pages?.length - 1 && "1px solid #c4c4c4",
                        }}
                      >
                        <Button
                          disableRipple
                          onClick={(e) =>{
                             handleClick(page)
                          }
                          }
                          key={i}
                          sx={{
                            color: pathname === page.path ? primaryColor : secondaryColor,
                            padding: { lg: "11px 20px", md: "11px 10px", xs: '3px 15px' },
                            fontWeight: 700,
                            fontSize: { lg: "18px", xs: "14px" },
                            bgcolor: page.title === 'inherit',
                            width: '100%',
                            ml: { md: page?.title == "74px", xs: '3px 10px' },
                            mt: {
                              md: page?.title ==  "0",
                              xs: page?.title ==  "10px",
                            },
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            textTransform: 'capitalize',
                          }}
                        >
                          {page.title}
                        </Button>
                      </Box>
                    ))}
                     <Box py={1} >
                      <NavbarButtons />
                    </Box>
                  </Stack>
                </Menu>
              </Box>
            </Stack>
          </CustomContainer>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default Navbar;



