import React from 'react';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import { useNavigate } from 'react-router-dom';

export default function EventsCards({ data, titleHeight = '96px', dataColor, titleStyle, action, sx }) {
    const { primaryColor, warmGray, gray} = useThemePalette()
    const navigate = useNavigate()
    return (
        <Stack
            sx={{
                borderRadius: '6px',
                height: '100%',
            }}
        >
            <Box>
                <Box component='img' width='100%' src={data?.img} alt='blog img' />
            </Box>
            <Stack
                p='20px'
                spacing={2}
            >
                <Typography
                    fontWeight='600'
                    fontSize='26px'
                    color={primaryColor}
                    height={titleHeight}
                    {...titleStyle}
                >
                    {data?.title?.length > 95 ? data?.title.substring(0, 95).concat("...") : data?.title}
                </Typography>
        
                <Stack direction="row" alignItems="center" >
                    <Typography fontSize='24px' color={warmGray} >{data?.date}</Typography>
                </Stack>

                <Typography
                    fontSize='18px'
                    color={gray}
                    dangerouslySetInnerHTML={{ __html: data?.description?.length > 290 ? data?.description.substring(0, 290).concat("...") : data?.description }}
                />

                <Box justifySelf='flex-end'   >
                    <Button
                    variant="text"
                    color="primary"
                    onClick={_ => action ? action() : data?.path ? navigate(data?.path) : ''}
                    endIcon={<ArrowForwardIcon />}
                    sx={{ alignSelf: 'flex-end', textTransform: 'none',fontWeight: '600', fontSize: '22px' }}
                    >
                    Read More
                    </Button>
                </Box>
            </Stack>
        </Stack>
    );
}
