import { Stack, Typography } from "@mui/material";
import React from "react";
import VoiceNote from "./VoiceNote";
import pdf_icon from "../../../../Assets/Images/pdf_icon.svg";
import File from "../CoursesOverview/File";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStudentEvaluationRequest } from "../../../../services/Modules/Courses";
import { useParams } from "react-router-dom";

export default function AssignmentAnswer({
  submittedAssignment,
  value,
  voices,
  mediaBlobUrl,
  fileName,
  handleDeleteVoices,
}) {
  const { blackTwo } = useThemePalette();
  const voiceMemo = submittedAssignment?.attachments?.filter(
    (attachment) => attachment?.attachment_type === "voice_memo"
  );
  const file = submittedAssignment?.attachments?.filter(
    (attachment) => attachment?.attachment_type !== "voice_memo"
  );
  const dispatch = useDispatch();
  const { course_id, session_id } = useParams();
  // console.log(voiceMemo ,'memo');
  // console.log(file , 'file');
  // console.log(submittedAssignment , 'submittedAss');

  useEffect(() => {
    dispatch(
      getStudentEvaluationRequest({
        course_id,
        assignment_id: session_id,
        assignment_delivery_id: submittedAssignment?.assignment_delivery_id,
      })
    );
  }, []);

  return (
    <>
      <Typography fontSize="18px" color={blackTwo} fontWeight="500">
        {submittedAssignment?.body}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        columnGap="29px"
        flexWrap="wrap"
      >
        <VoiceNote
          handleDeleteVoices={handleDeleteVoices}
          mediaBlobUrl={mediaBlobUrl}
          addCancelIcon={false}
          voices={voices}
          voiceMemo={voiceMemo}
        />
        {fileName && (
          <File
            icon={pdf_icon}
            addCancelIcon={false}
            name={fileName}
            file={file}
          />
        )}
      </Stack>
      {/* {studentEvaluation?.feedback && */}
      <Stack>
        <Typography
          fontSize={{ md: "28px", xs: "22px" }}
          py="15px"
          color={blackTwo}
          fontWeight="500"
        >
          Feedback :{" "}
        </Typography>
        <Typography mb="20px" color={blackTwo}>
          Lorem ipsum dolor sit amet, consetetur sadimpor Lorem ipsum dolor sit
          amet, consetetur sadimpor Lorem ipsum dolor sit amet, consetetur Lorem
          ipsum dolor sit amet, consetetur sadimpor Lorem ipsum dolor sit amet,
          consetetur sadimpor Lorem ipsum dolor sit amet, consetetur
        </Typography>
      </Stack>
      {/* } */}
    </>
  );
}
