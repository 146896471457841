import { Box, Container, Grid } from "@mui/material";
import React from "react";

export default function VideoSkeleton({ children }) {
  return (
    <Box sx={{ minHeight: "850px", py: "5%" }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {children}
        </Grid>
      </Container>
    </Box>
  );
}
