import { reduxRequest } from "../../../common/utils/reduxRequest";
import {
  authLoading,
  changePasswordSuccess,
  checkEmailExistSuccess,
  logOutSuccess,
  loginSuccess,
  resendConfirmationCodeSuccess,
  resetPasswordByEmailSuccess,
  sendEmailOtpSuccess,
  sendResetPasswordSuccess,
  signupSuccess,
  verifyEmailOtpSuccess,
} from "./Actions";

export const signupRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: signupSuccess,
    loadingType: "signup",
    url: "user/signup",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const loginRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: loginSuccess,
    loadingType: "login",
    url: "user/login",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const sendResetPasswordRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: sendResetPasswordSuccess,
    loadingType: "resetPasswod",
    url: "send_reset_password_code_phone",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const checkEmailExistRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: checkEmailExistSuccess,
    loadingType: "checkEmail",
    url: "check_email",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const sendEmailOtpRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: sendEmailOtpSuccess,
    loadingType: "emailOtp",
    url: "forgot-password-email",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const veriifyEmailOtpRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: verifyEmailOtpSuccess,
    loadingType: "verifyEmailOtp",
    url: "verify-reset-code",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const resetPasswordRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: resetPasswordByEmailSuccess,
    loadingType: "resetPassword ",
    url: "reset-password-email",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const resendConfirmationCodeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: resendConfirmationCodeSuccess,
    loadingType: "resendConfirmatiom",
    url: "user/resend_verification_code",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const logOutRequest = () => {
  const requestData = {
    isLoading: authLoading,
    successFunction: logOutSuccess,
    loadingType: "resendConfirmatiom",
    url: "user/logout",
    method: "delete",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const changePasswordRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: changePasswordSuccess,
    loadingType: "changePassword",
    url: "user/account-settings/change-password",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
