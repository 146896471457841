import React from "react";
import ModelBox from "../../Atoms/Model/ModalBox";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPasswodModal,
  loginModel,
  signupModel,
} from "../../../services/Modules/Models/Actions";
import { useEffect } from "react";
import { useFormik } from "formik";
import { loginSchema } from "./validation";
import { useThemePalette } from "../../../common/hooks/useThemePalatte";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import setAuthToken from "../../../services/Modules/utils/handel_api";
import { loginRequest } from "../../../services/Modules/Auth";

export default function LoginForm() {
  const { isOpenLogin } = useSelector((state) => state?.model);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { blackTwo, babyBlue, black5 } = useThemePalette();
  const [showPassword, setShowPassword] = useState(false);
  const [searchParam, setSearchParams] = useSearchParams();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values) => {
    const obj = {
      user: {
        email: values?.email,
        password: values?.password,
      },
      device: {
        uid: "68c7c644",
        fcm_token: "fcm_token",
      },
    };
    const action = (data) => {
      console.log(data, "data");
      dispatch(loginModel());
      localStorage.setItem("userToken", data?.data?.token);
      setAuthToken(data?.data?.token);
      formik?.setValues({ ...initialValues })
    };
    dispatch(loginRequest({ body: obj, action }));
  };

  //   useEffect(() => {
  //     token && localStorage?.setItem("userToken", token);
  //   }, [token]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: loginSchema,
  });

  return (
    <>
      <ModelBox
        openVar={isOpenLogin}
        closeFunc={() => {
          dispatch(loginModel())
          formik?.setValues({ ...initialValues })
        }
        }
        title="Welcome Back!"
        titleStyle={{ fontSize: "32px", fontWeight: "700", mb: "64px" }}
      >
        <form onSubmit={formik?.handleSubmit}>
          {/* <Stack sx={{ pb: '16px' }}>
                    <Typography sx={{ pb: '16px' }} color={blackTwo} fontWeight='500'>Phone Number or IP Number</Typography>
                    <Stack direction='row' justifyContent='space-between'>
                        <PhoneInput
                            select={false}
                            id={"login_phone_number"}
                            name={"phone_number"}
                            className="normal-textField user-phone"
                            selectId={"search-select"}
                            inputValue={formik?.values?.phone_number}
                            inputType={"number"}
                            setPhone={(e) => formik?.setValues({ ...formik?.values, phone_number: e.target.value })}
                            placeholder={"Phone number"}
                            labels={en}
                            error={formik?.touched?.phone_number && Boolean(formik?.errors?.phone_number)}
                            helperText={formik?.touched?.phone_number && formik?.errors?.phone_number}
                        />
                    </Stack>
                </Stack> */}

          <Stack sx={{ pb: "16px" }}>
            <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
              Enter your email
            </Typography>
            <TextField
              id="outlined-basic"
              hiddenLabel
              placeholder="Enter your email"
              variant="outlined"
              onChange={formik?.handleChange}
              name="email"
              value={formik?.values?.email}
            />
            {formik?.touched?.email && Boolean(formik?.errors?.email) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                {formik?.errors?.email}
              </FormHelperText>
            )}
          </Stack>

          <Stack sx={{ pb: "16px" }}>
            <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
              Password
            </Typography>
            <FormControl variant="outlined">
              <OutlinedInput
                placeholder="Enter your password"
                id="outlined-adornment-password"
                onChange={formik?.handleChange}
                name="password"
                type={showPassword ? "text" : "password"}
                value={formik?.values?.password}
                error={formik?.errors?.error}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              sx={{
                ml: "auto",
                color: black5,
                fontWeight: 500,
                fontSize: "14px",
              }}
              onClick={(_) => {
                dispatch(forgetPasswodModal());
                dispatch(loginModel());
                // setSearchParams({ email: formik.values?.email })
              }}
            >
              Forgot Password?
            </Button>

            {formik?.touched?.password && Boolean(formik?.errors?.password) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                {formik?.errors?.password}
              </FormHelperText>
            )}
          </Stack>
          <Stack>
            <Button type="submit" variant="contained">
              Login
            </Button>
          </Stack>
          <Stack
            direction="row"
            sx={{ m: "auto", width: "fit-content", mt: "32px", mb: "10px" }}
            justifyContent="cenetr"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              color={babyBlue}
              fontWeight="300"
              fontSize="14px"
            >
              New User?
            </Typography>
            <Button
              sx={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
              onClick={(_) => {
                dispatch(signupModel());
                dispatch(loginModel());
              }}
            >
              Sign up
            </Button>
          </Stack>
        </form>
      </ModelBox>
    </>
  );
}
