import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import Lock from "../../../../Assets/Images/Lock.png";
import { useState, useEffect } from "react";
import accordianVideoIcon from "../../../../Assets/Images/accordianIcon1.png";
import ReadingIcon from "../../../../Assets/Images/ReadingIcon(2).svg";
import quizIcon from "../../../../Assets/Images/quizIcon(2).svg";
import videoIcon1 from "../../../../Assets/Images/videoIcon1.svg";
import greenCheckMark from "../../../../Assets/Images/greenCheckMark.svg";
import { useNavigate, useParams } from "react-router-dom";
import CustomAccordian from "../../../Atoms/SharedComponents/CustomAccordian";

export default function AccordianList({
  section,
  sectionIndex,
  subjectId,
  subjectName,
  session,
  id,
}) {
  const { course_id } = useParams();
  const navigate = useNavigate();
  const [assignments, setAssignments] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [accordionListData, setAccoirdionListData] = useState([]);
  const [readingData, setReadingData] = useState([]);

  useEffect(() => {
    section && setSessions(section?.sessions)?.flat(1);
  }, [section]);

  useEffect(() => {
    setAssignments(sessions?.map((session) => session?.assignments)?.flat(1));
    setQuizzes(sessions?.map((session) => session?.quizzes)?.flat(1));
    setReadingData(
      sessions?.filter((reading) => reading?.content_type === "book")
    );
  }, [sessions]);

  useEffect(() => {
    quizzes &&
      assignments &&
      sessions &&
      readingData &&
      setAccoirdionListData([
        ...assignments,
        ...sessions,
        ...quizzes,
        ...readingData,
      ]);
  }, [assignments, sessions, quizzes, readingData]);

  const viewSession = ({ section_id, id, content_type, book_id }) => {
    if (content_type === "book") {
      return navigate(
        `/website/ebook/${course_id}/${section_id}/${id}/${book_id}?type=${content_type}&subject_name=${subjectName}&subject_id=${subjectId}`
      );
    }
    navigate(
      `/website/courses-details/${course_id}/${section_id}/${id}?type=${content_type}&subject_name=${subjectName}&subject_id=${subjectId}`
    );
  };
  // ------------------------------- return assignment or quiz or session id -------------------------------------------
  const checkId = (session) => {
    return session?.assignment_id
      ? session?.assignment_id
      : session?.quiz_id
      ? session?.quiz_id
      : session?.course_session_id;
  };

  const checkIcon = (session) => {
    console.log(session, "session");
    if (session?.progress?.value == "completed")
      return <img src={greenCheckMark} alt="check mark icon" />;
    if (session?.quiz_id) return <img src={quizIcon} alt="check mark icon" />;
    if (session?.assignment_id)
      return <img src={quizIcon} alt="check mark icon" />;
    if (session?.content_type == "book")
      return <img src={ReadingIcon} alt="check mark icon" />;
    if (session?.content_type === "video_and_text" || "video")
      return <img src={videoIcon1} alt="check mark icon" />;
  };

  const accordianDetails = accordionListData?.map((session, index) => {
    return (
      <Stack key={index}>
        <Stack
          direction="row"
          key={session?.course_session_id}
          onClick={() =>
            viewSession({
              section_id: section?.section_id,
              id: session?.assignment_id
                ? session?.assignment_id
                : session?.quiz_id
                ? session?.quiz_id
                : session?.course_session_id,
              content_type: session?.quiz_id
                ? "quiz"
                : session?.assignment_id
                ? "assignment"
                : session?.content_type == "book"
                ? "book"
                : session?.content_type === "video_and_text" || "video"
                ? "video"
                : "",
              book_id: session?.book?.id && session?.book?.id,
            })
          }
          sx={{
            bgcolor: id == checkId(session) ? "#fff" : "#F8F8F8",
            p: 2,
            justifyContent: "space-between",
            "&:hover": { cursor: "pointer" },
            my: "4px",
          }}
        >
          <Stack direction="row">
            <Stack>{checkIcon(session)}</Stack>
            <Typography
              sx={{ px: 1, color: "rgb(52, 52, 52)", fontWeight: 600 }}
            >
              {session?.quiz_id
                ? "Quiz"
                : session?.assignment_id
                ? "Assignment"
                : session?.content_type === "book"
                ? "Book"
                : session?.content_type === "video_and_text" || "video"
                ? "video"
                : ""}
            </Typography>
            <Typography
              sx={{
                color: "rgba(52, 52, 52,.6)",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {session.text || session?.body}
            </Typography>
          </Stack>
          <Stack sx={{ pl: 1 }}>
            {session.is_blocked ? null : <img src={Lock} alt="lock icon" />}
          </Stack>
        </Stack>
      </Stack>
    );
  });
  return (
    <Box>
      <CustomAccordian
        accordianTitle={`${section?.title?.replace(/[0-9]/g, "")} ${
          section?.position
        }`}
        accordianDetails={accordianDetails}
        sectionId={section?.section_id}
      />
    </Box>
  );
}
