import Types from './Types';

const INIT_STATE = {
    isOpenSignup: false,
    isOpenVerification: false,
    isOpenLogin: false,
    isOpenForgetPassword: false,
    isCreatedNewPassword: false,
    isChangePassword: false,
    isDeleteAccountModel: false,
    isStudentReviewModel: false,
    isWalletFilterByModel: false,
    isChargeWallet: false,
    isWalletChargedSuccessfully:false,
};

export default function modelReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.SIGN_UP_MODEL: {
            return {
                ...state,
                isOpenSignup: !state.isOpenSignup
            };
        }
        case Types.VERIFICATIOM_MODEL: {
            return {
                ...state,
                isOpenVerification: !state.isOpenVerification
            };
        }
        case Types.LOG_IN_MODEL: {
            return {
                ...state,
                isOpenLogin: !state.isOpenLogin
            }
        }
        case Types.FORGET_PASSWORD_MODEL: {
            return {
                ...state,
                isOpenForgetPassword: !state.isOpenForgetPassword
            }
        }
        case Types.CREATE_NEW_PASSWORD_MODEL: {
            return {
                ...state,
                isCreatedNewPassword: !state.isCreatedNewPassword
            }
        }
        case Types.CHANGE_PASSWORD_MODEL: {
            return {
                ...state,
                isChangePassword: !state.isChangePassword
            }
        }
        case Types.DELETE_ACCOUNT_MODEL: {
            return {
                ...state,
                isDeleteAccountModel: !state.isDeleteAccountModel
            }
        }
        case Types.STUDENT_REVIEW_MODEL: {
            return {
                ...state,
                isStudentReviewModel: !state.isStudentReviewModel
            }
        }
        case Types.WALLET_FILTER_BY_MODEL: {
            return {
                ...state,
                isWalletFilterByModel: !state.isWalletFilterByModel
            }
        }
        case Types.CHARGE_WALLET_MODEL: {
            return {
                ...state,
                isChargeWallet: !state.isChargeWallet
            }
        }
        case Types.WALLET_CHARGED_SUCCESSFULLY_MODEL: {
            return {
                ...state,
                isWalletChargedSuccessfully: !state.isWalletChargedSuccessfully
            }
        }
        default: {
            return state;
        }
    }
}