import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProfileCoursesCard from 'components/Atoms/SharedComponents/ProfileCoursesCard'
import CustomTabs from 'components/Atoms/CustomTabs/CustomTabs'
import { Box, Button, LinearProgress } from '@mui/material'
import { studentReviewModel } from 'services/Modules/Models/Actions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CardRating from 'components/Atoms/SharedComponents/Cards/CardRating'
import StarIcon from "@mui/icons-material/Star";


export default function MyCoursesTemplate({studentCoursesList}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()



    const inProgressCourses = studentCoursesList?.filter(value => value?.classified_product?.progress?.value == 'inprogress')
    const pendingCourses = studentCoursesList?.filter(value => value?.classified_product?.progress?.value == 'todo')
    const completedCourses = studentCoursesList?.filter(value => value?.classified_product?.progress?.value == 'completed')

    const tabsData = [
        { label: 'In progress', value: '1' },
        { label: 'Pending', value: '2' },
        { label: 'Completed', value: '3' },
    ]
    const tabPanelData = [
        {
            value: '1', panel: inProgressCourses?.map((course, i) =>
                <ProfileCoursesCard
                    key={i}
                    data={course}
                    labelIcon={false}
                    click={_ => navigate(`/website/courses-details/${course?.classified_product?.course_id}/?subject_name=All&subject_id=0`)}
                >
                    <Box className='my-courses' position='absolute' bottom='10px' width='100%'>
                        <LinearProgress
                            sx={{
                                height: '6px',
                                borderRadius: '8px',
                            }}
                            variant="determinate"
                            value={course?.classified_product?.complete_percentage}
                        />
                    </Box>
                </ProfileCoursesCard>
            )
        },
        {
            value: '2', panel: pendingCourses?.map((course, i) =>
                <ProfileCoursesCard
                    key={i}
                    data={course}
                    labelIcon={false}
                    mt='20px'
                    click={_ => navigate(`/website/courses-details/${course?.classified_product?.course_id}/?subject_name=All&subject_id=0`)}
                />)
        },
        {
            value: '3', panel: completedCourses?.map((course, i) =>
                <ProfileCoursesCard
                    key={i}
                    data={course}
                    labelIcon={false}
                    click={_ => navigate(`/website/courses-details/${course?.classified_product?.course_id}/?subject_name=All&subject_id=0`)}
                >
                    {course?.final_rating_from_reviews == 0 ?
                        <Box>
                            <Button
                                onClick={_ => {
                                    dispatch(studentReviewModel())
                                    setSearchParams({ course_id: course?.classified_product?.course_id });
                                }}
                                sx={{ minWidth: 'auto', p: 0 }}
                            >
                                Review
                            </Button>
                        </Box>
                        :
                        <CardRating
                            iconEmptyColor="#E1E1E1"
                            iconSize={"small"}
                            readOnly={true}
                            defaultValue={course?.final_rating_from_reviews}
                            icon={
                                <StarIcon
                                    size="small"
                                    sx={{
                                        fontSize: "25px",
                                        "& .MuiRating-iconEmpty": {
                                            color: "secondary.main",
                                        },
                                    }}
                                />
                            }
                            emptyIcon={
                                <StarIcon
                                    style={{ opacity: 0.55 }}
                                    size="small"
                                    sx={{ fontSize: "25px" }}
                                />
                            }
                        />
                    }
                </ProfileCoursesCard >
            )
        },
    ]

    return <>
        <CustomTabs tabsData={tabsData} tabPanelData={tabPanelData} justifyContent='space-between' tabsFontWeight='700' buttonWidth='30%' indicatorWidth='220px' fontWeight='700' />
    </>
}
