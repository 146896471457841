import { Box, Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "./Footer";

export default function LandingLayout({ logOutFun, bgColor }) {
  const { isOpenVerification } = useSelector((state) => state?.model);
  return (
    <Box bgcolor={bgColor} width="100%">
      <Navbar logOutFun={logOutFun} />
      <Outlet />
      <Box mb='24px'></Box>
      <Footer />
    </Box>
  );
}
