import Types from "./Types";

export const getAllCoursesSuccess = (payload) => ({
  type: Types.GET_ALL_COURSES_SUCCESS,
  payload,
});

export const coursesLoading = (payload) => ({
  type: Types.COURSES_LOADING,
  payload,
});

export const getAllCoursesError = (payload) => ({
  type: Types.GET_ALL_COURSES_ERROR,
  payload,
});

export const getStudentCoursesDetailsSuccess = (payload) => ({
  type: Types.GET_STUDENT_COURSES_DETAILS_SUCCESS,
  payload,
});

export const getStudentSectionsSuccess = (payload) => ({
  type: Types.GET_STUDENT_SECTIONS_SUCCESS,
  payload,
});

export const getStudentSessionSuccess = (payload) => ({
  type: Types.GET_STUDENT_SESSIONS_SUCCESS,
  payload,
});

export const getStudentSessionDetailsSuccess = (payload) => ({
  type: Types.GET_STUDENT_SESSION_DETAILS_SUCCESS,
  payload,
});

export const getAllReviewsSussess = (payload) => ({
  type: Types.GET_ALL_REVIEWS_SUSSESS,
  payload,
});

export const getCoursesListSuccess = (payload) => ({
  type: Types.GET_COURSES_LIST_SUCCESS,
  payload,
});

export const getCoursesListError = (payload) => ({
  type: Types.GET_COURSES_LIST_ERROR,
  payload,
});

///
export const getStudentAssignmentDetailsSuccess = (payload) => ({
  type: Types.GET_STUDENT_ASSIGNMENT_DETAILS_SUCCESS,
  payload,
});

export const getStudentQuizDetailsSuccess = (payload) => ({
  type: Types.GET_STUDENT_QUIZ_DETAILS_SUCCESS,
  payload,
});

export const submitAssignmentSuccess = (payload) => ({
  type: Types.SUBMIT_ASSIGNMENT_SUCCESS,
  payload,
});

export const getStudentEvaluationSuccess = (payload) => ({
  type: Types.GET_STUDENT_EVALUATION_SUCCESS,
  payload,
});

//start Quiz
export const startQuizSuccess = (payload) => ({
  type: Types.STUDENT_START_QUIZ_SUCCESS,
  payload,
});

//answer Quiz
export const answerQuizSuccess = (payload) => ({
  type: Types.STUDENT_ANSWER_QUIZ_SUCCESS,
  payload,
});

//clear answer
export const clearQuizAnswerSuccess = (payload) => ({
  type: Types.CLEAR_STUDENT_ANSWER_QUIZ_SUCCESS,
  payload,
});

//submit quiz
export const submitQuizSuccess = (payload) => ({
  type: Types.STUDENT_SUBMIT_QUIZ_SUCCESS,
  payload,
});

//ATTEMPTS quiz
export const getQuizAttemptsSuccess = (payload) => ({
  type: Types.GET_STUDENT_QUIZ_ATTEMPTS_SUCCESS,
  payload,
});

export const getCourseDetailsToGuestSuccess = (payload) => ({
  type: Types.GET_GUEST_COUESE_DETAILS_SUCCESS,
  payload,
});

export const completeSessionSuccess = (payload) => ({
  type: Types.COMPLETE_SESSION_SUCCESS,
  payload,
});

export const getStudentSavedListSuccess = (payload) => ({
  type: Types.GET_STUDENT_SAVED_LIST_SUCCESS,
  payload,
});

export const addCourseToSavedListSuccess = (payload) => ({
  type: Types.ADD_COURSE_TO_SAVED_LIST_SUCCESS,
  payload,
});

export const removeCourseToSavedListFromProductSuccess = (payload) => ({
  type: Types.REMOVE_COUESE_FORM_SAVED_LIST_FORM_PRODUCT_SUCCESS,
  payload,
});

export const removeCourseToSavedListFromProfileSuccess = (payload) => ({
  type: Types.REMOVE_COUESE_FORM_SAVED_LIST_FORM_PROFILE_SUCCESS,
  payload,
});

export const getStudentCoursesListSuccess = (payload) => ({
  type: Types.GET_STUDENT_COURSES_LIST_SUCCESS,
  payload,
});

export const showAllReviewsForCurrentCourseSuccess = (payload) => ({
  type: Types.SHOW_ALL_REVIEWS_FOR_CURRENT_COURSE_SUSSESS,
  payload,
});

export const addReviewSuccess = (payload) => ({
  type: Types.ADD_REVIEW_SUSSESS,
  payload,
});

