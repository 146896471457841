import { Stack, Typography, Button, Divider, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import ModelBox from 'components/Atoms/Model/ModalBox';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { walletFilterByModel } from 'services/Modules/Models/Actions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from "dayjs";
import { getListTransactionsWithFilterRequest } from 'services/Modules/Wallet';


export default function WalletFilterByForm() {
    const dispatch = useDispatch()
    const { isWalletFilterByModel } = useSelector(state => state?.model)
    const { blackFour, greySix } = useThemePalette()
    const [dateFromValue, setDateFromValue] = useState(null);
    const [dateToValue, setDateToValue] = useState(null);
    const [value, setValue] = useState('');
    const [error, setError] = useState({ dataFrom: false, dateTo: false })

    const resetValues = () => {
        setDateFromValue(null)
        setDateToValue(null)
        setValue('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (dateFromValue == null && dateToValue == null) {
            setError({ dataFrom: true, dateTo: true })
            return
        } else if (dateFromValue == null) {
            setError({ dataFrom: true, dateTo: false })
            return
        } else if (dateToValue == null) {
            setError({ dataFrom: false, dateTo: true })
            return
        } else {
            setError({ dataFrom: false, dateTo: false })
        }
        const from = dayjs(dateFromValue).format("YYYY-MM-DD")
        const to = dayjs(dateToValue).format("YYYY-MM-DD")
        dispatch(getListTransactionsWithFilterRequest({
            page_number: 1, page_size: 3, from, to, type: value,
            action: () => {
                dispatch(walletFilterByModel())
                resetValues()
            }
        }))
    };


    return <>
        <ModelBox
            openVar={isWalletFilterByModel}
            closeFunc={() => {
                dispatch(walletFilterByModel());
            }}
            title='Filter by date'
            titleStyle={{ fontWeight: '600', fontSize: '22px', color: blackFour }}
            px="30px"
            justifyContent='flex-start'
        >
            <form onSubmit={e => handleSubmit(e)}>
                <Stack sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "18px" }} color={blackFour} fontWeight="600">
                        From
                    </Typography>
                    <DatePicker
                        value={dateFromValue}
                        onChange={(newValue) => setDateFromValue(newValue)}
                        name="from"
                        views={["year", "month", "day"]}
                        format="YYYY-MM-DD"
                        InputProps={{
                            sx: { borderRadius: '30px' },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '30px',
                            }
                        }}
                    />
                    {error?.dataFrom && <Typography sx={{ ml: '10px', fontSize: '14px', color: 'error.main', pt: '5px' }} >Date must be entered</Typography>}
                </Stack>
                <Stack sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "18px" }} color={blackFour} fontWeight="600">
                        To
                    </Typography>
                    <DatePicker
                        value={dateToValue}
                        onChange={(newValue) => setDateToValue(newValue)}
                        name="from"
                        views={["year", "month", "day"]}
                        format="YYYY-MM-DD"
                        InputProps={{
                            sx: { borderRadius: '30px' },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '30px',
                            }
                        }}
                    />
                    {error?.dateTo && <Typography sx={{ ml: '10px', fontSize: '14px', color: 'error.main', pt: '5px' }} >Date must be entered</Typography>}
                </Stack>

                <Divider sx={{ my: '40px' }} />

                <Stack direction='row' alignItems='flex-start' mb={2} >
                    <Typography
                        sx={{ fontWeight: '600', fontSize: '22px', color: blackFour }}
                        component="h2"
                    >
                        Filter by transaction
                    </Typography>
                </Stack>

                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    >
                        <FormControlLabel
                            sx={{ color: blackFour }}
                            value="addition"
                            control={<Radio size="small" sx={{ color: greySix, }} />}
                            label="Addition"
                        />
                        <FormControlLabel
                            sx={{ color: blackFour }}
                            value="subtraction"
                            control={<Radio size="small" sx={{ color: greySix }} />}
                            label="Subtraction"
                        />
                    </RadioGroup>
                </FormControl>

                <Divider sx={{ my: '40px' }} />

                <Stack direction='row' justifyContent='space-between'>
                    <Button onClick={resetValues} sx={{ width: '49%', py: '10px', fontSize: '20px', fontWeight: '600' }} variant='outlined' >Reset</Button>
                    <Button type='submit' sx={{ width: '49%', boxShadow: 'none', py: '10px', fontSize: '20px', fontWeight: '600' }} variant='contained'  >Save</Button>
                </Stack>
            </form>
        </ModelBox>
    </>
}
