import React from "react";
import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";

const OutlineTree = ({
  type,
  data,
  setPageNumber,
  index,
  pageNumber,
  length,
}) => {
  const [isLesson, setLesson] = useState({ state: false, idx: 0 });
  const [isPage, setPage] = useState({ state: false, idx: 0 });
  const { blackTwo, blackThree, primaryColor, transparentGray } =
    useThemePalette();

  const handelTreetoggle = (func, index, isToggle) => {
    if (isToggle?.idx === index) {
      func({ state: !isToggle?.state, idx: index });
    } else {
      func({ state: true, idx: index });
    }
  };
  return (
    <div
      onClick={() => setPageNumber(data?.page_from)}
      style={{
        border: "0",
        cursor: "pointer",
        fontWeight: "600",
        textTransform: "capitalize",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          borderBottom: !length && `1px solid ${transparentGray}`,
          py: "14px",
        }}
        alignItems="center"
        columnGap="5px"
      >
        <Box
          onClick={(e) => {
            e.stopPropagation();
            handelTreetoggle(setLesson, index, isLesson);
          }}
        >
          {data?.children?.length !== 0 ? (
            isLesson?.state && isLesson?.idx === index ? (
              <KeyboardArrowDownIcon sx={{ color: blackTwo }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ color: blackTwo }} />
            )
          ) : null}
        </Box>
        <Typography
          sx={{
            marginRight: data?.children?.length !== 0 ? "" : "50px",
            fontWeight: "600",
            color: type === "modal" && "white",
            fontSize: "16px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setPageNumber(data?.page_from);
          }}
        >
          {data.title}
        </Typography>
      </Stack>
      <div
        style={{
          height: isLesson?.state && isLesson?.idx == index ? "auto" : "0",
          overflow: "hidden",
          marginLeft: "15px",
        }}
      >
        {data?.children?.map((lesson, indx) => {
          return (
            <>
              <Stack
                className="d-flex align-items-center"
                direction="row"
                onClick={(e) => {
                  e.stopPropagation();
                  handelTreetoggle(setPage, indx, isPage);
                }}
                alignItems="center"
                key={indx}
              >
                {lesson?.children?.length !== 0 ? (
                  isPage?.state && isPage?.idx === indx ? (
                    <KeyboardArrowDownIcon sx={{ color: blackThree }} />
                  ) : (
                    <KeyboardArrowRightIcon sx={{ color: blackThree }} />
                  )
                ) : null}
                <Typography
                  style={{
                    marginLeft: lesson?.children?.length != 0 ? "" : "25px",
                    marginBlock: "8px",
                    color: type === "modal" && "white",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPageNumber(lesson?.page_from);
                  }}
                >
                  {lesson?.title}
                </Typography>
              </Stack>
              <div
                style={{
                  height: isPage?.state && isPage?.idx === indx ? "auto" : "0",
                  overflow: "hidden",
                  marginLeft: "15px",
                }}
              >
                {lesson?.children?.map((val, idx) => {
                  // NOTE eh lazmt el condation da ?
                  // if (idx + 1 >= lesson?.page_from && idx + 1 <= lesson?.page_to)
                  return (
                    <div
                      key={idx}
                      style={{
                        padding: "2px",
                        color:
                          pageNumber === val?.page_from
                            ? primaryColor
                            : blackTwo,
                        p: "5px",
                        marginLeft: "20px",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPageNumber(val?.page_from);
                      }}
                    >
                      {val?.title}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default OutlineTree;
