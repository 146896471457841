import { Stack, Typography } from '@mui/material'
import React from 'react'

export default function NoDataYet({ data, py }) {
    return (
        <Stack width="100%" alignItems="center" py={py ? py : '3rem'}>
            <Typography color={"error.main"}>No {data} Yet</Typography>
        </Stack>
    )
}
