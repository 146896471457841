import React from "react";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import { Stack } from "@mui/material";
import MembersContent from "./MembersContent";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";
export default function Members() {
  return (
    <>
     <Stack
            sx={{ width: '100%', backgroundColor: '#F3F3F3' }}
            direction='row'
            justifyContent='space-between'
            alignItems= 'center'
            py={10}
        >
      <CustomContainer>
      {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
      <FrequentSkelton  
        title="What Members Say About Us"
      ></FrequentSkelton>
      {/* ------------------------------ Display Static Img and Title ----------------------- */}
            <MembersContent/>
     </CustomContainer>
     
  </Stack>
    </>
  );
}
