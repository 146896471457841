import { Stack, Container } from "@mui/material";
import FrequentSkelton from "../../Atoms/SharedComponents/FrequentSkelton";
import CustomSlider from "../../Atoms/SharedComponents/CustomSlider";
import CoursesCard from "../../Atoms/SharedComponents/Cards/CoursesCard";
import Loading from "../../Atoms/Loading/Loading";
import NoDataYet from "../../Atoms/NoDataYet";

export default function MostPopular({
  title,
  subTitle,
  coursesArr,
  coursesLoading,
}) {
  // console.log(coursesArr, "coursesArr");

  return (
    <>
      <FrequentSkelton title={title} subtitle={subTitle} paddingBottom='4rem'>
        {coursesLoading ? (
          <Loading />
        ) : coursesArr.length <= 0 ? (
          <NoDataYet data='Courses' />
        ) : (
          <CustomSlider
            className='home-page'
            slides1024={3}
            slides1020={1}
            slides600={1}
          >
            {coursesArr?.map((data, i) => (
              <Stack key={i} maxWidth='272px'>
                <CoursesCard data={data} />
              </Stack>
            ))}
          </CustomSlider>
        )}
      </FrequentSkelton>
    </>
  );
}
