import Types from "./Types";

export const getBookMarksSuccess = (payload) => ({
  type: Types.GET_BOOK_MARKS_SUCCESS,
  payload,
});

export const bookMarksLoading = (payload) => ({
  type: Types.BOOK_MARKS_LOADING,
  payload,
});

export const createBookMarkSuccess = (payload) => ({
  type: Types?.CREATE_BOOK_MARK_SUCCESS,
  payload,
});

export const removeBookMarkSussess = (payload) => ({
  type: Types.REMOVE_BOOK_MARK_SUCCESS,
  payload,
});
