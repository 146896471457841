import React from 'react';
import { Stack } from '@mui/material';
import HomePageCards from '../../../Atoms/SharedComponents/LandingPage/HomePageCards';
import member1 from '../../../../Assets/Images/person1.png';
import member2 from '../../../../Assets/Images/person2.png';
import member3 from '../../../../Assets/Images/person3.png';
import member4 from '../../../../Assets/Images/person4.png';
import CustomContainer from 'components/Atoms/SharedComponents/LandingPage/CustomContainer';

const MembersContent = ({icon}) => {
  const cards = [
    {
      title: 'Heko Feisher',
      subtitle: '“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin”',
      icon: member1 
    },
    {
      title: 'Heko Feisher',
      subtitle: '“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin”',
      icon: member2 
    },
    {
      title: 'Heko Feisher',
      subtitle: '“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin”',
      icon: member3
    },
    {
      title: 'Heko Feisher',
      subtitle: '“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin”',
      icon: member4
    }
  ];

  return (
    <CustomContainer>  
      <Stack sx={{ flexDirection: { xs: 'column' , sm:'row' }}} 
                justifyContent='space-between'
                width='100%'
                mt="50px"
                >            {cards.map((card, index) => (
              <HomePageCards 
                sx={{
                  boxShadow: "0 3px 6px 0 #ffff",
                }}
                key={index} 
                {...card}  
                backgroundColor="white"
                imageContainer={(
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100px', transform: 'translateY( -50%)', borderRadius: '50%' }}>
                    <img src={card.icon} alt="Icon"  />
                  </div>
                )}/>
              ))}
        </Stack>
        </CustomContainer>
  );
};

export default MembersContent;
