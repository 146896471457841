import { Box, Container, Stack } from "@mui/material";
import React from "react";
import LandingPageHeaderTitle from "./LandingPageHeaderTitle";

export default function FrequentSkelton({
  children,
  title,
  subtitle,
  bg = "rgba(0, 0, 0, 0.07)",
  backgroundColor,
  ...rest
}) {
  return (
    <>
      <Box sx={{  backgroundColor , width: "100%" }} {...rest}>
        <Stack alignItems="center" justifyContent="center">
          <LandingPageHeaderTitle title={title} subtitle={subtitle} />
          {children}
        </Stack>
      </Box>
    </>
  );
}
