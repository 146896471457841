import { FormControl, FormHelperText, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import "./Select.css";
import { useThemePalette } from "common/hooks/useThemePalatte";
import { Stack } from "@mui/system";

function SelectBox({
    items,
    title,
    value,
    onchange,
    id,
    name,
    error,
    helperText,
    labelStyle,
    label,
    style,
    disabled,
    className,
    defaultValue,
    sx,
    placeholder,
    menuItemStyle,
    variant = "standard",
    onclick = () => { },
}) {
    const { inputBg, secondryColor } = useThemePalette();

    return (
        <FormControl
            disabled={disabled}
            fullWidth
            style={style}
            error={error ? true : false}
            className={className}
        >
            {label && <InputLabel id={id}>{label}</InputLabel>}
            <Select
                displayEmpty
                id={id}
                name={name}
                defaultValue={defaultValue}
                inputProps={{ "aria-label": "Without label" }}
                value={value}
                onChange={onchange}
                label={label}
                placeholder={placeholder}
                variant={variant}
                sx={{
                    width: "100%",
                    border: "none",
                    ...sx,
                }}
            >
                <MenuItem
                    value=""
                    disabled
                    sx={{
                        // direction: localStorage.getItem("lang") === "en" ? "ltr" : "rtl",
                        color: "GrayText",
                        bgcolor:'#fff',
                        
                    }}
                >
                    {title}
                </MenuItem>
                {items?.map((item) => (
                    <MenuItem
                        value={item.id}
                        key={item.id}
                        onClick={() => onclick(item.id)}
                        sx={menuItemStyle}
                        
                    // style={{ direction: localStorage.getItem("lang") === "en" ? "ltr" : "rtl" }}
                    >
                        {item?.icon ? <img style={{ margin: "0 10px" }} width='30px' height='17px'  src={item?.icon} alt="item icon" /> : ""}{" "}
                        {item?.name}
                    </MenuItem>
                ))}
            </Select>
            {error ? <FormHelperText>{helperText}</FormHelperText> : ""}
        </FormControl>
    );
}

export default SelectBox;
