import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import TurnedInNotOutlinedIcon from "@mui/icons-material/TurnedInNotOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PaperComponent from "../../../Atoms/Paper/PaperComponent";

export default function SideNavbar({ logOutFun }) {
  const { transparentGray, blackTwo, secondryColor } = useThemePalette();
  const [isActive, setIsActive] = useState(0);
  const navigate = useNavigate();

  const menu_items = [
    {
      icon: AutoStoriesOutlinedIcon,
      title: "My courses",
      path: "/website/profile/courses",
      index: 0,
    },
    {
      icon: Person2OutlinedIcon,
      title: "Account info",
      path: "/website/profile/accountInfo",
      index: 1,
    },
    {
      icon: AccountBalanceWalletOutlinedIcon,
      title: "Wallet",
      path: "/website/profile/wallet",
      index: 2,
    },
    {
      icon: StarsOutlinedIcon,
      title: "Points",
      path: "/website/profile/points",
      index: 3,
    },
    {
      icon: TurnedInNotOutlinedIcon,
      title: "Saved list",
      path: "/website/profile/saveList",
      index: 4,
    },
    {
      icon: SettingsOutlinedIcon,
      title: "Settings",
      path: "/website/profile/settings",
      index: 5,
    },
    { icon: LogoutOutlinedIcon, title: "Logout", fun: logOutFun },
  ];
  const { pathname } = useLocation();
  return (
    <>
      <PaperComponent
        sx={{ boxShadow: "none", borderRadius: "20px", width: "100%" }}
      >
        <Stack>
          <List sx={{ mx: "20px" }}>
            {menu_items?.map((item, i) => (
              <ListItem
                key={i}
                disablePadding
                onClick={() => {
                  item?.fun ? item.fun() : navigate(item.path);
                  setIsActive(i);
                }}
              >
                <ListItemButton
                  sx={{
                    py: "20px",
                    borderBottom:
                      i == menu_items.length - 1
                        ? ""
                        : `1px solid ${transparentGray}`,
                    color: pathname == item.path ? secondryColor : blackTwo,
                  }}
                >
                  <ListItemIcon>
                    {/* <img src={item?.icon} alt='courses' /> */}
                    <item.icon
                      sx={{
                        color: pathname == item.path ? secondryColor : blackTwo,
                        fontSize: "22px",
                        fontWeight: "100 !important",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.title}
                    sx={{ fontWeight: 500 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
      </PaperComponent>
    </>
  );
}
