const ModelType = {
    SIGN_UP_MODEL: 'SIGN_UP_MODEL',
    LOG_IN_MODEL: 'LOG_IN_MODEL',
    VERIFICATIOM_MODEL: 'VERIFICATIOM_MODEL',
    FORGET_PASSWORD_MODEL: 'FORGET_PASSWORD_MODEL',
    CREATE_NEW_PASSWORD_MODEL: 'CREATE_NEW_PASSWORD_MODEL',
    CHANGE_PASSWORD_MODEL: 'CHANGE_PASSWORD_MODEL',
    DELETE_ACCOUNT_MODEL: 'DELETE_ACCOUNT_MODEL',
    STUDENT_REVIEW_MODEL: 'STUDENT_REVIEW_MODEL',
    WALLET_FILTER_BY_MODEL: 'WALLET_FILTER_BY_MODEL',
    CHARGE_WALLET_MODEL: 'CHARGE_WALLET_MODEL',
    WALLET_CHARGED_SUCCESSFULLY_MODEL: "WALLET_CHARGED_SUCCESSFULLY_MODEL"
};
export default ModelType;