import Types from "./Types";

const WALLET_INIT_STATE = {
  transactionsData: {},
  rechargeByCodeData:{},
  rechargeByFawryData:{},
  rechargeData:{},
  isLoading: {
    type: "",
    loading: false,
  },
};

export function walletReducer(state = WALLET_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_LIST_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactionsData: payload?.data,
      };
    }
    case Types.GET_LIST_TRANSACTIONS_WITH_FILTER_SUCCESS: {
      return {
        ...state,
        transactionsData: payload?.data,
      };
    }
    case Types.RECHARGR_BY_CODE_SUCCESS: {
      return {
        ...state,
        rechargeByCodeData: payload?.data,
      };
    }
    case Types.RECHARGR_BY_FAWRY_SUCCESS: {
      return {
        ...state,
        rechargeByFawryData: payload?.data,
      };
    }
    case Types.RECHARGR_SUCCESS: {
      return {
        ...state,
        rechargeSuccess: payload?.data,
      };
    }
    default: {
      return state;
    }
  }
}
