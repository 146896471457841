import React from "react";
import MyCoursesTemplate from "../../Template/Profile/MyCourses.Template";
import { getStudentCoursesListRequest } from "../../services/Modules/Courses";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function MyCoursesPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudentCoursesListRequest({ page_number: 1, page_size: 10 }));
  }, []);

  const { studentCoursesList } = useSelector((state) => state?.courses);
  return <MyCoursesTemplate studentCoursesList={studentCoursesList} />;
}
