import React from "react";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import VideoViewTabs from "./VideoTabs";
import VimeoPlayer from "../../../Atoms/SharedComponents/VimeoPlayer";
import { useDispatch } from "react-redux";
import { completeSessionRequest } from "../../../../services/Modules/Courses";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useThemePalette } from "common/hooks/useThemePalatte";
import { getMeetingInfoRequest, getZoomCredRequest } from "services/Modules/ZoomIntegration";
import { useSelector } from "react-redux";
import * as dayjs from "dayjs";
import ZoomMeeting from "./ZoomMeeting";
import { useNavigate } from "react-router-dom";

// import { getZoomCredRequest } from "services/Modules/ZoomIntegration";
// import { useSelector } from "react-redux";
// import ZoomMeeting from "./ZoomMeeting";
// import { Routes, Route } from "react-router-dom";

export default function ChapterVideo({ closed, sessionChossen }) {
  const dispatch = useDispatch()
  const { course_id, session_id, section_id } = useParams()
  const [completed, setCompleted] = useState()
  const { blackTwo, whiteTwo } = useThemePalette()

  useEffect(() => {
    setCompleted(sessionChossen?.progress?.value)
  }, [sessionChossen?.progress?.value])


  return (
    <>
      <Stack>
        <Stack
          sx={{
            width: "100%",
            // position: "relative",
            justifyContent: "center",
            alignItems: "center",
            mb: '32px'
          }}
        >
          {sessionChossen?.video_url && (
            <VimeoPlayer className="vimeo-video" video_url={sessionChossen?.video_url} />
          )}
          {closed && (
            <Box
              sx={{
                position: "absolute",
                bgcolor: "rgba(0,0,0,.65)",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "37.67%",
                  left: "32%",
                  transform: 'translate("-50%","-50%")',
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    my: 1,
                    letterSpacing: "0.12rem",
                    color: "white",
                    fontSize: "1.1rem",
                    fontWeight: 600,
                  }}
                >
                  Unlock this session and enjoy it
                </Typography>
                <Button variant="contained" sx={{ my: 1, px: "50px" }}>
                  Enroll Chapter
                </Button>
              </Box>
            </Box>
          )}
        </Stack>

        {sessionChossen?.zoom?.map((zoom) => zoom?.status == 'started' &&
          <Stack sx={{ my: '40px' }}>
            <Paper sx={{ boxShadow: 'none', p: '16px', borderRadius: '10px' }} >
              <Typography
                sx={{ mt: '3px', color: blackTwo, fontWeight: 700, fontSize: '18px' }}
              >
                Zoom Meeting 1
              </Typography>
              <Divider sx={{ border: `2px solid ${whiteTwo}`, my: '16px' }} />
              <Stack>
                <Typography>Meeting id : {zoom?.meeting_id}</Typography>
                <Typography>Password  : {zoom?.meeting_password}</Typography>
                <Typography>Url  : {zoom?.join_url}</Typography>
                <Typography>Time  : {dayjs(zoom?.start_time).format("h:mm A")}</Typography>
                <Stack direction='row' justifyContent='space-between' >
                  <Typography>Date  : {dayjs(zoom?.start_time).format("D MMMM YYYY")}</Typography>
                  <Box >
                    <Button
                      variant='contained'
                      target="_blank"
                      href={zoom?.join_url}
                      sx={{ px: '70px' }}
                      disableElevation
                    >
                      Join
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        )}
        {/* <a href='/somefile.txt' download>Click to download</a> */}

        {sessionChossen?.zoom?.map((zoom) => zoom?.state == 'ended' &&
          <Stack sx={{ my: '40px' }}>
            <Paper sx={{ boxShadow: 'none', p: '16px', borderRadius: '10px' }} >
              <Typography
                sx={{ mt: '3px', color: blackTwo, fontWeight: 700, fontSize: '18px' }}
              >
                Zoom Meeting 1
              </Typography>
              <Divider sx={{ border: `2px solid ${whiteTwo}`, my: '16px' }} />
              <Stack>
                <Typography>Time  : {dayjs(zoom?.start_time).format("h:mm A")}</Typography>
                <Stack direction='row' justifyContent='space-between' >
                  <Typography>Date  : {dayjs(zoom.start_time).format("D MMMM YYYY")}</Typography>
                  <Box >
                    <Button
                      variant='contained'
                      // target="_blank"
                      href={'/somefile.txt'}
                      sx={{ px: '30px' }}
                      disableElevation
                      download
                    >
                      Download link
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        )}


        {sessionChossen?.progress?.value != 'completed' ? <Box>
          <Button
            variant='contained'
            onClick={_ => dispatch(completeSessionRequest({
              course_id,
              section_id,
              course_session_id: session_id,
              action: () => window.location.reload()
            }))}
          >
            Complete Video
          </Button>
        </Box> :
          <Stack direction="row" columnGap="8px">
            <CheckIcon sx={{ color: "primary.main" }} />
            <Typography sx={{ color: "primary.main", fontSize: "15px" }}>
              video Completed{" "}
            </Typography>
          </Stack>
        }
        {sessionChossen?.video_url && (
          <Box className="video-view-tabs" sx={{ mt: "26px" }}>
            <VideoViewTabs sessionChossen={sessionChossen} />
          </Box>
        )}
      </Stack>
    </>
  );
}
