import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getAllReviewsRequest,
  getGuestCourseDetailsRequest,
} from "../../../services/Modules/Courses";
import {
  fetchAllSubjectsRequest,
  fetchFilteredSubjectsRequest,
} from "../../../services/Modules/Subjects";
import CoursesOverview from "../../../Template/Courses/CoursesOverview/CoursesOverviewTemplate";

export default function CoursesOverviewPage({setBgColor}) {
  const dispatch = useDispatch();
  const { course_id } = useParams();
  const {
    guestCourseDetails,
    studentCoursesDetailsLoading,
    reviews,
    sessions,
  } = useSelector((state) => state.courses);
  const { filterdSubject, allSubjects } = useSelector(
    (state) => state.subjects
  );
  const [finalData, setFinalData] = useState([]);
  const [searchParam] = useSearchParams();
  const subjectId = searchParam.get("subject_id");

  useEffect(() => {
    subjectId !== "0"
      ? dispatch(
          fetchFilteredSubjectsRequest({
            page_number: 1,
            page_size: 10,
            subject_id: subjectId,
          })
        )
      : dispatch(fetchAllSubjectsRequest({ page_number: 1, page_size: 4 }));
  }, [subjectId]);

  useEffect(() => {
    subjectId === "0"
      ? setFinalData(allSubjects)
      : setFinalData(filterdSubject);
  }, [subjectId, allSubjects, filterdSubject]);

  useEffect(() => {
    dispatch(getGuestCourseDetailsRequest({ course_id }));
    setBgColor("rgba(0, 0, 0, 0.07)")
  }, []);

  useEffect(() => {
    guestCourseDetails?.product_id &&
      dispatch(
        getAllReviewsRequest({ product_id: guestCourseDetails?.product_id })
      );
  }, [guestCourseDetails?.product_id]);

  return (
    <CoursesOverview
      studentCoursesDetails={guestCourseDetails}
      studentCoursesDetailsLoading={studentCoursesDetailsLoading}
      filterdSubjectData={finalData}
      reviews={reviews}
      sessions={sessions}
    />
  );
}
