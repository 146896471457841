import { Box, Button, Stack, Typography } from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import { useNavigate } from 'react-router-dom'

export default function BlogCards ({ data, titleHeight = '96px', dataColor, titleStyle, action, sx }) {
    const { primaryColor, lightRed } = useThemePalette()
    const navigate = useNavigate()
    return <>
        <Stack
            sx={{
                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                borderRadius: '20px',
                height: '100%',
                ...sx
            }}
        >
            <Box>
                <Box component='img' width='100%' src={data?.img} alt='blog img' />
            </Box>
            <Stack
                p='32px'
            >
                <Typography
                    fontWeight='600'
                    fontSize='24px'
                    lineHeight='36px'
                    color={primaryColor}
                    mb='8px'
                    height={titleHeight}
                    {...titleStyle}
                >
                    {data?.title?.length > 95 ? data?.title.substring(0, 95).concat("...") : data?.title}
                </Typography>
                <Stack direction='row' justifyContent='space-between' mb='32px'>
                    <Box flex='1' />
                    <Typography fontWeight='500' color={dataColor ? dataColor : lightRed}>
                        {data?.date}
                    </Typography>
                </Stack>
                <Box color="gray" >
                    <Typography
                        fontSize='18px'
                        fontWeight='400'
                        lineHeight='35px'
                    >
                        {data?.description?.length > 290 ? data?.description.substring(0, 290).concat("...") : data?.description }
                   </Typography>
                </Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={_ => action ? action() : data?.path ? navigate(data?.path) : ''}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ alignSelf: 'flex-end', textTransform: 'none' }}
                >
                  Read More
                </Button>
            </Stack>
        </Stack>
    </>
}
