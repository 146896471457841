import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';

export default function HomePageCards ({ title, subtitle, icon , backgroundColor, imageContainer}) {
  const { primaryColor } = useThemePalette();

  return (
    <Stack  justifyContent= 'space-between' width='23%'  >
        <Stack  alignItems="center"   sx={{ borderRadius: '15px', backgroundColor }}>
            {imageContainer}
            <Box style={{ textAlign: 'center', display: 'flex', flexDirection: 'column',alignItems: 'center'}}>
            <Typography variant="h6"   style={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
            <Typography  py={3} px={8} variant="subtitle1" color="text.secondary">
              {subtitle}
            </Typography>
          </Box>
        </Stack>
    </Stack>
  );
};


