import React from "react";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import { Stack } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";
import PricingContent from "./PricingContent";

export default function PricingCards({ pricingData }) {
  return (
    <>
      <CustomContainer>
        <Stack
          alignItems="center"
          sx={{ backgroundColor: "white" }}
          py={15}
        >
          {/* Custom Component Adding bg-color and some styles */}
          <FrequentSkelton
            title="Pricing"
            subtitle="Choose from price packages what suits your business most"
          />
          {/* Display Cards */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }} 
            justifyContent="space-between"
            flexWrap='wrap'
            width='100%'
          >
            {pricingData.map((pricing, index) => (
                <Stack key={index} width={{  md: '32%', xs: '100%',sm:'48%' }} mb="20px">
                    <PricingContent
                    title={pricing.title}
                    price={pricing.price}
                    points={pricing.points}
                    showSubtitle={index >= pricingData.length - 2} 
                    subtitleText="Billed monthly"
                    />
                </Stack>
            ))}
          </Stack>
        </Stack>
      </CustomContainer>
    </>
  );
}
