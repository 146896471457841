import React from "react";
import { Typography, Stack } from "@mui/material";
import CustomSlider from "../../../Atoms/SharedComponents/CustomSlider";
import CoursesCard from "../../../Atoms/SharedComponents/Cards/CoursesCard";
import { useSearchParams } from "react-router-dom";

export default function CoursesListSlider({ allSubjectData }) {
  const [searchParams] = useSearchParams();
  return (
    <>
      <Stack alignItems="center">
        <Typography
          sx={{
            fontSize: "1.6rem",
            lineHeight: "45px",
            fontWeight: 700,
            letterSpacing: "1.2px",
            color: "#343434",
          }}
        >
          Design Courses
        </Typography>
      </Stack>
      <Stack alignItems="center">
        <Typography
          sx={{
            fontSize: "23px",
            fontWeight: 700,
            lineHeight: "45px",
            color: "#ffba00",
          }}
        >
          Courses to get you started
        </Typography>
      </Stack>
      <Stack sx={{ py: 2, mb: "6%" }}>
        <CustomSlider
          className="courses-details"
          slides1024={4}
          slides1020={1}
          slides600={1}
        >
          {allSubjectData?.map((data, i) => {
            console.log(data);
            return (
              <Stack key={i} maxWidth="272px">
                <CoursesCard
                  data={data}
                  url={`/website/courses-details/${
                    data?.classified_product?.course_id
                  }?subject_name=${searchParams.get(
                    "subject_name"
                  )}&subject_id=${searchParams.get("subject_id")}`}
                />
              </Stack>
            );
          })}
        </CustomSlider>
      </Stack>
    </>
  );
}
