import React from "react";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import { Stack, Button } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";
import EventsCards from "./EventsCards";

export default function Events({eventsData}) {
  return (
    <>
<CustomContainer>    
    <Stack 
        alignItems="center"
        sx={{ backgroundColor: "white" }}
    >
          
      {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
      <FrequentSkelton
        title="Events We Have Been To"
        subtitle="We engage in networking to gain and exchange knowledge with potential partners all over the world because we believe that events connect people, breed innovation, build communities and spark change.We engage in networking to gain and exchange knowledge with potential partners all over the world because we believe that events connect people, breed innovation, build communities and spark change."
      ></FrequentSkelton>
        <Stack
                direction="row"
                justifyContent="space-between"
                flexWrap='wrap'
                width='100%'
            >
                {eventsData?.map((data, i) => (
                    <Stack key={i} width={{ lg: '31%', md: '48%', xs: '100%' }} mb="20px">
                        <EventsCards data={data} />
                    </Stack>
                ))}
                <Stack py={5}>
                    <Button
                            disableElevation
                            disableRipple
                            variant="contained"
                            sx={{ ml: { lg: '1rem', md: '0rem' }, my: '0.5rem', p: { lg: '9px 27px', md: '5px 10px' } }}
                            aria-label='show more'   
                    >
                            Show More
                    </Button>
                </Stack>
            </Stack>        
        
    </Stack>
    </CustomContainer> 
    </>
  );
}
