import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Tab } from '@mui/material'
import React from 'react'
import { useState } from 'react';

export default function CustomTabs({ tabsData, tabPanelData, justifyContent, buttonWidth, indicatorWidth, fontWeight }) {
    const [value, setValue] = useState('1');

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    return <>
        <TabContext value={value}  >
            <Paper elevation={0} sx={{ px: { md: '80px', xs: '5px' }, borderRadius: '10px' }}  >
                <TabList
                    aria-label='Tabs exmple'
                    onChange={handleChange}
                    textColor='primary'
                    indicatorColor='primary'
                    sx={{
                        pt: '10px',
                        '& .MuiTabs-flexContainer': {
                            justifyContent: justifyContent
                        },
                        '& .MuiTabs-indicator': {
                            width: indicatorWidth,
                        },
                        '& .MuiButtonBase-root': {
                            width: buttonWidth,
                            color: 'rgba(123, 123, 123, 0.6)'
                        },
                    }}
                >
                    {tabsData?.map(tab => <Tab key={tab.value} sx={{ textTransform: 'capitalize', fontWeight: fontWeight }} label={tab.label} value={tab.value} />)}
                </TabList>
            </Paper>
            <Box mx={{ md: '0px', xs: '0px' }} my='4px' >
                {tabPanelData?.map(tabPanel => <TabPanel sx={{ p: 0, mt: '38px' }} key={tabPanel.value} value={tabPanel.value}>{tabPanel.panel}</TabPanel>)}
            </Box>
        </TabContext>
    </>
}
