import React from "react";
import CoursesOverview from "../../../components/Organisms/Courses/CoursesOverview/CoursesOverview";

export default function CoursesOverviewTemplate({
  studentCoursesDetails,
  reviews,
  studentCoursesDetailsLoading,
  filterdSubjectData,
}) {
  return (
    <CoursesOverview
      studentCoursesDetails={studentCoursesDetails}
      studentCoursesDetailsLoading={studentCoursesDetailsLoading}
      filterdSubjectData={filterdSubjectData}
      reviews={reviews}
    />
  );
}
