import React, { useState } from "react";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import { Stack, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";
import PlusIcon from "../../../../Assets/Images/Plus.svg";
import MinusIcon from "../../../../Assets/Images/MinusCircle.svg";

export default function Faq({ faqData }) {
  const [expanded, setExpanded] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <CustomContainer>
        <Stack
          alignItems="center"
          sx={{ backgroundColor: "white" }}
          py={7}
        >
          {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
          <FrequentSkelton
            title="Frequently Asked Question"
            subtitle="General Questions"
          ></FrequentSkelton>
          {faqData.map((faqItem, index) => (
            <Accordion
              key={index}
              expanded={expanded === index}
              onChange={handleChange(index)}
              sx={{ backgroundColor: "primary.main", width: "100%", mb: 2, }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === index ? (
                    <Box component="img" src={MinusIcon} />
                  ) : (
                    <Box component="img" src={PlusIcon} />
                  )
                }
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                sx={{ height: "100px" }}
              >
                <Typography variant="h6" color="white">
                  {faqItem.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails >
                <Typography color="white">{faqItem.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </CustomContainer>
    </>
  );
}
