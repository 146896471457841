import {
  bookMarksLoading,
  createBookMarkSuccess,
  getBookMarksSuccess,
  removeBookMarkSussess,
} from "./Actions";
import { reduxRequest } from "../../../common/utils/reduxRequest";

export const getBookMarksRequest = ({ book_id }) => {
  const requestData = {
    isLoading: bookMarksLoading,
    successFunction: getBookMarksSuccess,
    url: `books/${book_id}/book_bookmarks`,
    method: "get",
    loadingType: "getBookmarks",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createBookMarkRequest = ({ book_id, ...variables }) => {
  const requestData = {
    isLoading: bookMarksLoading,
    successFunction: createBookMarkSuccess,
    url: `books/${book_id}/book_bookmarks`,
    loadingType: "createBookmark",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const removeBookMarkRequest = ({ book_id, book_bookmark_id }) => {
  const requestData = {
    isLoading: bookMarksLoading,
    successFunction: removeBookMarkSussess,
    url: `books/${book_id}/book_bookmarks/${book_bookmark_id}`,
    loadingType: "removeBookmark",
    method: "delete",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
