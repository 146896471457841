import { Typography, Stack, Box, Button } from "@mui/material";
import BannerIcon from "../../../../Assets/Images/banner.png";
import { useThemePalette } from "common/hooks/useThemePalatte";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";

function Header() {
  const { black, warmGray } = useThemePalette();
  const HeaderContent = {
    title: `Learn A new Skill Every day,AnyTime And Any Where.`,
    subtitle: "Solution for you to gain more knowledge, easy and flexible online learning with many subjects of classes that you can choose."
  };

  return (
    <CustomContainer>
      <Stack
        bgcolor="white"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={15}
      >
        <Stack spacing="30px" width="50%">
          <Box>
            <Typography variant="h1" color={black}>
              {HeaderContent.title}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            color={warmGray}
            sx={{
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "42px",
              letterSpacing: "0.5px",
              textAlign: "left",
            }}
          >
            {HeaderContent.subtitle}
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 0 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "fit-content",
                height: "auto",
                padding: "16px 26px",
                borderRadius: "10px",
                marginRight: "20px",
              }}
            >
              Get Started
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: "fit-content",
                height: "auto",
                padding: "12px 27px",
                borderRadius: "10px",
                border: "3px solid",
              }}
            >
              Learn More
            </Button>
          </Stack>
        </Stack>
        <Stack direction="column" width="50%" alignItems="center" justifyContent="center">
          <img
            width="100%"
            height="auto"
            src={BannerIcon}
            alt="banner-icon"
          />
        </Stack>
      </Stack>
    </CustomContainer>
  );
}

export default Header;
