const ebookTypes = {
  GET_BOOK_MARKS_SUCCESS: "GET_BOOK_MARKS_SUCCESS",
  BOOK_MARKS_LOADING: "BOOK_MARKS_LOADING",
  CREATE_BOOK_MARK_SUCCESS: "CREATE_BOOK_MARK_SUCCESS",
  CREATE_BOOK_MARK_LOADING: "CREATE_BOOK_MARK_LOADING",
  REMOVE_BOOK_MARK_SUCCESS: "REMOVE_BOOK_MARK_SUCCESS",
  REMOVE_BOOK_MARK_LOADING: "REMOVE_BOOK_MARK_SUCCESS",
};

export default ebookTypes;
