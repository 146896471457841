import Types from "./Types";

export const GetCartItemsSuccess = (payload) => ({
  type: Types.GET_CART_ITEMS_SUCCESS,
  payload,
});

export const GetCartItemsError = (payload) => ({
  type: Types.GET_CART_ITEMS_ERROR,
  payload,
});

export const cartLoading = (payload) => ({
  type: Types.CART_LOADING,
  payload,
});

export const removeFromCartSuccess = (payload) => ({
  type: Types.REMOVE_FROM_CART,
  payload,
});
export const clearCartSuccess = (payload) => ({
  type: Types.CLEAR_CART_SUCCESS,
  payload,
});


export const AddToCartSuccess = (payload) => ({
  type: Types.ADD_TO_CART_SUCCESS,
  payload,
});
export const checkOutSuccess = (payload) => ({
  type: Types.CHECK_OUT_SUCCESS,
  payload,
});

export const PromoCodeSuccess = (payload) => ({
  type: Types.PROMO_CODE_SUCCESS,
  payload,
});


