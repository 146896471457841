import React from 'react'
import PaymentSection from './PaymentMethods';
import PricingSummary from './PricingSummary';

function Payment({ cartItems }) {
  return (
    <>
      <PaymentSection cartItems={cartItems} />
      <PricingSummary cartItems={cartItems} />
    </>
  );
}
export default Payment