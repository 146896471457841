import React from "react";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import { Stack } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";
import BlogCards from "./BlogCards";

export default function Blog({BlogData, t}) {
  return (
    <>
<CustomContainer>    
    <Stack 
        alignItems="center"
        sx={{ backgroundColor: "white" }}
        py={15}>
          
      {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
      <FrequentSkelton
        title="Blog"
      ></FrequentSkelton>
       <Stack
                direction="row"
                justifyContent="space-between"
                flexWrap='wrap'
                width='100%'
            >
                {BlogData?.map((data, i) => (
                    <Stack key={i} width={{  md: '48%', xs: '100%' }} mb="20px">
                      <BlogCards data={data} t={t} />
                    </Stack>
                ))}
            </Stack>
    </Stack>
    </CustomContainer> 
    </>
  );
}
