import { Box, Button, Container, Stack } from "@mui/material";
import React from "react";
import CoursesDetailsList from "./CoursesDetailsList";
import learningIcon from "../../../../Assets/Images/learningIcon.svg";
import lockIcon from "../../../../Assets/Images/lockIcon.svg";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import CoursesDetailsBoxTitle from "../../../Atoms/SharedComponents/CoursesDetailsBoxTitle";
import NoDataYet from "../../../Atoms/NoDataYet";

export default function CoursesDetailsAssignments({
  studentCoursesDetails,
  handlePreviewClick,
}) {
  const { primaryColor } = useThemePalette();

  return (
    <Box
      id="Assignments"
      sx={{ bgcolor: "#fff" }}
      pt="32px"
      pb="40px"
      px={{ lg: "44px", xs: "20px" }}
    >
      <Stack pt="16px">
        <CoursesDetailsBoxTitle title="Assignments" />
        {studentCoursesDetails?.classified_product?.assignments?.length > 0 ? (
          <>
            {studentCoursesDetails?.classified_product?.assignments?.map(
              (quiz, i) => (
                <CoursesDetailsList
                  data={quiz}
                  titleIcon={learningIcon}
                  listTitle={`Assignment ${i + 1}`}
                  descriptionIcon={lockIcon}
                  key={i}
                  handlePreviewClick={handlePreviewClick}
                />
              )
            )}
            <Box Box alignSelf="center" pb="32px">
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  px: { md: "80px" },
                  border: `1.66px solid ${primaryColor}`,
                  "&:hover": {
                    border: `1.66px solid ${primaryColor}`,
                  },
                }}
              >
                View All Assignments
              </Button>
            </Box>
          </>
        ) : (
          <NoDataYet data="Assignments" />
        )}
      </Stack>
    </Box>
  );
}
