import { combineReducers } from "redux";
import { coursesReducer } from "./Modules/Courses/Reducer";
import { subjectsReducer } from "./Modules/Subjects/Reducer";
import { uploadFileReducer } from "./Modules/UploadFiles/Reducer";
import { bookMarksReducer } from "./Modules/Ebook/Reducer";
import modelReducer from "./Modules/Models/Reducer";
import { authReducer } from "./Modules/Auth/Reducer";
import { walletReducer } from "./Modules/Wallet/Reducer";
import { zoomReducer } from "./Modules/ZoomIntegration/Reducer";
import { cartReducer } from "./Modules/Cart/Reducer";

const rootReducer = combineReducers({
    courses: coursesReducer,
    subjects: subjectsReducer,
    files: uploadFileReducer,
    bookMarks: bookMarksReducer,
    model: modelReducer,
    auth: authReducer,
    wallet: walletReducer,
    zoom:zoomReducer,
    cart:cartReducer
});

export default rootReducer;