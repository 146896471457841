import ModelBox from 'components/Atoms/Model/ModalBox'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WalletChargedSuccessfullyModel } from 'services/Modules/Models/Actions'
import walletCharged from 'Assets/Images/walletCharged.svg'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { useThemePalette } from 'common/hooks/useThemePalatte'
import { useNavigate } from 'react-router-dom'

export default function WalletChargedSuccessfully() {
  const { isWalletChargedSuccessfully } = useSelector(state => state?.model)
  const dispatch = useDispatch()
  const {rechargeSuccess } = useSelector(state => state.wallet)
  const { warmGray2 } = useThemePalette()
  const navigate = useNavigate()
  
  return <>
    <ModelBox
      openVar={isWalletChargedSuccessfully}
      closeFunc={_ => dispatch(WalletChargedSuccessfullyModel())}
      modelBorderRadius='0'
    >
      <Stack justifyContent='center' alignItems='center'>
        <figure>
          <img src={walletCharged} alt='icon' />
        </figure>
        <Stack width='70%' justifyContent='center' alignItems='center'>
          <Typography fontWeight='700' fontSize='22px' mt='40px' >Wallet charged successfully</Typography>
          <Typography textAlign='center' color={warmGray2} my='16px' >
            Your wallet charged with <Typography fontWeight='700' component='span' >{ rechargeSuccess?.chargeItems ?  rechargeSuccess?.chargeItems[0]?.price : rechargeSuccess?.amount}</Typography>  successfully, your current balance is
          </Typography>
          <Typography color={warmGray2} fontSize='22px' fontWeight='700' >{ rechargeSuccess?.chargeItems ?  rechargeSuccess?.chargeItems[0]?.price : rechargeSuccess?.balance}</Typography>
        </Stack>
      </Stack>
      <Divider sx={{ mt: '29px', mb: '40px' }} />
      <Stack alignItems='center' >
        <Box>
          <Button
            variant='contained'
            sx={{ fontWeight: '700', fontSize: '20px', margin: 'auto' }}
            onClick={_=>navigate('website/course-list')}
          >
            Explore courses
          </Button>
        </Box>
      </Stack>
    </ModelBox>
  </>
}
