import { removeCourseFromSavedListFromProfileRequest } from "services/Modules/Courses";
import ProfileCoursesCard from "components/Atoms/SharedComponents/ProfileCoursesCard";
import { useDispatch } from "react-redux";

export default function SavedListTemplate({ courses }) {
  const dispatch = useDispatch();
  return (
    <>
      {courses?.map((course, i) => (
        <ProfileCoursesCard
          key={i}
          data={course}
          labelIcon={true}
          labelAction={(_) =>
            dispatch(
              removeCourseFromSavedListFromProfileRequest({
                bookmark_id: course?.bookmark_id,
              })
            )
          }
        />
      ))}
    </>
  );
}
