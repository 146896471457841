const authTypes = {
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  AUTH_LOADING: "AUTH_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  SEND_RESET_PASSWORD_SUCCESS: "SEND_RESET_PASSWORD_SUCCESS",

  CHECK_EMAIL_EXISTS_SUCCESS: "CHECK_EMAIL_EXISTS_SUCCESS",

  SEND_EMAIL_OTP_SUCCESS: "SEND_EMAIL_OTP_SUCCESS",

  VERIFY_EMAIL_OTP_SUCCESS: "VERIFY_EMAIL_OTP_SUCCESS",

  RESET_PASSWORD_BY_EMAIL_SUCCESS: "RESET_PASSWORD_BY_EMAIL_SUCCESS",

  RESEND_CONFIEMATION_CODE_SUCCESS: "RESEND_CONFIEMATION_CODE_SUCCESS",

  LOG_OUT_SUCCESS: "LOG_OUT_SUCCESS",

  CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
};

export default authTypes;
