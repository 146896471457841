import React from 'react';
import HomePageCards from '../../../Atoms/SharedComponents/LandingPage/HomePageCards';
import findcourse from '../../../../Assets/Images/glass.svg';
import watchvideo from '../../../../Assets/Images/video.svg';
import doassignments from '../../../../Assets/Images/chart.svg';
import getcertificate from '../../../../Assets/Images/Ticket Star.svg';
import CustomContainer from 'components/Atoms/SharedComponents/LandingPage/CustomContainer';
import { Stack } from '@mui/material';

const LearningContent = () => {
  
  const cards = [
    {
      title: 'Find Course',
      subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      icon: findcourse 
    },
    {
      title: 'Watch Video',
      subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      icon: watchvideo 
    },
    {
      title: 'Do Assignments',
      subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      icon: doassignments
    },
    {
      title: 'Get Certificate',
      subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      icon: getcertificate
    }
  ];
  
  return (
    <CustomContainer>
      <Stack sx={{ flexDirection: { xs: 'column' , sm:'row' }}} 
                justifyContent='space-between'
                width='100%'
                mt="50px"
                >
        {cards.map((card, index) => (
          <HomePageCards 
            key={index} 
            {...card} 
            backgroundColor="#DE47471A"
              imageContainer={(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100px', height:'100px', transform: 'translateY( -50%)', borderRadius: '50%', backgroundColor: '#A41E22' }}>
                  <img src={card.icon} alt="Icon" style={{ width: 45, height: 45 }} />
                </div>
              )}
          />
        ))}
      </Stack>
      </CustomContainer>
    );
};

export default LearningContent;
