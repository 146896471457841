/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Container } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Loading from "../../Atoms/Loading/Loading";
import FrequentSkelton from "../../Atoms/SharedComponents/FrequentSkelton";
import TopCategoriesCards from "./TopCategoriesCards";
import TopCategoriesSubjects from "./TopCategoriesSubjects";

export default function TopCategories({
  loading,
  isActive,
  setIsActive,
  finalData,
  categoriesSubjects,
  handleClick,
  clickedSubject,
  studentSavedList,
  setClickedSubject
}) {

  const navigate = useNavigate();
  return (
    <FrequentSkelton
      title="Top Categories"
      subtitle="Learn the latest skills to reach your professional goals"
    >
      <Container maxWidth="xl">
        <Stack alignItems="center" justifyContent="center" width="100%">
          {/* ------------------------------------------------- Slider Shows subjects name ---------------------------------------- */}
          <TopCategoriesSubjects
            setIsActive={setIsActive}
            isActive={isActive}
            categoriesSubjects={categoriesSubjects}
            handleClick={handleClick}
            setClickedSubject={setClickedSubject}
          />
          {/* --------------------------------------------- Card shows courses details ---------------------------------------- */}
          {loading ? (
            <Loading />
          ) : (
            <TopCategoriesCards
              studentSavedList={studentSavedList}
              clickedSubject={clickedSubject}
              finalData={finalData}
            />
          )}
          <Box>
            <Button
              onClick={() =>
                navigate(
                  `/website/course-list?subject_name=${clickedSubject?.name}&subject_id=${clickedSubject?.id}`
                )
              }
              variant="contained"
              sx={{ mb: "6.25rem", mt: "4rem" }}
              aria-label="view more courses"
            >
              View More Courses
            </Button>
          </Box>
        </Stack>
      </Container>
    </FrequentSkelton>
  );
}
