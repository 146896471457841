import {
  IconButton,
  InputBase,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useThemePalette } from "../../../common/hooks/useThemePalatte";
import { useDispatch } from "react-redux";
import { AddPromoCode, getCartItemsRequest } from "services/Modules/Cart";
import { useSelector } from "react-redux";

export default function PromoCodeInput({ cart, cartItems }) {
  const { PromoCodeSuccess } = useSelector((state) => state?.cart);
  
  const { warmGray } = useThemePalette();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [promoCode, setPromoCode] = useState("");
  const dispatch = useDispatch();
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const applyCoupon = (product_id, cart_item_id) => {
    const promoCodeTrimmed = promoCode.trim();
    dispatch(
      AddPromoCode({ product_id, coupon_id: promoCodeTrimmed, cart_item_id })
    );
    setRerenderFlag(() => !rerenderFlag);
  };
  useEffect(() => {
    dispatch(getCartItemsRequest({ page_number: 1, page_size: 10 }));
  }, [PromoCodeSuccess?.data]);

  return (
    <Stack
      sx={{
        display: { xs: "none", md: "flex" },
      }}
    >
      {/* ------------------------------- PromoCode bar ------------------------ */}
      <Paper
        component='form'
        sx={{
          display: "flex",
          alignItems: "center",
          width: isLargeScreen ? 500 : "100%",
          boxShadow: 0,
          border: 1,
          borderColor: warmGray,
        }}
      >
        {/* ----------------------------- Input ---------------------------------- */}
        <InputBase
          sx={{ ml: 1, flex: 1, py: isLargeScreen ? 0 : 1 }}
          placeholder='Enter Course’s Promo code'
          inputProps={{ "aria-label": "" }}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <IconButton
          type='button'
          sx={{
            p: "5px 15px",
            m: "0",
            borderRadius: "0px",
            color: "white",
            bgcolor: "primary.main",
          }}
          variant='contained'
          aria-label='search'
          onClick={() =>
            applyCoupon(cart?.product?.product_id, cart?.cart_item_id)
          }
        >
          Apply
        </IconButton>
      </Paper>
    </Stack>
  );
}
