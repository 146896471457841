import React from 'react'
import PrivacyAndTermsSkleton from '../../components/Atoms/SharedComponents/PrivacyAndTermsSkleton'

export default function PrivacyPolicy() {
        return <>
                <PrivacyAndTermsSkleton
                        pageTitle={'Privacy policy'}
                        content={

                                `
                
                The fear of driving is often complicated, if not caused by, the individuals’ automatic negative thoughts. These thoughts can be scary and irrational, such as the concern that they will veer into oncoming traffic or drive off a bridge, or they may be centered on the person’s physical feelings of anxiety such as a rapid heartbeat or dizziness. These thoughts are often described as the most bothersome symptom of driving anxiety and they can be the actual triggers for panic attacks while driving. Controlling these thoughts is critical to success in eliminating a driving phobia.

        Thought Stopping
        
        It is sometimes advised that the individual who has a fear of driving diligently try to stop their negative thoughts. Although this is well intentioned and the goal certainly is to reduce the quantity of these bothersome thoughts, the technique is inherently flawed. Requiring the individual to remember what not to think of infers that they have already thought it. It is akin to telling them to not think of a blue banana. The first thing they will think of is a blue banana because the very act of remembering what not to think of requires the thought that is intended to be avoided. Methods of mentally visualizing a stop sign or snapping oneself with a rubber band to train the mind to no longer have the thoughts is unfortunately an often suggested technique that is not recommended. 
        
        Scheduled Worry Time
        
        Worry time is setting aside specific periods of time through the day, typically morning and evening, to devote to letting these thoughts run their course. For instance, a common thought associated with the fear of driving is that of being trapped and not being able to escape and losing control. For this thought, the individual would force themselves to ruminate on the thought twice a day for a predefined period of time. The intention is twofold. First, the thought becomes less powerful as the person becomes disinterested in it after repeatedly playing out the scenario mentally. Secondly, the technique teaches the person to be able to postpone their worrying until the designated time, which eventually may allow them to postpone the concern indefinitely. 
        
                The fear of driving is often complicated, if not caused by, the individuals’ automatic negative thoughts. These thoughts can be scary and irrational, such as the concern that they will veer into oncoming traffic or drive off a bridge, or they may be centered on the person’s physical feelings of anxiety such as a rapid heartbeat or dizziness. These thoughts are often described as the most bothersome symptom of driving anxiety and they can be the actual triggers for panic attacks while driving. Controlling these thoughts is critical to success in eliminating a driving phobia.

        Thought Stopping
        
        It is sometimes advised that the individual who has a fear of driving diligently try to stop their negative thoughts. Although this is well intentioned and the goal certainly is to reduce the quantity of these bothersome thoughts, the technique is inherently flawed. Requiring the individual to remember what not to think of infers that they have already thought it. It is akin to telling them to not think of a blue banana. The first thing they will think of is a blue banana because the very act of remembering what not to think of requires the thought that is intended to be avoided. Methods of mentally visualizing a stop sign or snapping oneself with a rubber band to train the mind to no longer have the thoughts is unfortunately an often suggested technique that is not recommended. 
        
        Scheduled Worry Time
        
        Worry time is setting aside specific periods of time through the day, typically morning and evening, to devote to letting these thoughts run their course. For instance, a common thought associated with the fear of driving is that of being trapped and not being able to escape and losing control. For this thought, the individual would force themselves to ruminate on the thought twice a day for a predefined period of time. The intention is twofold. First, the thought becomes less powerful as the person becomes disinterested in it after repeatedly playing out the scenario mentally. Secondly, the technique teaches the person to be able to postpone their worrying until the designated time, which eventually may allow them to postpone the concern indefinitely. 
        
                The fear of driving is often complicated, if not caused by, the individuals’ automatic negative thoughts. These thoughts can be scary and irrational, such as the concern that they will veer into oncoming traffic or drive off a bridge, or they may be centered on the person’s physical feelings of anxiety such as a rapid heartbeat or dizziness. These thoughts are often described as the most bothersome symptom of driving anxiety and they can be the actual triggers for panic attacks while driving. Controlling these thoughts is critical to success in eliminating a driving phobia.

        Thought Stopping
        
        It is sometimes advised that the individual who has a fear of driving diligently try to stop their negative thoughts. Although this is well intentioned and the goal certainly is to reduce the quantity of these bothersome thoughts, the technique is inherently flawed. Requiring the individual to remember what not to think of infers that they have already thought it. It is akin to telling them to not think of a blue banana. The first thing they will think of is a blue banana because the very act of remembering what not to think of requires the thought that is intended to be avoided. Methods of mentally visualizing a stop sign or snapping oneself with a rubber band to train the mind to no longer have the thoughts is unfortunately an often suggested technique that is not recommended. 
        
        Scheduled Worry Time
        
        Worry time is setting aside specific periods of time through the day, typically morning and evening, to devote to letting these thoughts run their course. For instance, a common thought associated with the fear of driving is that of being trapped and not being able to escape and losing control. For this thought, the individual would force themselves to ruminate on the thought twice a day for a predefined period of time. The intention is twofold. First, the thought becomes less powerful as the person becomes disinterested in it after repeatedly playing out the scenario mentally. Secondly, the technique teaches the person to be able to postpone their worrying until the designated time, which eventually may allow them to postpone the concern indefinitely. 
        
                The fear of driving is often complicated, if not caused by, the individuals’ automatic negative thoughts. These thoughts can be scary and irrational, such as the concern that they will veer into oncoming traffic or drive off a bridge, or they may be centered on the person’s physical feelings of anxiety such as a rapid heartbeat or dizziness. These thoughts are often described as the most bothersome symptom of driving anxiety and they can be the actual triggers for panic attacks while driving. Controlling these thoughts is critical to success in eliminating a driving phobia.

        Thought Stopping
        
        It is sometimes advised that the individual who has a fear of driving diligently try to stop their negative thoughts. Although this is well intentioned and the goal certainly is to reduce the quantity of these bothersome thoughts, the technique is inherently flawed. Requiring the individual to remember what not to think of infers that they have already thought it. It is akin to telling them to not think of a blue banana. The first thing they will think of is a blue banana because the very act of remembering what not to think of requires the thought that is intended to be avoided. Methods of mentally visualizing a stop sign or snapping oneself with a rubber band to train the mind to no longer have the thoughts is unfortunately an often suggested technique that is not recommended. 
        
        Scheduled Worry Time
        
        Worry time is setting aside specific periods of time through the day, typically morning and evening, to devote to letting these thoughts run their course. For instance, a common thought associated with the fear of driving is that of being trapped and not being able to escape and losing control. For this thought, the individual would force themselves to ruminate on the thought twice a day for a predefined period of time. The intention is twofold. First, the thought becomes less powerful as the person becomes disinterested in it after repeatedly playing out the scenario mentally. Secondly, the technique teaches the person to be able to postpone their worrying until the designated time, which eventually may allow them to postpone the concern indefinitely. 
        
                The fear of driving is often complicated, if not caused by, the individuals’ automatic negative thoughts. These thoughts can be scary and irrational, such as the concern that they will veer into oncoming traffic or drive off a bridge, or they may be centered on the person’s physical feelings of anxiety such as a rapid heartbeat or dizziness. These thoughts are often described as the most bothersome symptom of driving anxiety and they can be the actual triggers for panic attacks while driving. Controlling these thoughts is critical to success in eliminating a driving phobia.

        Thought Stopping
        
        It is sometimes advised that the individual who has a fear of driving diligently try to stop their negative thoughts. Although this is well intentioned and the goal certainly is to reduce the quantity of these bothersome thoughts, the technique is inherently flawed. Requiring the individual to remember what not to think of infers that they have already thought it. It is akin to telling them to not think of a blue banana. The first thing they will think of is a blue banana because the very act of remembering what not to think of requires the thought that is intended to be avoided. Methods of mentally visualizing a stop sign or snapping oneself with a rubber band to train the mind to no longer have the thoughts is unfortunately an often suggested technique that is not recommended. 
        
        Scheduled Worry Time
        
        Worry time is setting aside specific periods of time through the day, typically morning and evening, to devote to letting these thoughts run their course. For instance, a common thought associated with the fear of driving is that of being trapped and not being able to escape and losing control. For this thought, the individual would force themselves to ruminate on the thought twice a day for a predefined period of time. The intention is twofold. First, the thought becomes less powerful as the person becomes disinterested in it after repeatedly playing out the scenario mentally. Secondly, the technique teaches the person to be able to postpone their worrying until the designated time, which eventually may allow them to postpone the concern indefinitely. 
        
                The fear of driving is often complicated, if not caused by, the individuals’ automatic negative thoughts. These thoughts can be scary and irrational, such as the concern that they will veer into oncoming traffic or drive off a bridge, or they may be centered on the person’s physical feelings of anxiety such as a rapid heartbeat or dizziness. These thoughts are often described as the most bothersome symptom of driving anxiety and they can be the actual triggers for panic attacks while driving. Controlling these thoughts is critical to success in eliminating a driving phobia.

        Thought Stopping
        
        It is sometimes advised that the individual who has a fear of driving diligently try to stop their negative thoughts. Although this is well intentioned and the goal certainly is to reduce the quantity of these bothersome thoughts, the technique is inherently flawed. Requiring the individual to remember what not to think of infers that they have already thought it. It is akin to telling them to not think of a blue banana. The first thing they will think of is a blue banana because the very act of remembering what not to think of requires the thought that is intended to be avoided. Methods of mentally visualizing a stop sign or snapping oneself with a rubber band to train the mind to no longer have the thoughts is unfortunately an often suggested technique that is not recommended. 
        
        Scheduled Worry Time
        
        Worry time is setting aside specific periods of time through the day, typically morning and evening, to devote to letting these thoughts run their course. For instance, a common thought associated with the fear of driving is that of being trapped and not being able to escape and losing control. For this thought, the individual would force themselves to ruminate on the thought twice a day for a predefined period of time. The intention is twofold. First, the thought becomes less powerful as the person becomes disinterested in it after repeatedly playing out the scenario mentally. Secondly, the technique teaches the person to be able to postpone their worrying until the designated time, which eventually may allow them to postpone the concern indefinitely. 
        
                The fear of driving is often complicated, if not caused by, the individuals’ automatic negative thoughts. These thoughts can be scary and irrational, such as the concern that they will veer into oncoming traffic or drive off a bridge, or they may be centered on the person’s physical feelings of anxiety such as a rapid heartbeat or dizziness. These thoughts are often described as the most bothersome symptom of driving anxiety and they can be the actual triggers for panic attacks while driving. Controlling these thoughts is critical to success in eliminating a driving phobia.

        Thought Stopping
        
        It is sometimes advised that the individual who has a fear of driving diligently try to stop their negative thoughts. Although this is well intentioned and the goal certainly is to reduce the quantity of these bothersome thoughts, the technique is inherently flawed. Requiring the individual to remember what not to think of infers that they have already thought it. It is akin to telling them to not think of a blue banana. The first thing they will think of is a blue banana because the very act of remembering what not to think of requires the thought that is intended to be avoided. Methods of mentally visualizing a stop sign or snapping oneself with a rubber band to train the mind to no longer have the thoughts is unfortunately an often suggested technique that is not recommended. 
        
        Scheduled Worry Time
        
        Worry time is setting aside specific periods of time through the day, typically morning and evening, to devote to letting these thoughts run their course. For instance, a common thought associated with the fear of driving is that of being trapped and not being able to escape and losing control. For this thought, the individual would force themselves to ruminate on the thought twice a day for a predefined period of time. The intention is twofold. First, the thought becomes less powerful as the person becomes disinterested in it after repeatedly playing out the scenario mentally. Secondly, the technique teaches the person to be able to postpone their worrying until the designated time, which eventually may allow them to postpone the concern indefinitely. 
        
        
        `}
                />
        </>
}
