import React, { useState, useEffect } from "react";
import arrowDropleft from "../../../../Assets/Images/Iconionic-ios-arrow-dropleft.svg";
import arrowDropRight from "../../../../Assets/Images/Iconionic-ios-arrow-dropright.svg";
import { Button, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { completeSessionRequest } from "../../../../services/Modules/Courses";
import LoadingButton from "@mui/lab/LoadingButton";

const Controles = ({ setPageNumber, numPages, isLoading, session }) => {
  const dispatch = useDispatch();
  const { course_id, section_id } = useParams();
  const [data, setData] = useState(session);
  useEffect(() => {
    setData(session);
  }, [session]);

  const handelCompleteCourse = () => {
    const action = () => {
      let newSessionState = data;
      newSessionState.progress = {
        translation: "completed",
        value: "completed",
      };
      setData(newSessionState);
    };
    dispatch(
      completeSessionRequest({
        course_id,
        section_id,
        course_session_id: data?.course_session_id,
        action,
      })
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      my="40px"
    >
      <Button
        onClick={() => setPageNumber((s) => (s > 1 ? s - 1 : (s = numPages)))}
      >
        <img src={arrowDropleft} alt="arrow" width="12x" height="17px" />
        <Typography fontWeight="600" ml="16px">
          Previous
        </Typography>
      </Button>
      {data?.progress?.value == "completed" ? (
        <Stack direction="row" columnGap="8px">
          <CheckIcon sx={{ color: "primary.main" }} />
          <Typography sx={{ color: "primary.main", fontSize: "15px" }}>
            Session Completed
          </Typography>
        </Stack>
      ) : (
        <LoadingButton
          loading={isLoading?.type == "completeSessions" ? true : false}
          variant="contained"
          sx={{
            boxShadow: "0",
            "&:hover": {
              boxShadow: "0",
            },
          }}
          onClick={handelCompleteCourse}
        >
          Complete
        </LoadingButton>
        // <Button
        //   variant="contained"
        //   sx={{
        //     boxShadow: "0",
        //     "&:hover": {
        //       boxShadow: "0",
        //     },
        //   }}
        //   onClick={handelCompleteCourse}
        // >
        //   Complete
        // </Button>
      )}
      <Button
        onClick={() => setPageNumber((s) => (s < numPages ? s + 1 : (s = 1)))}
      >
        <Typography fontWeight="600" mr="16px">
          Next
        </Typography>
        <img src={arrowDropRight} alt="arrow" width="12x" height="17px" />
      </Button>
    </Stack>
  );
};

export default Controles;
