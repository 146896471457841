import React from "react";
import CoursesList from "../../../components/Organisms/Courses/CoursesList/CoursesList";

export default function CoursesListTemplate({
  allSubjectData,
  PageChanging,
  dataPerPage,
  allCoursesList,
  isLoading,
}) {

  return <>
    <CoursesList 
      allSubjectData={allSubjectData}
      PageChanging={PageChanging}
      dataPerPage={dataPerPage}
      allCoursesList={allCoursesList}
      isLoading={isLoading}
    />
  </>
}
