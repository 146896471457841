import SignUpTemplate from "Template/LandingPageTemplate/SignUp/SignUpTemplate";
import React from "react";

export default function SignUpPage() {
  
  
    return (
    <div>
        <SignUpTemplate/>
    </div>
  );
};


