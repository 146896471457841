import Types from "./Types";

export const signupSuccess = (payload) => ({
  type: Types.SIGN_UP_SUCCESS,
  payload,
});

export const authLoading = (payload) => ({
  type: Types.AUTH_LOADING,
  payload,
});

export const loginSuccess = (payload) => ({
  type: Types.LOGIN_SUCCESS,
  payload,
});

export const sendResetPasswordSuccess = (payload) => ({
  type: Types.SEND_RESET_PASSWORD_SUCCESS,
  payload,
});

export const checkEmailExistSuccess = (payload) => ({
  type: Types.CHECK_EMAIL_EXISTS_SUCCESS,
  payload,
});

export const sendEmailOtpSuccess = (payload) => ({
  type: Types.CHECK_EMAIL_EXISTS_LOADING,
  payload,
});

export const verifyEmailOtpSuccess = (payload) => ({
  type: Types.VERIFY_EMAIL_OTP_SUCCESS,
  payload,
});

export const resetPasswordByEmailSuccess = (payload) => ({
  type: Types.RESET_PASSWORD_BY_EMAIL_SUCCESS,
  payload,
});

export const resendConfirmationCodeSuccess = (payload) => ({
  type: Types.RESEND_CONFIEMATION_CODE_SUCCESS,
  payload,
});

export const logOutSuccess = (payload) => ({
  type: Types.LOG_OUT_SUCCESS,
  payload,
});

export const changePasswordSuccess = (payload) => ({
  type: Types.CHANGE_PASSWORD_SUCCESS,
  payload,
});
