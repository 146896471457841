import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import cancelIcon from '../../../../Assets/Images/cancelIcon.svg'
import ImageIcon from '@mui/icons-material/Image';
import { useThemePalette } from '../../../../common/hooks/useThemePalatte';

export default function File({ icon, name, content, extention, files, addCancelIcon, handleDeleteFile }) {
    const { warmGray4, lightGreen } = useThemePalette()

    const handleDeleteVoice = (i) => {
        handleDeleteFile(i)
    }
    return <>
        {name?.map((name, i) => name !== undefined &&
            <Stack
                sx={{ cursor: 'pointer' }}
                position='relative'
                maxWidth='fit-content'
                direction='row'
                gap='16px'
                alignItems='center'
                justifyContent='space-between'
                border={`1px solid ${warmGray4}`}
                borderRadius='12px'
                mt='10px'
                p='10px'
                key={i}
            >
                <Box>
                    <img src={icon} alt='pdf icon' />
                </Box>
                <Stack direction='row' alignItems='center' color={lightGreen} >
                    <Typography fontWeight='600' lineHeight='20px' fontSize='18px' >
                        {files?.filename ? files?.filename : name}
                    </Typography>
                    {files?.attachment_type === "photo" ? <ImageIcon /> : <img src={content} alt='' />}
                    {/* <Typography fontWeight='600' lineHeight='20px' fontSize='18px'>
                    {extention}
                </Typography> */}
                </Stack>
                {addCancelIcon && <Box position="absolute" top="-10px" right="-10px">
                    <img src={cancelIcon} onClick={_ => handleDeleteVoice(i)} alt="cancel icon" />
                </Box>}
            </Stack>
        ).slice(0, 3)}
    </>
}
