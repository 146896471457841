

import React from "react";
import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { useThemePalette } from "common/hooks/useThemePalatte";
import PromoCodeInput from "./PromoCodeInput";

function PaymentSection({ cartItems }) {
  const [paymentMethod, setPaymentMethod] = React.useState("cash");
  const [redeemPoints, setRedeemPoints] = React.useState(false);
  const { lightGreen, warmGray2, primaryColor } = useThemePalette();

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleRedeemPointsChange = (event) => {
    setRedeemPoints(event.target.checked);
  };

  return (
    <>
    
      <Container>
        <Typography variant='h5' gutterBottom>
          Payment Methods
        </Typography>
        <RadioGroup
          aria-label='payment-method'
          name='payment-method'
          value={paymentMethod}
          onChange={handlePaymentChange}
        >
          <FormControlLabel
            value='cash'
            control={<Radio style={{ color: primaryColor }} />}
            label='Cash'
          />
          <FormControlLabel
            value='card'
            control={<Radio style={{ color: primaryColor }} />}
            label='Card'
          />
          <FormControlLabel
            value='wallet'
            control={<Radio style={{ color: primaryColor }} />}
            label='Wallet'
          />
        </RadioGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={redeemPoints}
              onChange={handleRedeemPointsChange}
              name='redeem-points'
              style={{ color: primaryColor }}
            />
          }
          label='Redeem Points'
        />
        <Typography sx={{ marginLeft: "30px", color: warmGray2 }}>
          You have 2000 points ($1)
        </Typography>

        <Divider
          variant='fullWidth'
          sx={{
            borderBottom: `1px solid ${lightGreen}`,
            mt: 2,
            mb: 2,
          }}
        />
      </Container>
    </>
  );
}

export default PaymentSection;
