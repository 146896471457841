import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
import { Box, Stack } from "@mui/material";
import setAuthToken from "./services//Modules/utils/handel_api";
import { theme } from "./common/style/theme";
import AppRoute from "./common/Route";
import LandingPage from "./common/LandingPage";
import { useEffect, useState } from "react";
import AlertProvider from "./components/Atoms/Alerts/ToastifyAlert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocation } from "react-router-dom";
import axios from "axios";

function App() {
  const token = localStorage.getItem('userToken')
  const { pathname } = useLocation()
  console.log(pathname?.includes('website'), 'pathname');
  setAuthToken(token)
  const isWebsite = true
  useEffect(() => {
    axios.defaults.headers.common["platform"] = `web`;
    setAuthToken(token)
  }, [])
  return (
    <>
      <Stack width="100%" alignItems="center">
        <Box width="100%">
          <ThemeProvider theme={theme}>
            <AlertProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* {isWebsite ? <AppRoute /> : <LandingPage />} */}
                <AppRoute />
                {/* <LandingPage /> */}
              </LocalizationProvider>
            </AlertProvider>
          </ThemeProvider>
        </Box>
      </Stack>
    </>
  );
}
export default App;
