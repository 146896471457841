import {
  getListTransactionsSuccess,
  getListTransactionswithFilterSuccess,
  rechargeSuccess,
  walletLoading,
} from "./Actions";
import { reduxRequest } from "../../../common/utils/reduxRequest";


export const getListTransactionsRequest = ({ page_number, page_size, ...variables }) => {
  const requestData = {
    isLoading: walletLoading,
    successFunction: getListTransactionsSuccess,
    url: `wallet_transactions?page_number=${page_number}&page_size=${page_size}`,
    loadingType: "getListTransactions",
    method: 'get',
    ...variables
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getListTransactionsWithFilterRequest = ({ page_number, page_size, from, to, type, ...variables }) => {
  const requestData = {
    isLoading: walletLoading,
    successFunction: getListTransactionswithFilterSuccess,
    url: `wallet_transactions?page_number=1&page_size=3&from=${from}&to=${to}&type=${type}`,
    loadingType: "getListTransactionsWithFilter",
    method: 'get',
    ...variables
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const rechargeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: walletLoading,
    successFunction: rechargeSuccess,
    url: `/wallet_transactions/recharge`,
    loadingType: "recharge",
    ...variables
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

