import React from "react";
import { useThemePalette } from "common/hooks/useThemePalatte";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import easyreg from "../../../../Assets/Images/Group 1.svg"
import schedule from "../../../../Assets/Images/group 2.svg"
import learnfrom from "../../../../Assets/Images/group 3.svg"
import { Box, Button, Container,Stack, Typography } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";

export default function WhyChoose() {
    const { warmGray } = useThemePalette();

    const reasons = [
        { id: 0, img: easyreg, title: 'Easy Registration' , subtitle: 'Create your Account Now with \nSeveral Steps Only'},
        { id: 1, img: schedule, title: 'Flexible Schedule' , subtitle:'You Can Learn Any Time And \nAny Where With out Worries' },
        { id: 2, img: learnfrom, title: 'Learn From Expert' , subtitle:'We Only Choose Experienced \nInstructors For Courses'},
    ]
  return (
      <>
      <CustomContainer>
    <Stack 
        alignItems="center"
        sx={{ backgroundColor: "white" }}
        py={7}>
      {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
      <FrequentSkelton
        title="Why Choose Our Online Plateform"
      ></FrequentSkelton>
      {/* ------------------------------ Display Static Img and Title ----------------------- */}
       <Stack
                sx={{ flexDirection: { xs: 'column' , sm:'row' } }}
                justifyContent='space-between'
                width='100%'
            >
                {/* --------------------------- Maping into reasons Array to display img and description */}
                {reasons.map(reason =>
                    <Stack
                        direction='column'
                        justifyContent='space-between'
                        alignItems='center'
                        key={reason.id}
                    >
                        <Box
                            width='137px'
                            height='137px'
                        >
                            <img
                                src={reason.img}
                                alt={reason.title}
                                className='w-100'
                            />
                        </Box>
                        <Box sx={{  textAlign: 'center' }}>
                            <Typography
                             sx={{
                                fontSize: '24px',
                                fontWeight: 600,
                                letterSpacing: '0em',
                                textAlign: 'center',
                                marginTop:'20px'
                            }}>
                                {reason.title}
                            </Typography>
                            <Typography 
                             sx={{
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '25px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                marginTop:'20px',
                                whiteSpace: 'pre-line',
                            }}
                            color={warmGray}
                            >
                                {reason.subtitle}
                            </Typography>

                        </Box>
                    </Stack>
                )}
            </Stack>
    </Stack>
    </CustomContainer> 
    </>
  );
}
