import React from "react";
import AboutUsTemplate from "Template/LandingPageTemplate/About/AboutUsTemplate";
import eventone from '../../../Assets/Images/event1.png';
import eventtwo from '../../../Assets/Images/event2.png';
import eventthree from '../../../Assets/Images/event3.png';

export default function AboutUsPage() {
    
    const eventsData = [
        {
          img: eventone,
          title: "ALAM RAKAMY",
          date: "15/01/2020",
          description:
            "Our CEO, Eng. Ahmed Badie, had a great time at #LEAP23 event in Riyadh, Saudi Arabia, one of the largest technical conferences about future #technologies in the Middle East.",
        },
        {
          img: eventtwo,
          title: "ALAM RAKAMY",
          date: "15/01/2020",
          description: `Our CEO, Eng. Ahmed Badie, had a great time at #LEAP23 event in Riyadh, Saudi Arabia, one of the largest technical conferences about future #technologies in the Middle East,`,
        },
        {
          img: eventthree,
          title: "LEAP 2023 ",
          date: "10/03/2023",
          description: `Our CEO, Eng. Ahmed Badie, had a great time at #LEAP23 event in Riyadh, Saudi Arabia, one of the largest technical conferences about future #technologies in the Middle East. `,
        },
      ];
  
    return (
    <div>
        <AboutUsTemplate
            eventsData={eventsData}/>
    </div>
  );
};


