import React from "react";
import Ebook from "../../../components/Organisms/Courses/EbookContent/EbookContent";

export default function EbookTemplate({
  scale,
  setScale,
  pageNumber,
  setPageNumber,
  setNumPages,
  setBookmarkedPages,
  bookmarkedPages,
  session,
  isOnePage,
  breadcrumb,
  section,
  setOnePage,
  numPages,
  removeBookmark,
  createBookmark,
  isPageBookmarked,
  isLoading,
}) {
  return (
    <Ebook
      scale={scale}
      setScale={setScale}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      numPages={numPages}
      setNumPages={setNumPages}
      setBookmarkedPages={setBookmarkedPages}
      bookmarkedPages={bookmarkedPages}
      session={session}
      isOnePage={isOnePage}
      breadcrumb={breadcrumb}
      section={section}
      setOnePage={setOnePage}
      isPageBookmarked={isPageBookmarked}
      removeBookmark={removeBookmark}
      createBookmark={createBookmark}
      isLoading={isLoading}
    />
  );
}
