import { Paper } from '@mui/material'
import React from 'react'

export default function PaperComponent({ px, py, children }) {
    return <>
        <Paper sx={{ boxShadow: 'none', borderRadius: '20px', py: py, px: px }} >
            {children}
        </Paper>
    </>
}
