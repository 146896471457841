import React from 'react'
import ModelBox from '../../Atoms/Model/ModalBox'
import { useDispatch, useSelector } from 'react-redux'
import { createNewPasswodModal, verificationModel } from '../../../services/Modules/Models/Actions'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useThemePalette } from '../../../common/hooks/useThemePalatte'
import VerificationInput from 'react-verification-input'
import { useTimer } from '../../../common/hooks/useTimer'
import { useFormik } from 'formik'
import { resendConfirmationCodeRequest, veriifyEmailOtpRequest } from '../../../services/Modules/Auth'

export default function VerificationForm() {
    const { isOpenVerification } = useSelector(state => state?.model)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const phone = searchParams.get('phoneNumber')
    const email = searchParams.get('email')
    const country_code = searchParams.get('country_code')
    const signUp = searchParams.get('signUp')
    const { blackFour } = useThemePalette()
    const date = new Date()
    const { minutes, seconds } = useTimer(new Date(date?.getTime() + 60000));


    useEffect(() => {
        if (isOpenVerification != true) {
            searchParams.delete('phoneNumber')
            setSearchParams(searchParams)
            searchParams.delete('email')
            setSearchParams(searchParams)
        }
    }, [isOpenVerification])

    const initialValues = {
        verification_code: '',
    }

    const onSubmit = (values) => {
        const obj = {
            "user": {
                verification_code: values?.verification_code,
                email
            }
        }
        // dispatch(createNewPasswodModal())
        // dispatch(verificationModel())

        if (signUp) {
            dispatch(verificationModel()) // lw el user by3ml signup w el request tmam byfl el model da w yms7 el params
            searchParams.delete('email')
            setSearchParams(searchParams)
            searchParams.delete('signUp')
            setSearchParams(searchParams)
        }

        const action = () => {
            dispatch(createNewPasswodModal())
            dispatch(verificationModel())
            searchParams.delete('email')
            setSearchParams(searchParams)
        }
        dispatch(veriifyEmailOtpRequest({ body: obj, action }))
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        // validationSchema: loginSchema
    })

    return <>
        <ModelBox
            openVar={isOpenVerification}
            closeFunc={() => {
                dispatch(verificationModel())
                searchParams.delete('phoneNumber')
                setSearchParams(searchParams)
                searchParams.delete('email')
                setSearchParams(searchParams)
                searchParams.delete('signUp')
                setSearchParams(searchParams)
                searchParams.delete('country_code')
                setSearchParams(searchParams)
            }}
            title="Verification code"
            titleStyle={{ fontSize: "32px", fontWeight: "700", mb: '32px' }}
            px='30px'
        >
            <Stack justifyContent='center' alignItems='center' sx={{ marginBottom: '80px' }} >
                <Typography fontSize='14px'>Enter the verification code we just sent you on your mobile number</Typography>
                <Typography>
                    {phone && phone?.replace(phone?.substring(0, phone?.length - 3), '*'.repeat(phone?.length - 3))}
                    {email && email?.replace(email?.substring(0, email?.length - 13), '*'.repeat(email?.length - 13))}
                </Typography>
            </Stack>
            <form onSubmit={formik?.handleSubmit} className='verification' >
                <Stack justifyContent='center' alignItems='center' >
                    <VerificationInput
                        value={formik?.values?.verification_code}
                        validChars='0-9'
                        length={5}
                        placeholder=" "
                        classNames={{
                            container: "verification-container",
                            character: "character",
                            characterInactive: "character--inactive",
                            characterSelected: "character--selected",
                        }}
                        onChange={(value) => formik.setValues({ verification_code: value })}
                    />
                    <Button type='submit' variant='contained' fullWidth sx={{ mt: '40px', mb: '32px' }} >Submit</Button>
                    {phone && <Typography fontWeight='600' color={blackFour} >{minutes} : {seconds}</Typography>}
                    {email && <Typography fontWeight='600' color={blackFour} >{minutes} : {seconds}</Typography>}
                    <Typography mt='24px' color={blackFour} fontSize='14px' >It may take a minute to receive the code, Haven’t received it?</Typography>
                    <Button onClick={_ => dispatch(resendConfirmationCodeRequest({ body: { "user": { "country_code": `+${country_code}`, "phone_number": phone && phone } } }))} disabled={minutes + seconds < 1 ? false : true} sx={{ color: blackFour, mt: '16px', fontWeight: 700, fontSize: '14px' }} >Re-send code</Button>
                    {console.log(minutes + seconds < 1)}
                </Stack>
            </form>
        </ModelBox>
    </>
}