import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import AccordianList from "./AccordianList";

export default function ChapterLeftSide({
  studentCoursesDetails,
  sessionChossen,
  subjectId,
  subjectName,
}) {
  return (
    <Stack bgcolor="#F8F8F8">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "rgba(155, 155, 155 ,.55)",
        }}
      >
        <Typography
          sx={{
            px: 2,
            py: 3,
            fontSize: "1.2rem",
            width: { lg: "70%", xs: "100%" },
            fontWeight: 600,
            lineHeight: " 22px",
          }}
        >
          {studentCoursesDetails?.title}
        </Typography>
      </Box>
      {studentCoursesDetails?.sections?.map((section, sectionIndex) => {
        return (
          <Stack key={section.section_id}>
            <AccordianList
              section={section}
              session={sessionChossen}
              sectionIndex={sectionIndex}
              subjectId={subjectId}
              subjectName={subjectName}
              id={
                sessionChossen?.assignment_id
                  ? sessionChossen?.assignment_id
                  : sessionChossen?.quiz_id
                  ? sessionChossen?.quiz_id
                  : sessionChossen?.course_session_id
              }
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
