import React from 'react';
import { Typography, Paper, InputBase, Button, Grid, Stack, Box } from '@mui/material';
import getintouch from '../../../../Assets/Images/Intouch.png';
import CustomContainer from 'components/Atoms/SharedComponents/LandingPage/CustomContainer';

const GetInTouch = () => {
  return (
 
    <CustomContainer>
<Stack
      alignItems="center"
      justifyContent="space-between"
      py={10}
      mb={10}
      borderRadius='20px'
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: { sm: `url(${getintouch})`, xs: `url(${getintouch})` },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >        
        <Typography variant="h1" color='white' sx={{ marginBottom: { xs: '20px', md: '70px' }, fontSize: { xs: '2rem', md: '3rem' } }}>Get In Touch</Typography>
          <Grid container justifyContent="center" alignItems="center" >
            <Grid item xs={8}>
            <Paper
                component="form"
                justifyContent="center"
                alignItems="center"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: '50px',
                    width: '100%', 
                    boxShadow: 0,
                    border: 1,
                }}
            >
                {/* ----------------------------- Input ---------------------------------- */}
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Enter Your Email Address"
                    inputProps={{ "aria-label": "Enter Your Email Address" }}
                />
                {/* ----------------------------- search Button ---------------------------- */}
                <Button
                 variant="outlined" 
                 color="primary" 
                 sx={{ width: 'fit-content', height: '60px', padding: '12px 27px', borderRadius: '50px', border: '3px solid' }}
                 >
                    Subscribe
                </Button>
            </Paper>
            </Grid>
          </Grid>
      </Stack>
    </CustomContainer>
  );
};

export default GetInTouch;
