import Types from './Types';


export const signupModel = () => ({
    type: Types.SIGN_UP_MODEL
});

export const verificationModel = () => ({
    type: Types.VERIFICATIOM_MODEL
});

export const loginModel = () => ({
    type: Types.LOG_IN_MODEL
})

export const forgetPasswodModal = () => ({
    type: Types.FORGET_PASSWORD_MODEL
})

export const createNewPasswodModal = () => ({
    type: Types.CREATE_NEW_PASSWORD_MODEL
})

export const changePasswodModal = () => ({
    type: Types.CHANGE_PASSWORD_MODEL
})

export const deleteAccountModal = () => ({
    type: Types.DELETE_ACCOUNT_MODEL
})

export const studentReviewModel = () => ({
    type: Types.STUDENT_REVIEW_MODEL
})

export const walletFilterByModel = () => ({
    type: Types.WALLET_FILTER_BY_MODEL
})

export const chargeWalletModel = () => ({
    type: Types.CHARGE_WALLET_MODEL
})

export const WalletChargedSuccessfullyModel = () => ({
    type: Types.WALLET_CHARGED_SUCCESSFULLY_MODEL
})