import React from 'react'
import Rating from '@mui/material/Rating';
import { useState } from 'react';
import { useEffect } from 'react';


export default function CardRating({ final_rating_from_reviews, iconSize, iconEmptyColor, icon, emptyIcon, handleChangeRatingChange, ratingValue, defaultValue, readOnly }) {
    const [value, setValue] = useState();
    const handleChange = (e, newValue) => {
        setValue(newValue)
    }
    useEffect(() => {
        setValue(final_rating_from_reviews)
    }, [final_rating_from_reviews])

    const [hover, setHover] = useState(-1);


    return <>
        <Rating
            name="simple-controlled"
            value={ratingValue ? ratingValue : value}
            defaultValue={defaultValue}
            onChange={handleChangeRatingChange ? handleChangeRatingChange : handleChange}
            onChangeActive={(event, newHover) => {
                setHover(newHover);
            }}
            emptyIcon={emptyIcon && emptyIcon}
            icon={icon && icon}
            precision={0.5}
            size={iconSize}
            readOnly={readOnly}
            sx={{
                '& .MuiRating-iconEmpty': {
                    color: iconEmptyColor ? iconEmptyColor : 'secondary.main',
                },
            }}
        />
    </>
}
