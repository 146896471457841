import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material'
import { useThemePalette } from 'common/hooks/useThemePalatte';
import PhoneInput from 'components/Atoms/Input/PhoneInput/PhoneInput'
import { useFormik } from 'formik'
import React from 'react';
import facebook from '../../../../Assets/Images/facebook.svg';
import linkedin from '../../../../Assets/Images/linkedin.svg';
import twitter from '../../../../Assets/Images/twitter.svg';
import instagram from '../../../../Assets/Images/instagram.svg';
import Youtube from '../../../../Assets/Images/youtube.svg';
import en from "react-phone-number-input/locale/en.json";
import { messageUsSchema } from 'components/Organisms/Auth/validation';
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";

export default function FindUsForm() {
    const { primaryColor, blackTwo, lightGray2 } = useThemePalette();
    const initialValues = {
        name: "",
        country: "egypt",
        email: "",
        message: "",
    };

    const onSubmit = (values) => {
        console.log(values);
    };
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: messageUsSchema,
    });
    const Icons = [
        { href: "https://www.facebook.com/inovaeg", icon: facebook },
        { href: "https://twitter.com/inovaeg", icon: twitter },
        { href: "https://www.youtube.com/@Inovaeg", icon: Youtube, },
        { href: "https://www.instagram.com/inovaeg/", icon: instagram },
        { href: "https://www.linkedin.com/company/inovaeg/", icon: linkedin },
    ];

    return <>
    <Stack direction='column' 
            py={5}>
        <Typography variant='h2'
                    marginBottom='1rem'
                    color='primary.main'
                    fontSize='30px'
                    fontWeight='600'
        >
                        Find Us On Social Media
        </Typography>
             <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: { xs: "100%",sm:"24%", lg: "20%" }, pt: "32px", my: { xs: "20px", md: "0" } }}
             >
                            {Icons.map((data, i) => {
                                return (
                                    <Button key={i} href={data.href} target="_blank" sx={{ minWidth: 0, padding: 0 }}>
                                        <img src={data.icon} alt="icon" width="24px" height="24px" />
                                    </Button>
                                );
                            })}
            </Stack>

    </Stack>
        <form className="contact-form " onSubmit={formik?.handleSubmit}>
                <Stack
                    direction="column"
                    width='100%'
                    justifyContent="space-between"
                    flexWrap="wrap"
                    py={2}
                >
                    {/* -------------------------- NAME ------------------------------- */}
                    <Stack width={{ lg: '100%' }} sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                            Name
                        </Typography>
                        <TextFieldInput
                            placeholder="Enter your full name"
                            onChange={formik?.handleChange}
                            name="name"
                            value={formik?.values?.name}
                            error={formik?.errors?.name && formik?.touched?.name}
                            errorMessage={formik?.errors?.name}
                        />
                    </Stack>
                    {/* ------------------------------ Email -------------------------- */}
                    <Stack width={{ lg: "100%"  }} sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                            Email
                        </Typography>
                        <TextFieldInput
                            placeholder="Enter your email"
                            onChange={formik?.handleChange}
                            name="email"
                            value={formik?.values?.email}
                            error={formik?.errors?.email && formik?.touched?.email}
                            errorMessage={formik?.errors?.email}
                        />
                    </Stack>
                    {/* ------------------------------ Country-------------------------- */}
                    <Stack width={{ lg: "89%" }} sx={{ pb: "16px" }}>
                        <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                            Country
                        </Typography>
                            <PhoneInput
                                id={"country"}
                                FormControlWidth="100%"
                                name={"country"}
                                selectId={"search-select"}
                                inputBg={lightGray2}
                                displayCountry={true}
                                labels={en}
                                value={formik?.values?.country}
                                onChange={(value) =>
                                    formik?.setValues({
                                        ...formik?.values,
                                        country: value,
                                    })
                                }
                                error={formik?.touched?.country && Boolean(formik?.errors?.country)}
                                helperText={formik?.touched?.country && formik?.errors?.country}
                            />
                    </Stack>
                    {/* ------------------------------ Message *-------------------------- */}
                    <Stack width={"100%"} sx={{ pb: "16px" }}>
                        <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                            Message
                        </Typography>
                        <TextFieldInput
                            placeholder="Message"
                            onChange={formik?.handleChange}
                            name="message"
                            value={formik?.values?.message}
                            error={formik?.errors?.message && formik?.touched?.message}
                            errorMessage={formik?.errors?.message}
                            multiline
                            rows={7}
                        />
                    </Stack>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ px: {md:"100px", xs:'30px'}, my: "16px", py: "10px", width: "fit-content", fontSize: '20px', fontWeight: '600' }}
                    >
                        SEND
                    </Button>
                </Stack>
        </form>
    </>
}
