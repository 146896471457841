import React from 'react'
import ModelBox from '../../Atoms/Model/ModalBox'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAccountModal } from '../../../services/Modules/Models/Actions'
import { Button, Stack, Typography } from '@mui/material'

export default function DeleteAccountModel() {
    const { isDeleteAccountModel } = useSelector(state => state.model)
    const dispatch = useDispatch()
    return <>
        <ModelBox
            openVar={isDeleteAccountModel}
            closeFunc={() => dispatch(deleteAccountModal())}
        >
            <Typography fontSize='18px' fontWeight='500' >Are you sure you want to Delete your account?</Typography>
            <Stack direction='row' justifyContent='flex-end' columnGap='32px' pt='32px'>
                <Button>Delete</Button>
                <Button onClick={() => dispatch(deleteAccountModal())} >Cancel</Button>
            </Stack>
        </ModelBox>
    </>
}
