import React from "react";
import Cart from "components/Organisms/Cart/Cart";

export default function CartTemplate({ cartItems, removeFromCart,  }) {
  return (
    <>
      <Cart
        cartItems={cartItems}
        removeFromCart={removeFromCart}
       
      />
    </>
  );
}
