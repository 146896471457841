import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import CoursesDetailsBoxTitle from './CoursesDetailsBoxTitle'
import { useThemePalette } from '../../../common/hooks/useThemePalatte'
import placeHolder from '../../../Assets/Images/TestVideoImg.png'
import outlinedLabelIcon from '../../../Assets/Images/outlinedLabelIcon.svg'


export default function ProfileCoursesCard({ data, labelIcon, labelAction, children, mt, click }) {
    const { blackTwo } = useThemePalette()

    return <>
        <Stack direction='row' columnGap='24px' mb='40px' >
            <Box>
                <img alt='' style={{ borderRadius: '7px' }} src={data?.classified_product?.image_url ? data?.classified_product?.image_url : placeHolder} width='219px' height='137px' />
            </Box>
            <Stack width={'100%'} mt={mt} position='relative'  >
                {labelIcon && <Box sx={{ cursor: 'pointer' }} alignSelf='flex-end'
                    onClick={labelAction}
                >
                    <img src={outlinedLabelIcon} alt='icon' />
                </Box>}
                <Box sx={{ cursor: 'pointer' }} onClick={click}>
                    <CoursesDetailsBoxTitle title={data?.classified_product?.title} fontSize='20px' fontWeight='600' />
                </Box>
                <Typography fontSize='14px' fontWeight='600' color={blackTwo} >{data?.classified_product?.description?.slice(0, 150)}</Typography>
                <Box width='100%' sx={{ position: 'absolute', bottom: '10px' }}  >
                    {children}
                </Box>
            </Stack>
        </Stack>
    </>
}
