import { reduxRequest } from "../../../common/utils/reduxRequest";
import {
  getAllSubjecsSuccess,
  getFilteredCoursesSuccess,
  getFilteredSubjectsSuccess,
  getSubjecsSuccess,
  subjecsLoading,
} from "./Actions";

export const getSubjectRequest = ({ classes }) => {
  const requestData = {
    isLoading: subjecsLoading,
    successFunction: getSubjecsSuccess,
    url: `classes/${classes}/subjects`,
    loadingType: "subject",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const fetchAllSubjectsRequest = ({ page_number, page_size }) => {
  const requestData = {
    isLoading: subjecsLoading,
    successFunction: getAllSubjecsSuccess,
    url: `courses_filtering?page[number]=${page_number}&page[size]=${page_size}`,
    loadingType: "Allsubject",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const fetchFilteredSubjectsRequest = ({
  page_number,
  page_size,
  subject_id,
}) => {
  const requestData = {
    isLoading: subjecsLoading,
    successFunction: getFilteredSubjectsSuccess,
    url: `courses_filtering?page[number]=${page_number}&page[size]=${page_size}&filter[subject_id]=${subject_id}`,
    loadingType: "filteredsubject",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const fetchFilteredCoursesRequest = ({
  page_number,
  page_size,
  curriculum_id,
  subject_id,
  instructor_id,
}) => {
  const requestData = {
    isLoading: subjecsLoading,
    successFunction: getFilteredCoursesSuccess,
    url: `courses_filtering?page[number]=${page_number}&page[size]=${page_size}&filter[curriculum_id]=${curriculum_id}&filter[subject_id]=${subject_id}&filter[instructor_id]=${instructor_id}`,
    loadingType: "FilteredCourses",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
