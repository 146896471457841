import { Paper, Typography } from '@mui/material';
import React from 'react'
import { useThemePalette } from '../../../../common/hooks/useThemePalatte';

export default function VideoOverview({ sessionChossen }) {
    const { warmGray } = useThemePalette()
    return <>
        <Paper elevation={0} sx={{ px: '16px', py: '24px', borderRadius: '10px' }}>
            <Typography fontWeight='600' color={warmGray} >{sessionChossen?.text}</Typography>
        </Paper>
    </>
}
