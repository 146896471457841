import React from "react";
import { Box, Button, Divider, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import attachment from "../../../../Assets/Images/attachment-icon.svg";
import microphone from "../../../../Assets/Images/micfophone-icon.svg";
import VoiceNote from "./VoiceNote";
import pdf_icon from "../../../../Assets/Images/pdf_icon.svg";
import { useParams } from "react-router-dom";
import File from "../CoursesOverview/File";
import voiceNoteChekMark from "../../../../Assets/Images/voiceNoteChekMark.svg";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import { submitAssignmentRequest } from "../../../../services/Modules/Courses";
import TextArea from "../../../Atoms/Input/TextArea/TextArea";

export default function StudentSubmitAnswer({
  setToggleRecording,
  setValue,
  value,
  sessionChossen,
  mediaBlobUrl,
  clearBlobUrl,
  status,
  voices,
  submittedAssignment,
  setIsAssignmentSubmitted,
  handleUploadFile,
  fileName,
  files,
  setFiles,
  handleDeleteVoices,
  handleDeleteFile,
}) {
  const { blackTwo, warmGray4 } = useThemePalette();
  const dispatch = useDispatch();
  const { course_id } = useParams();

  const handleSubmitAssignment = () => {
    dispatch(
      submitAssignmentRequest({
        course_id,
        assignment_id: sessionChossen?.assignment_id,
        body: {
          assignment_delivery: {
            body: value,
          },
          attachments: [
            {
              attachment_url:
                "https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-medium/1f973.png",
              attachment_type: "photo",
            },
            {
              attachment_url:
                "https://inclass-app.s3.eu-central-1.amazonaws.com/staging/npgXPl-h41lLQw5yh2n3pA_1672235415_.pdf",
              attachment_type: "document",
              filename: "This document name",
            },
            {
              attachment_url: "https://server12.mp3quran.net/taher/001.mp3",
              attachment_type: "voice_memo",
              filename: "This Voice name",
            },
          ],
        },
      })
    );
    submittedAssignment && setIsAssignmentSubmitted(true);
  };

  return (
    <Box position="relative" mb="32px">
      <TextArea
        style={{
          borderRadius: "12px",
          color: blackTwo,
          fontSize: "18px",
          fontWeight: 500,
          background:'transparent',
        }}
        placeholder="Type your answer here ..."
        value={value}
        onchange={(val) => setValue(val)}
      />
      <Stack position="absolute" bottom="24px" width="100%">
        <Stack direction="row" justifyContent="space-between" px="20px">
          <Stack direction="row" alignItems="center">
            <Button
              disableRipple
              sx={{
                borderRadius: "0",
                "&:hover": {
                  bgcolor: "#28A19C10",
                },
              }}
              aria-label="upload picture"
              component="label"
              onClick={(_) => setToggleRecording(true)}
            >
              <img src={microphone} width="40px" height="40px" alt="" />
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button
              aria-label="upload picture"
              component="label"
              disableRipple
              sx={{
                borderRadius: "0",
                "&:hover": {
                  bgcolor: "#28A19C10",
                },
              }}
            >
              <input
                hidden
                accept={"application/pdf , image/*"}
                type="file"
                onChange={(e) => handleUploadFile(e)}
              />
              <img src={attachment} width="40px" height="40px" alt="" />
            </Button>
          </Stack>
          <Box alignSelf="center">
            <Button
              variant="contained"
              disabled={value ? false : true}
              sx={{
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                px: "32px",
                py: "7px",
                fontWeight: 600,
                "&:disabled": {
                  color: "rgba(255, 255, 255, 0.4)",
                  backgroundColor: "#28A19C",
                },
              }}
              onClick={(_) => handleSubmitAssignment()}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap="24px"
        flexWrap="wrap"
        position="absolute"
        bottom="90px"
        left="40px"
      >
        {status === "recording" && (
          <Stack
            //  position='relative'
            direction="row"
            columnGap="5px"
            alignItems="center"
          >
            {/* <Box position='absolute' bottom='30px' left='-20px' > */}
            {/* <div className="ring-container">
                            <div className="ringring"></div>
                            <div className="circle"></div>
                        </div> */}
            <Stack
              sx={{ cursor: "pointer" }}
              position="relative"
              maxWidth="fit-content"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              border={`1px solid ${warmGray4}`}
              borderRadius="12px"
              mt="10px"
              p="15px"
            >
              <input type="range" max="100" value="0" />
            </Stack>
            <img
              src={voiceNoteChekMark}
              onClick={(_) => setToggleRecording(false)}
              width="20px"
              height="20px"
              alt="icon"
              style={{ cursor: "pointer" }}
            />
            {/* </Box> */}
          </Stack>
        )}
        <VoiceNote
          mediaBlobUrl={mediaBlobUrl}
          voices={voices}
          addCancelIcon={true}
          clearBlobUrl={clearBlobUrl}
          handleDeleteVoices={handleDeleteVoices}
        />
        {fileName && (
          <File
            icon={pdf_icon}
            addCancelIcon={true}
            name={fileName}
            files={files}
            setFiles={setFiles}
            handleDeleteFile={handleDeleteFile}
          />
        )}
      </Stack>
    </Box>
  );
}
