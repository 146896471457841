import React from 'react';
import { Stack } from "@mui/material";
import PricingCards from 'components/Molecules/LandingPage/Pricing/PricingCards';
import Faq from 'components/Molecules/LandingPage/Pricing/Faq';
import GetInTouch from 'components/Molecules/LandingPage/Home/GetInTouch';


const PricingTemplate = ({pricingData, faqData}) => {
    return (

        <Stack gap={5}>
         <PricingCards pricingData={pricingData} />
         <Faq faqData={faqData}/>
         <GetInTouch/>
        </Stack>
      );

};

export default PricingTemplate;
