import React from 'react';
import { Stack } from "@mui/material";
import Header from "../../../components/Molecules/LandingPage/Home/Header";
import InclassNumbers from 'components/Molecules/LandingPage/Home/InclassNumbers';
import Learning from 'components/Molecules/LandingPage/Home/Learning';
import AboutUs from 'components/Molecules/LandingPage/Home/AboutUs';
import GetInTouch from 'components/Molecules/LandingPage/Home/GetInTouch';
import WhyChoose from 'components/Molecules/LandingPage/Home/WhyChoose';
import AllFearures from 'components/Molecules/LandingPage/Home/AllFeatures';
import Members from 'components/Molecules/LandingPage/Home/Members';

const LandingHomeTemplate = ({achievednumbers}) => {
    return (

        <Stack rowGap={10}>
          <Header/>
          <InclassNumbers achievednumbers={achievednumbers}/>
          <Learning/>
          <AboutUs />
          <WhyChoose />
          <AllFearures/>
          <Members/>
          <GetInTouch/>
        </Stack>
      );

};

export default LandingHomeTemplate;
