import { Box } from "@mui/material";
import React from "react";

export default function IconComponent({ iconSrc, style, ...rest }) {
  return (
    <Box
      sx={{ cursor: "pointer" }}
      color="primary.main"
      mx={{ lg: "1rem", md: "0rem" }}
      my="0.5rem"
      {...rest}
    >
      <img src={iconSrc} style={style} alt="shopping card" />
    </Box>
  );
}
