import { Stack, Typography, Box, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useThemePalette } from '../../../common/hooks/useThemePalatte';

const ModelBox = ({ title, children, closeFunc, openVar, titleStyle, px, justifyContent, padding, modelBorderRadius }) => {
    const { grayFour } = useThemePalette()

    return (
        <Dialog
            open={openVar}
            onClose={closeFunc}
            PaperProps={{
                sx: {
                    borderRadius: modelBorderRadius ? modelBorderRadius:"12px",
                    height: 'auto',
                    minWidth: { lg: '492px', md: '800px', sm: '500px' },
                    padding: '20px',
                    px: px
                }
            }}
        >
            <Stack
                onClick={closeFunc}
                position='relative'
                alignItems='flex-end'
                sx={{ cursor: 'pointer', mt: '20px' }}
            >
                <Box
                    position='absolute'
                    sx={{
                        bgcolor: grayFour,
                        padding: '4px',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '-11px'
                    }}
                >
                    <CloseIcon fontSize='14px' />
                </Box>
            </Stack>
            <Stack direction='row' alignItems='center' justifyContent={justifyContent ? justifyContent : 'center'} mb={2} mx={1}>
                <Typography
                    sx={titleStyle}
                    component="h2"
                >
                    {title}
                </Typography>
            </Stack>
            {children}
        </Dialog >
    );
}

export default ModelBox