import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useRef, useState } from "react";
import cancelIcon from "../../../../Assets/Images/cancelIcon.svg";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";

export default function VoiceNote({
  voices,
  addCancelIcon,
  handleDeleteVoices,
}) {
  const [audio, setAudio] = useState();
  const [timeline, setTimeline] = useState();

  const { warmGray4 } = useThemePalette();
  const [audioIcon, setAudioIcon] = useState({
    index: 0,
    path: "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z",
  });
  const [played, setPlayed] = useState(false);

  function toggleAudio(i) {
    setAudio(document?.querySelector(`#assignment-audio${i}`));
    const localAudio = document?.querySelector(`#assignment-audio${i}`);
    const unPlayedAudio = Array.from(
      document?.querySelectorAll(`#assignment audio`)
    );
    const filtered = unPlayedAudio?.filter(
      (unPlayed) => unPlayed?.id !== `assignment-audio${i}`
    );
    if (localAudio?.paused) {
      localAudio?.play();
      filtered?.map((audio) => audio?.pause());
      setAudioIcon({
        path: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z",
        index: i,
      });
      setPlayed({ index: i, played: true });
    } else {
      localAudio?.pause();
      setAudioIcon({
        path: "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z",
        index: i,
      });
      setPlayed({ index: i, played: false });
    }
  }

  function audioEnded(i) {
    setAudioIcon({
      path: "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z",
      index: i,
    });
    setPlayed({ index: i, played: false });
  }
  function changeTimelinePosition(i) {
    setTimeline(document.querySelector(`.timeline${i}`));
    const localTimeline = document.querySelector(`.timeline${i}`);
    const percentagePosition = (100 * audio?.currentTime) / audio?.duration;
    localTimeline.style.backgroundSize = `${percentagePosition}% 100 % `;
    localTimeline.value = percentagePosition;
  }

  function changeSeek(i) {
    setTimeline(document.querySelector(`.timeline${i}`));
    const time = (timeline?.value * audio?.duration) / 100;
    if (audio?.currentTime) audio.currentTime = time;
  }

  const handleDeleteVoice = (i) => {
    // voices.splice(i, 1)
    handleDeleteVoices(i);
    console.log(voices);
  };

  // console.log(voices);
  // const waveformRef = useRef();

  // useEffect(() => {
  //     if (waveformRef.current) {
  //         const wavesurfer = WaveSurfer.create({
  //             container: waveformRef.current,
  //             waveColor: '#09c',
  //             progressColor: 'purple',
  //             cursorColor: '#09c',
  //             barWidth: 3,
  //             barRadius: 3,
  //             cursorWidth: 1,
  //             height: '10px',
  //             width: '200px',
  //             scrollParent: true
  //         });
  //         wavesurfer?.current?.load('https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3');

  //     }
  // }, [voices, waveformRef]);
  // console.log(waveformRef?.current)
  // const [position, setPosition] = useState(0);
  // const [muted, setMuted] = useState(false);

  // const handlePositionChange = (position) => {
  //     /* ... */
  // };
  // const onReadyHandler = () => console.log("done loading!");
  // drawAudio('https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/shoptalk-clip.mp3');

  // const [wavesurfer, setWavesurfer] = useState(null)
  // const [playing, setPlaying] = useState(false)
  // const [position, setPosition] = useState(0)

  // const onPosChange = (pos, wavesurfer) => {
  //     if (pos !== position) {
  //         setPosition({ pos, wavesurfer });
  //     }
  // };

  // const onSeek = ({ pos, wavesurfer }) => {
  //     console.log(pos);
  // }

  // const skipAhead = () => {
  //     wavesurfer.seekTo(secondsToPosition(position + 10));
  // };

  // const secondsToPosition = (sec) => {
  //     return 1 / wavesurfer.getDuration() * sec;
  // };

  return (
    <>
      <Stack direction="row" columnGap="18px" id="assignment">
        {voices &&
          voices
            ?.map((voice, i) => {
              return (
                <Stack
                  sx={{ cursor: "pointer" }}
                  position="relative"
                  maxWidth="fit-content"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  border={`1px solid ${warmGray4}`}
                  borderRadius="12px"
                  mt="10px"
                  key={i}
                >
                  <div className="audio-player">
                    <div className="icon-container">
                      <audio
                        id={`assignment-audio${i}`}
                        onEnded={() => audioEnded(i)}
                        onTimeUpdate={() => changeTimelinePosition(i)}
                        src={voice}
                      ></audio>
                    </div>
                  </div>
                  <div className="controls">
                    <button
                      className="player-button"
                      onClick={(_) => toggleAudio(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#28A19C"
                      >
                        <path
                          fillRule="evenodd"
                          d={`${
                            audioIcon?.index === i
                              ? audioIcon?.path
                              : "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                          }`}
                          id={`path${i}`}
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <input
                    onChange={() => changeSeek(i)}
                    type="range"
                    className={`timeline${i}`}
                    max="100"
                    value="0"
                  />
                  {/* <div className={'container example'}>
                        <div className="play button"
                            onClick={() => { setPlaying({ playing: !playing }) }}
                            style={{ left: '-99px' }}
                        >
                            {playing ? '▶️' : '⏹'}
                        </div>
                        <div className="skip button"
                            onClick={wavesurfer && skipAhead}
                            style={
                                wavesurfer ? {} : { opacity: '.4', cursor: 'default' }
                            }
                        >
                            {'⏩'}
                        </div>
                        <ReactWaves
                            audioFile={voice}
                            className='react-waves'
                            options={{
                                barHeight: 2,
                                cursorWidth: 0,
                                height: 200,
                                hideScrollbar: true,
                                progressColor: '#EC407A',
                                responsive: true,
                                waveColor: '#D1D6DA',
                            }}
                            volume={1}
                            zoom={1}
                            playing={playing}
                            pos={position}
                            onPosChange={onPosChange}
                            onSeek={onSeek}
                        />
                    </div> */}
                  {/* <Wavesurfer
                        src={voice}
                        position={position}
                        onPositionChange={handlePositionChange}
                        onReady={onReadyHandler}
                        playing
                        muted={muted}
                    /> */}
                  {/* <Waveform /> */}
                  {/* <canvas></canvas> */}

                  <Typography pr="5px">
                    {" "}
                    {played?.index === i && audio?.duration}
                  </Typography>
                  {addCancelIcon && (
                    <Box position="absolute" top="-10px" right="-10px">
                      <img
                        src={cancelIcon}
                        onClick={(_) => handleDeleteVoice(i)}
                        alt="cancel icon"
                      />
                    </Box>
                  )}
                </Stack>
              );
            })
            .slice(0, 3)}
      </Stack>
    </>
  );
}
