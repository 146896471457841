import React from "react";
import AllFeaturesContent from "./AllFeaturesContent";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import {Stack } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";

export default function AllFearures() {
  return (
    <>
    <CustomContainer>
        <Stack sx={{ backgroundColor: "white" }}
               py={5}>
        {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
        <FrequentSkelton
            title="All Features"
        ></FrequentSkelton>
        {/* ------------------------------ Display Static Img and Title ----------------------- */}
            <AllFeaturesContent />
        </Stack>
    </CustomContainer> 
    </>
  );
}
