import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesRequest,
  getStudentSavedListRequest,
} from "../../services/Modules/Courses";
import {
  fetchAllSubjectsRequest,
  fetchFilteredSubjectsRequest,
  getSubjectRequest,
} from "../../services/Modules/Subjects";
import HomeTemplate from "../../Template/Home/Home.Template";
import { addToCartRequest } from "services/Modules/Cart";

export default function HomePage({ setBgColor }) {
  const [isActive, setIsActive] = useState(0);
  const [categoriesSubjects, setCategoriesSubjects] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [clickedSubject, setClickedSubject] = useState();
  const dispatch = useDispatch();
  // --------------------------------------------- Subiects Api Response ---------------------------------
  const { allSubjects, filterdSubject, loading, subjects } = useSelector(
    (state) => state?.subjects
  );
  const { token } = useSelector((state) => state.auth);

  // ------------------------- Fetch Subjects Name and unFiltered Subjects Details when the copmonent rendering for the first time ----------------
  useEffect(() => {
    dispatch(getSubjectRequest({ classes: 25 }));
    dispatch(fetchAllSubjectsRequest({ page_number: 1, page_size: 4 }));
  }, []);
  useEffect(() => {
    setClickedSubject({ id: 0, name: "All" })
  }, []);

  // ----------------------- Set final data by data depends user Click --------------------------------------------
  useEffect(() => {
    isActive === 0 ? setFinalData(allSubjects) : setFinalData(filterdSubject);
  }, [allSubjects, filterdSubject, isActive]);

  // ------------------------ Extraction Needed data from subkects api respone and adding All into it ---------------------------------
  useEffect(() => {
    subjects && setCategoriesSubjects([{ id: 0, name: "All" }, ...subjects]);
  }, [subjects]);

  // --------------------------------------- handle fetch request depends on user click -----------------------------------------------
  const handleClick = (data) => {
    setIsActive(data?.id);
    setClickedSubject(data);

    data?.id === 0
      ? dispatch(fetchAllSubjectsRequest({ page_number: 1, page_size: 10 }))
      : dispatch(
          fetchFilteredSubjectsRequest({
            page_number: 1,
            page_size: 10,
            subject_id: data?.id,
          })
        );
  };
  // ------------------------------------------- Most Popular request ---------------------------------------------
  const { courses, isLoading } = useSelector((state) => state.courses);
  const [coursesArr, setCoursesArr] = useState([]);
  // ------------------------- Fetching Courses when the copmonent rendering for the first time ----------------
  // useEffect(() => {
  //   dispatch(
  //     getCoursesRequest({ page_number: 1, page_size: 10, school_class_id: 25 })
  //   );
  //   setBgColor("white");
  // }, []);
  // ------------------------ Extraction Needed data from Courses api response ---------------------------------
  useEffect(() => {
    let extractedData = [];
    const myCoursesResponse = courses;
    for (const key in myCoursesResponse) {
      extractedData.push(...myCoursesResponse[key]);
    }
    setCoursesArr(extractedData);
  }, [courses]);
  // ------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (token)
      dispatch(
        getStudentSavedListRequest({ body: { page: { number: 1, size: 2 } } })
      );
  }, [token]);
  const { studentSavedList } = useSelector((state) => state.courses);



    


  return (
    <HomeTemplate
      loading={loading?.loading}
      handleClick={handleClick}
      isActive={isActive}
      setIsActive={setIsActive}
      finalData={finalData}
      categoriesSubjects={categoriesSubjects && categoriesSubjects}
      coursesArr={coursesArr}
      coursesLoading={isLoading?.type == "list" ? isLoading?.loading : false}
      clickedSubject={clickedSubject}
      studentSavedList={studentSavedList}
      setClickedSubject={setClickedSubject}
    />
  );
}
