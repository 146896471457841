import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CartTemplate from "Template/Cart/Cart.Template";
import {
  getCartItemsRequest,
  removeFromCartRequest,
} from "services/Modules/Cart";

export default function CartPage() {
  const dispatch = useDispatch();
  const [remove, setRemove] = useState(false);
  const data = useSelector((state) => state?.cart);

  // ============= remove cart item  =================

  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCartRequest(productId));

    setRemove(!remove);
  };
  useEffect(() => {
    dispatch(getCartItemsRequest({ page_number: 1, page_size: 10 }));
  }, [remove, dispatch, data?.removeFromCart]);

  //============ checkOut=========
  return (
    <CartTemplate cartItems={data} removeFromCart={handleRemoveFromCart} />
  );
}
