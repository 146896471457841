import React from 'react'
import Breadcrumb from 'components/Atoms/Breadcrumbs/Breadcrumb'
import { Box, Container, Stack } from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';


export default function CartHeader() {

  
  const { lightGreen } = useThemePalette();
  const breadcrumb = [
    { title: "Design", path: "", active: true, activeColor: lightGreen },
    {
      title: "User Experience Design",
      path: "",
      active: false,
      notActive: lightGreen,
    },

    {
      title: "Cart",
      path: "",
      active: false,
      notActive: lightGreen,
    },
  ];

  return (
    <>
      <Container>
        <Stack style={{ marginTop: "20px" }}>
          {breadcrumb && (
            <Breadcrumb
              data={breadcrumb}
              breadcrumbsBgColor={""}
              iconColor={lightGreen}
            />
          )}
        </Stack>
      </Container>
    </>
  );
}
