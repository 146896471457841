import { reduxRequest } from "../../../common/utils/reduxRequest";
import {
  authLoading,
  signupSuccess,
} from "./Actions";

export const signupRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: authLoading,
    successFunction: signupSuccess,
    loadingType: "signup",
    url: "user/signup",
    method: "post",
    action:variables.action,
    body: variables.body,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

