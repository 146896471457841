import Types from './Types'

const BOOK_MARKS_INIT_STATE = {
    bookMarks: [],
    isLoading: {
        type: "",
        loading: false,
    },
}

export function bookMarksReducer(state = BOOK_MARKS_INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_BOOK_MARKS_SUCCESS: {
            return {
                ...state,
                bookMarks: payload?.data,
            };
        }
        case Types.GET_BOOK_MARKS_LOADING: {
            return {
                ...state,
                isLoading: payload,
            };
        }
        case Types.CREATE_BOOK_MARK_SUCCESS: {
            return {
                ...state,
                bookMarks: [...state?.bookMarks, payload?.data]
            }
        }
        case Types.CREATE_BOOK_MARK_LOADING: {
            return {
                ...state,
                isLoading: payload
            }
        }
        case Types.REMOVE_BOOK_MARK_SUCCESS: {
            return {
                ...state,
                bookMarks: state?.bookMarks?.filter((bookmark) => bookmark?.id != payload?.data?.id)
            }
        }
        case Types.REMOVE_BOOK_MARK_LOADING: {
            return {
                ...state,
                isLoading: payload
            }
        }
        default: {
            return state;
        }
    }
}