/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Learning from '../../components/Molecules/Home/Learning'
import TopCategories from '../../components/Molecules/Home/TopCategories'
import LearningBenfits from '../../components/Molecules/Home/LearningBenfits'
import OurClients from '../../components/Molecules/Home/OurClients'
import MostPopular from '../../components/Molecules/Home/MostPopular'
import PopularCertificates from '../../components/Molecules/Home/PopularCertificates'
// import Layout from '../components/Organisms/Layout/Layout'
import Home from '../../components/Organisms/Home/Home'
import WhatsIcon from '../../components/Atoms/SharedComponents/WhatsIcon'
import HomeHeader from '../../components/Molecules/Home/HomeHeader'

export default function HomeTemplate({
    loading,
    getSubjectRequest,
    fetchAllSubjectsRequest,
    fetchFilteredSubjectsRequest,
    isActive,
    setIsActive,
    finalData,
    categoriesSubjects,
    handleClick,
    coursesArr,
    coursesLoading,
    clickedSubject,
    studentSavedList,
    setClickedSubject
}) {

    return <Home
        loading={loading}
        getSubjectRequest={getSubjectRequest}
        fetchAllSubjectsRequest={fetchAllSubjectsRequest}
        fetchFilteredSubjectsRequest={fetchFilteredSubjectsRequest}
        isActive={isActive}
        setIsActive={setIsActive}
        finalData={finalData}
        categoriesSubjects={categoriesSubjects}
        handleClick={handleClick}
        clickedSubject={clickedSubject}
        title='Most Popular'
        subTitle='Learn the latest skills to reach your professional goals'
        coursesArr={coursesArr}
        coursesLoading={coursesLoading}
        studentSavedList={studentSavedList}
        setClickedSubject={setClickedSubject}
    />
}
