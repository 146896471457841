import { Box, Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import SignupForm from "../../Auth/SignupForm";
import VerificationForm from "../../Auth/VerificationForm ";
import LoginForm from "../../Auth/LoginForm";
import { useSelector } from "react-redux";
import ForgetPasswordForm from "../../Auth/ForgetPasswordForm";
import CreateNewPasswordForm from "../../Auth/CreateNewPasswordForm";
import ChangePasswordForm from "../../Auth/ChangePasswordForm";
import DeleteAccountModel from "../../Auth/DeleteAccountModel";
import Footer from "./Footer";

export default function Layout({ logOutFun, bgColor }) {
  const { isOpenVerification } = useSelector((state) => state?.model);
  return (
    <Box bgcolor={bgColor} width="100%">
      <Navbar logOutFun={logOutFun} />
      <Outlet />
      <SignupForm />
      {isOpenVerification && <VerificationForm />}
      <ForgetPasswordForm />
      <CreateNewPasswordForm />
      <LoginForm />
      <ChangePasswordForm />
      <DeleteAccountModel />
      <Footer />
    </Box>
  );
}
