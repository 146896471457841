import React from "react";
import Vimeo from "@u-wave/react-vimeo";


export default function VimeoPlayer({
  video_url,
  paused = false,
  className = "vimeo",
  autoplay = false,
  showControls = true,
  style,
}) {
  return (
    <Vimeo
      video={video_url}
      // video="https://vimeo.com/3155182"
      autoplay={autoplay}
      loop
      muted={false}
      style={{ width: "100%" }}
      className={className}
      controls={showControls}
      paused={paused}
      responsive
      // sx={{
      //   '& .vimeo div':{
      //     padding:0
      //   }
      // }}
    />
  );
}
