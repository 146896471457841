import React, { useEffect, useState } from "react";
import { AppBar, Box, Stack, Toolbar, Container } from "@mui/material";
import NavbarLogo from "components/Atoms/NavbarLogo";
import NavbarSeacrchBox from "components/Molecules/Navbar/NavbarSeacrchBox";
import NavbarButtons from "components/Molecules/Navbar/NavbarButtons";
import NavbarResponsiveMenu from "components/Molecules/Navbar/NavbarResponsiveMenu";
import { useSelector } from "react-redux";
import { useThemePalette } from "common/hooks/useThemePalatte";
import logOut from "Assets/Images/logOut.svg";
import courses from "Assets/Images/courses.svg";
import settings from "Assets/Images/settings.svg";
import bookmark from "Assets/Images/bookmark.svg";
import points from "Assets/Images/points.svg";
import accountInfo from "Assets/Images/accountInfo.svg";
import Wallet from "Assets/Images/Wallet.svg";
import UserMenu from "components/Molecules/Navbar/UserMenu";

export default function Navbar({ logOutFun }) {
  const { whiteColor } = useThemePalette();
  const [auth, setAuth] = useState();
  const { isLoading } = useSelector((state) => state?.auth);

  useEffect(() => {
    localStorage.getItem("userToken") != "undefined" &&
      setAuth(localStorage.getItem("userToken"));
  }, [isLoading?.type]);

  const menu_items = [
    { icon: courses, title: "My courses", path: "/website/profile/courses" },
    { icon: accountInfo, title: "Account info", path: "/website/profile/accountInfo" },
    { icon: Wallet, title: "Wallet", path: "/website/profile/wallet" },
    { icon: points, title: "Points", path: "/website/profile/points" },
    { icon: bookmark, title: "Saved list", path: "/website/profile/saveList" },
    { icon: settings, title: "Settings", path: "/website/profile/Settings" },
    { icon: logOut, title: "Logout", fun: logOutFun },
  ];

  return (
    <>
      <AppBar
        position="static"
        sx={{
          bgcolor: whiteColor,
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box px={{ md: "0.5rem" }} py="10px">
          <Toolbar style={{ paddingRight: 0, paddingLeft: 0 }}>
            <Container maxWidth="xl">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                // spacing={1}
              >
                {/* ------------------------------- Web LOGO -------------------------------- */}
                <NavbarLogo />
                {/*-------------------------------- Web Search Box ----------------------------*/}
                <NavbarSeacrchBox />
                {/* ------------------------------- Right Logo ------------------------------- */}
                {auth ? (
                  <UserMenu menu_items={menu_items} display="none" />
                ) : (
                  <NavbarButtons display="none" />
                )}
                {/* ------------------------------- Responsive Menu Icon ---------------------- */}
                <NavbarResponsiveMenu>
                  {auth ? (
                    <UserMenu menu_items={menu_items} display="flex" />
                  ) : (
                    <NavbarButtons display="flex" />
                  )}
                </NavbarResponsiveMenu>
              </Stack>
            </Container>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
}
