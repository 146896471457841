import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { getBookMarksRequest } from "../../../../services/Modules/Ebook";

const BookControls = ({
  pageNumber,
  scale,
  setBookmarkedPages,
  setNumPages,
  session,
  isOnePage,
  numPages,
}) => {
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const dispatch = useDispatch()
  //main config
  //prevent right click
  // document.addEventListener("contextmenu", (event) => event.preventDefault());

  /** TO DISABLE SCREEN CAPTURE **/
  document.addEventListener("keyup", (e) => {
    // console.log(e.key, "event");
    if (e.key == "PrintScreen") {
      navigator.clipboard.writeText("");
    }
  });

  /** TO DISABLE PRINTS WHIT CTRL+P **/
  document.addEventListener("keydown", (e) => {
    if (e.ctrlKey && e.key == "p") {
      alert("This section is not allowed to print or export to PDF");
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });

  const { bookMarks } = useSelector(state => state?.bookMarks)
  useEffect(() => {
    if (session?.book?.id) dispatch(getBookMarksRequest({ book_id: session?.book?.id }))
  }, [session?.book?.id]);

  useEffect(() => {
    bookMarks && setBookmarkedPages(bookMarks)
  }, [bookMarks])

  const canvas = useRef();
  const [isRendered, setIsRendered] = useState();
  function onRenderSuccess() {
    setIsRendered(true);
  }
  function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  useEffect(() => {
    if (!isRendered || !canvas.current) {
      return;
    }

    var context = canvas.current.getContext("2d");
    var { width, height } = canvas.current;

    context.save();
    const angle = 35;
    context.translate(width / 2, height / 2);
    context.rotate(degreesToRadians(angle));
    context.globalCompositeOperation = "multiply";
    context.textAlign = "center";
    context.font = "120px sans-serif";
    context.fillStyle = "rgba(0, 0, 0, 0.25)";
    context.fillText("Pyramakerz", 0, 0);
    // context.fillText('Pyramakerz', 200, 200);
    context.restore();
  }, [isRendered]);



  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBlock: "20px",
        width: "100%",
      }}
    >
      {/* ebook */}
      <div
        // className="scroll"
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Document
          onLoadSuccess={onDocumentLoadSuccess}
          file={session?.book?.url}
          externalLinkRel={"_blank"}
          externalLinkTarget={"_blank"}
          style={{ width: "100px" }}
        >
          <Stack direction='row' columnGap='16px' >
            <Page
              scale={scale}
              pageNumber={pageNumber}
              renderAnnotationLayer={true}
              canvasRef={canvas}
              onRenderSuccess={onRenderSuccess}
              renderTextLayer={true}
              className={!isOnePage && 'test'}
            />
            {!isOnePage && (
              <Page
                scale={scale}
                pageNumber={
                  pageNumber + 1 != numPages ? pageNumber + 1 : numPages
                }
                renderAnnotationLayer={true}
                // canvasRef={canvas}
                onRenderSuccess={onRenderSuccess}
                className='test'
                renderTextLayer={true}
              />
            )}
          </Stack>
        </Document>
      </div>
    </div>
  );
};

export default BookControls;
