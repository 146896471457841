import { Box } from '@mui/material'
import React from 'react'
import logo from "../../Assets/Images/inclassLogo.jpg";

export default function NavbarLogo() {
    return (
        <Box width={{ xs: '50%', md: 'auto' }}>
            <img src={logo} style={{ width: '100%', height: 'auto' }} alt="logo" />
        </Box>
    );
}
