import { Box, Button, Stack } from '@mui/material'
import shoppingCard from '../../../Assets/Images/feather-shopping-cart.svg'
import { useDispatch } from 'react-redux'
import IconComponent from '../../Atoms/SharedComponents/IconComponent'
import { loginModel, signupModel } from '../../../services/Modules/Models/Actions'

export default function NavbarButtons({ display }) {
    const dispatch = useDispatch()
    return <>
        {/* -------------------------------- Navbar button container --------------------------- */}
        <Box
            display={{ xs: display, md: "flex" }}
        >
            <Stack
                flexDirection={{ xs: 'column', md: "row" }}
                justifyContent="center"
                alignItems="center"
                width="100%"
            >
                {/* -------------------------------- Shopping Card icom ------------------------- */}
                <IconComponent iconSrc={shoppingCard} />
                {/* ---------------------------- Login Button ----------------------------------- */}
                <Button
                    disableElevation
                    disableRipple
                    variant="text"
                    aria-label='login'
                    onClick={_ => dispatch(loginModel())}
                >
                    Login
                </Button>
                {/* ----------------------------- SignUp Button --------------------------------- */}
                <Button
                    disableElevation
                    disableRipple
                    variant="contained"
                    sx={{ ml: { lg: '1rem', md: '0rem' }, my: '0.5rem', p: { lg: '9px 27px', md: '5px 10px' } }}
                    aria-label='sign up'
                    onClick={_ => dispatch(signupModel())}
                >
                    Sign Up
                </Button>
            </Stack>
        </Box >
    </>
}
