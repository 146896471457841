import { Button, Divider, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import ModelBox from 'components/Atoms/Model/ModalBox';
import SelectBox from 'components/Atoms/Select/Select';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { WalletChargedSuccessfullyModel, chargeWalletModel } from 'services/Modules/Models/Actions';
import chargeByCode from 'Assets/Images/chargeByCode.svg'
import creditCard from 'Assets/Images/creditCard.svg'
import fawry from 'Assets/Images/fawry.svg'
import { useFormik } from 'formik';
import { chargeByCardSchema, chargeByCodeSchema, chargeByFawrySchema } from '../Auth/validation';
import { rechargeRequest } from 'services/Modules/Wallet';


export default function ChargeWalletForm() {
    const { isChargeWallet, isWalletChargedSuccessfully } = useSelector(state => state?.model)
    const dispatch = useDispatch()
    const { blackFour, transparentGray } = useThemePalette()
    const [value, setValue] = useState('code');

    const codeInitialValues = {
        method: "code",
        code: ""
    };

    const fawryInitialValues = {
        method: "fawry",
        amount: ''
    }

    const cardInitialValues = {
        method: "card",
        card_number: ''
    }

    const onSubmit = (values) => {
        const codeObj = {
            "payment": {
                "method": values?.method,
                "code": values?.code
            }
        }
        const fawryObj = {
            "payment": {
                "method": values?.method,
                "amount": values?.amount
            }
        }
        const cardObj = {
            "payment": {
                "method": values?.method,
                "card_number": values?.card_number
            }
        }
        const action = () => {
            dispatch(WalletChargedSuccessfullyModel())
            dispatch(chargeWalletModel());
            formik?.setValues({ ...codeInitialValues })
            formik?.setErrors('')
        }
        dispatch(rechargeRequest({ body: value == 'code' ? codeObj : value == 'fawry' ? fawryObj : cardObj, action }))
    };

    const formik = useFormik({
        initialValues: value == 'code' ? codeInitialValues : value == 'fawry' ? fawryInitialValues : cardInitialValues,
        onSubmit,
        validationSchema: value == 'code' ? chargeByCodeSchema : value == 'fawry' ? chargeByFawrySchema : chargeByCardSchema
    });

    const items = [
        { icon: fawry, name: 'Fawry', id: 'fawry' },
        { icon: creditCard, name: 'Credit card', id: 'card' },
        { icon: chargeByCode, name: 'Pay by code', id: 'code' },
    ]

    useEffect(() => {
        // value == 'code' ? formik?.setValues({ ...codeInitialValues }) :
        //     value == 'fawry' ?
        //         formik?.setValues({ ...fawryInitialValues })
        //         :
        //         formik?.setValues({ ...cardInitialValues })

        if (value == 'code') {
            formik?.setValues({ ...codeInitialValues })
        } else if (value == 'fawry') {
            formik?.setValues({ ...fawryInitialValues })
            formik?.setErrors({ amount: false })
        } else {
            formik?.setValues({ ...cardInitialValues })
        }
    }, [value])

    useEffect(() => {
        isChargeWallet == false && setValue('code')
    }, [isChargeWallet])

    return <>
        <ModelBox
            openVar={isChargeWallet}
            closeFunc={() => {
                dispatch(chargeWalletModel());
                formik?.setValues({ ...codeInitialValues })
                setValue('code')
            }}
            title='Transaction details'
            titleStyle={{ fontWeight: '600', fontSize: '22px', color: blackFour }}
            px="30px"
            justifyContent='flex-start'
        >
            <form onSubmit={formik?.handleSubmit}>
                <Stack sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "16px" }} color={'blackTwo'} fontWeight="500">
                        Charge method
                    </Typography>
                    <SelectBox
                        sx={{ borderRadius: '30px' }}
                        placeholder="Pay by code"
                        items={items}
                        variant='outlined'
                        defaultValue='Pay by code'
                        onchange={(e) => {
                            formik?.setValues({
                                ...formik.values,
                                method: e.target.value
                            })
                            setValue(e.target.value)
                        }}
                        value={formik?.values?.method}
                        menuItemStyle={{
                            py: '23px',
                            borderBottom: `1px solid ${transparentGray}`,
                        }}
                    />
                    {formik?.touched?.method &&
                        Boolean(formik?.errors?.method) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.method}
                            </FormHelperText>
                        )}
                </Stack>
                {value == 'code' && <Stack sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "16px" }} color={'blackTwo'} fontWeight="500">
                        Code
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        hiddenLabel
                        variant="outlined"
                        onChange={(e) =>
                            formik?.setValues({
                                ...formik.values,
                                code: e.target.value
                            })
                        }
                        value={formik?.values?.code}
                        InputProps={{
                            sx: { borderRadius: '30px' },
                        }}
                        name="code"
                    />
                    {formik?.touched?.code &&
                        Boolean(formik?.errors?.code) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.code}
                            </FormHelperText>
                        )}
                </Stack>}
                {value == 'fawry' && <Stack sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "16px" }} color={'blackTwo'} fontWeight="500">
                        amount
                    </Typography>
                    <TextField
                        id="amount"
                        hiddenLabel
                        variant="outlined"
                        onChange={(e) =>
                            formik?.setValues({
                                ...formik.values,
                                amount: e.target.value
                            })
                        }
                        value={formik?.values?.amount}
                        InputProps={{
                            sx: { borderRadius: '30px' },
                        }}
                        name="amount"
                    />
                    {formik?.touched?.amount &&
                        Boolean(formik?.errors?.amount) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.amount}
                            </FormHelperText>
                        )}
                </Stack>}
                {value == 'card' && <Stack sx={{ pb: "16px" }}>
                    <Typography sx={{ pb: "16px" }} color={'blackTwo'} fontWeight="500">
                        card number
                    </Typography>
                    <TextField
                        id="cardNumber"
                        hiddenLabel
                        variant="outlined"
                        onChange={(e) =>
                            formik?.setValues({
                                ...formik.values,
                                card_number: e.target.value
                            })
                        }
                        value={formik?.values?.card_number}
                        InputProps={{
                            sx: { borderRadius: '30px' },
                        }}
                        name="cardNumber"
                    />
                    {formik?.touched?.card_number &&
                        Boolean(formik?.errors?.card_number) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.card_number}
                            </FormHelperText>
                        )}
                </Stack>}
                <Divider sx={{ my: '40px' }} />
                <Stack direction='row' justifyContent='center' >
                    <Button sx={{ px: '90px', py: '10px' }} variant='contained' type='submit'>Charge</Button>
                </Stack>
            </form>
        </ModelBox >
    </>
}

