import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useLocation } from "react-router-dom";
import CoursesDetailsOverview from "./CoursesDetailsOverview";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";

export default function CustomTabs({
  studentCoursesDetails,
  studentCoursesDetailsLoading,
  reviews,
  subjectName,
  subjectId,
}) {
  const path = useLocation();
  const { gray, blackTwo, primaryColor } = useThemePalette();
  const navLinks = [
    {
      label: "Overview",
      path: "#Overview",
    },
    {
      label: "Syllabus",
      path: "#Syllabus",
    },
    {
      label: "Instructors",
      path: "#Instructors",
    },
    {
      label: "Reviews",
      path: "#Reviews",
    },
    {
      label: "Quizzes",
      path: "#Quizzes",
    },
    {
      label: "Assignments",
      path: "#Assignments",
    },
  ];
  return (
    <>
      <Stack
        direction="row"
        bgcolor="#fff"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" flexWrap="wrap" id="Overview">
          {navLinks.map((link, i) => (
            <Button
              key={i}
              href={link?.path}
              sx={
                path?.hash === link.path
                  ? {
                      color: "primary,main",
                      fontWeight: "600",
                      borderBottom: "solid 2px #28A19C",
                      borderRadius: "0",
                      py: "24px",
                      px: { lg: "35px" },
                      // maxWidth: {lg:'140px'}
                    }
                  : {
                      color: gray,
                      fontWeight: "600",
                      borderRadius: "0",
                      py: "24px",
                      px: { lg: "35px" },
                      // maxWidth: '140px'
                    }
              }
            >
              {link?.label}
            </Button>
          ))}
        </Stack>
        {path?.hash !== "#Overview" &&
          path?.hash !== "#Syllabus" &&
          path?.hash !== "" && (
            <Stack direction="row" mr="35px" gap="24px">
              <Stack>
                <Typography
                  textAlign="center"
                  color={blackTwo}
                  fontWeight="700"
                >
                  {studentCoursesDetails?.original_price_currency}{" "}
                  {studentCoursesDetails?.original_price}
                </Typography>
                <Button
                  sx={{
                    p: 0,
                    fontSize: "13px",
                    fontWeight: 500,
                    borderBottom: `1px solid ${primaryColor}`,
                    borderRadius: 0,
                  }}
                >
                  Add to cart
                </Button>
              </Stack>
              <Button variant="contained" sx={{ px: "24px" }}>
                Enroll Now
              </Button>
            </Stack>
          )}
      </Stack>
      <CoursesDetailsOverview
        studentCoursesDetails={studentCoursesDetails}
        studentCoursesDetailsLoading={studentCoursesDetailsLoading}
        reviews={reviews}
        subjectName={subjectName}
        subjectId={subjectId}
      />
    </>
  );
}
