// Import necessary action creators and reduxRequest utility
import { reduxRequest } from "common/utils/reduxRequest";
import {
  AddToCartSuccess,
  GetCartItemsSuccess,
  PromoCodeSuccess,
  cartLoading,
  checkOutSuccess,
  clearCartSuccess,
  removeFromCartSuccess,
} from "./Actions";

export const getCartItemsRequest = ({ page_number, page_size }) => {
  const requestData = {
    isLoading: cartLoading,
    successFunction: GetCartItemsSuccess,
    url: `user/cart?page_number=${page_number}&page_size=${page_size}`,
    loadingType: "allCartItems",
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const removeFromCartRequest = (product_id) => {
  const requestData = {
    isLoading: cartLoading,
    successFunction: removeFromCartSuccess,
    url: `products/${product_id}/remove_from_cart`,
    loadingType: "removeCartItem",
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const addToCartRequest = ({product_id}) => {
  const requestData = {
    isLoading: cartLoading,
    successFunction: AddToCartSuccess,
    url: `products/${product_id}/add_to_cart`,
    loadingType: "addToCart",
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const checkoutRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: cartLoading,
    successFunction: checkOutSuccess,
    url: `user/cart/checkout`,
    loadingType: "checkout",
    method: "post",
    ...variables,
    body: {
      payment: {
        method: "cash",
      },
    },
  };



  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const ClearCart = ({...variables}) => {
  const requestData = {
    isLoading: cartLoading,
    successFunction: clearCartSuccess,
    url: `user/cart/clear`,
    loadingType: "clearCart",
    method: "Delete",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const AddPromoCode = ({ product_id, coupon_id, cart_item_id }) => {

  const requestData = {
    isLoading: cartLoading,
    successFunction: PromoCodeSuccess,
    url: `/products/${product_id}/coupons?coupon=${coupon_id}&cart_item_id=${cart_item_id}`,
    loadingType: "addCoupon",
    method: "post",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
