import React from 'react';
import { Stack, Box, Typography, Button} from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import AboutImg from "../../../../Assets/Images/aboutus.png";
import CustomContainer from 'components/Atoms/SharedComponents/LandingPage/CustomContainer';

const AboutUs = ({ subtitle }) => {
  const { warmGray } = useThemePalette();
  const aboutContent = {
    title: "About Us",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur",
  };

  return (
    <CustomContainer>
      <Stack
        sx={{ flexDirection: { xs: 'column' , sm:'row' } }}
        alignItems="stretch"
        justifyContent="space-between"
        spacing={8}
        py={5}
        
      >
        <Stack direction="column"  
        alignItems="left" 
        justifyContent="space-between" 
        sx={{ width: '45%' }}>
          <img
            src={AboutImg}
            alt="about-us"
            sx={{ marginTop: '100px', ml: '100px', mb: '50px' }}
          />  
        </Stack>        

        <Stack direction="column"  justifyContent="space-between"  sx={{ width: '45%' }}>
          <Box>
            <Typography variant='h2'
                marginBottom='1rem'
                color='primary.main'
                fontSize='40px'
                fontWeight='600'
                marginRight='50px'>
                {aboutContent.title}       
            </Typography>
          </Box>
          <Typography variant="body1" color={warmGray} sx={{ fontSize: '24px', fontWeight: 500, lineHeight: '42px'}}>
            {aboutContent.subtitle}
          </Typography>
          <Button variant="contained" color="primary" sx={{ width: 'fit-content',  padding: '16px 26px', borderRadius: '10px', marginRight: '20px' }}>Read More</Button>
        </Stack>
      </Stack>
      </CustomContainer>
  );
};

export default AboutUs;
