import axios from "axios";
import { StatgingBaseURL } from "../utils/constant";
import handleErrors from "../utils/handel_error";
import { getUploadedFileSuccess } from "./Action";


const token = "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozNzU4LCJkZXZpY2VfaWQiOjI4NywiZXhwIjo0ODM0NjY2OTcxfQ.9dXNqKEAOYEP745wuVsh3_CPVE5VOpFAqzV-j4sn8C8"
export const getPresignedUrlRequest = (file, fileExt) => async (dispatch) => {
    // dispatch(uploadLoading({ type: 'mainBlog', isLoading: true }));
    let files = {
        "files": [
            {
                extension: fileExt,
            },
        ]
    }
    try {
        const { data } = await axios.post(`${StatgingBaseURL}/presigned_url`, files);
        console.log(data);
        let { presigned_url, file_url } = data?.data[0];
        delete axios.defaults.headers.common["Authorization"];
        await axios.put(presigned_url, file, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch(getUploadedFileSuccess(file_url))
    } catch (error) {
        handleErrors(error);
    }
}