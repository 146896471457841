// Define action types for cart-related actions
const CartTypes = {
  GET_CART_ITEMS_SUCCESS: "GET_CART_ITEMS_SUCCESS",
  GET_CART_ITEMS_ERROR: "GET_CART_ITEMS_ERROR",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_Success",
  CART_LOADING: "CART_LOADING",
  CHECK_OUT_SUCCESS: "CHECK_OUT_SUCCESS",
  CLEAR_CART_SUCCESS: "CLEAR_CART_SUCCESS",
  PROMO_CODE_SUCCESS: "PROMO_CODE_SUCCESS",
};

export default CartTypes;
