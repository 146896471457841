import React from "react";
import LandingHomeTemplate from "Template/LandingPageTemplate/Home/LandingHomeTemplate";
export default function LandingHomePage() {
  
  const achievednumbers=[
    {number:250,title:"Active students"},
    {number:80,title:"Categories"},
    {number:70,title:"Classes"},
    {number:60,title:"Professonal Instractor"}
  ]
    return (
    <div>
      <LandingHomeTemplate 
      achievednumbers={achievednumbers} 
      /> 
    </div>
  );
};


