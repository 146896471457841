import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Button,
} from "@mui/material";
import orderListImage from "../../../Assets/Images/orderList.svg";
import { useThemePalette } from "common/hooks/useThemePalatte";
import { useDispatch } from "react-redux";
import { checkoutRequest, getCartItemsRequest } from "services/Modules/Cart";
import { useSelector } from "react-redux";
import ModelBox from "components/Atoms/Model/ModalBox";
import { useNavigate } from "react-router-dom";

function PricingSummary({ cartItems }) {
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0);
  const { primaryColor } = useThemePalette();
  const dispatch = useDispatch();
  const [checkoutKey, setCheckoutKey] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const data = useSelector((state) => state?.cart);
  const navigate = useNavigate();

  const handleCheckOutBTN = () => {
    const requestData = {
      action: () => {
        setOpenModel(true);
      },
    };
    dispatch(checkoutRequest(requestData));
    setCheckoutKey(!checkoutKey);
  };

  useEffect(() => {
    dispatch(getCartItemsRequest({ page_number: 1, page_size: 10 }));
    const sumWithInitial = cartItems?.CartList?.cart_items.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.price_before_discount,
      0
    );
    setPriceAfterDiscount(sumWithInitial);
  }, []);

  const AddMore = () => {
    navigate("/");
  };
  return (
    <Container>
      <ModelBox
        openVar={openModel}
        closeFunc={() => {
          setOpenModel(false);
          dispatch(getCartItemsRequest({ page_number: 1, page_size: 10 }));
        }}
        title=''
        titleStyle={{ fontSize: "32px", fontWeight: "700", mb: "32px" }}
        px='30px'
      >
        <Container
          sx={{ maxWidth: { xl: "xl", md: "lg" } }}
          style={{ textAlign: "center" }}
        >
          <Stack
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginBottom: "15px",
            }}
          >
            <img src={orderListImage} alt='order List' />
          </Stack>
          <Typography variant='h5' style={{ marginBottom: "15px" }}>
            Course payment is pending in
          </Typography>
          <Typography
            variant='h5'
            style={{
              color: primaryColor,
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            Order List
          </Typography>
          <Button
            style={{
              backgroundColor: primaryColor,
              color: "white",
              width: "60%",
              marginBottom: "30px",
            }}
          >
            Order List
          </Button>
        </Container>
      </ModelBox>
      <Typography variant='h5' gutterBottom>
        Summary
      </Typography>
      <Stack sx={{}}>
        <Stack sx={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Typography>Total Price Before Discount </Typography>
          <Typography>
            {priceAfterDiscount}
            {cartItems?.CartList?.total_price?.currency}
          </Typography>
        </Stack>
        <Stack sx={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Typography> Discount</Typography>
          <Typography>
            {priceAfterDiscount - cartItems?.CartList?.total_price?.amount}
            {cartItems?.CartList?.total_price?.currency}
          </Typography>
        </Stack>
        <Stack sx={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Typography>Total Price After Discount</Typography>
          <Typography>
            {cartItems?.CartList?.total_price?.amount}{" "}
            {cartItems?.CartList?.total_price?.currency}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ justifyContent: "center", flexDirection: "row" }}>
        <Button
          sx={{
            backgroundColor: primaryColor,
            color: "white",
            "&:hover": {
              backgroundColor: primaryColor,
            },
            paddingInline: "50px",
          }}
          onClick={() => handleCheckOutBTN()}
        >
          Checkout ( {cartItems?.CartList?.total_price?.amount}
          {cartItems?.CartList?.total_price?.currency} )
        </Button>
        <Button
          sx={{
            backgroundColor: "white",
            color: primaryColor,
            "&:hover": {
              backgroundColor: "white",
            },
            marginLeft: "20px",
            paddingInline: "100px",
            border: `1px solid ${primaryColor}`,
          }}
          onClick={() => AddMore()}
        >
          Add More
        </Button>
      </Stack>
    </Container>
  );
}

export default PricingSummary;
