import Types from "./Types";

export const zoomLoading = (payload) => ({
  type: Types.ZOOM_LOADING,
  payload,
});

export const getZoomCredSuccess = (payload) => ({
  type: Types.GET_ZOOM_CRED_SUCCESS,
  payload,
});

export const getMeetingInfoSuccess = (payload) => ({
  type: Types.GET_MEETING_INFO_SUCCESS,
  payload,
});

