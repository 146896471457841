/* eslint-disable no-unused-vars */
import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CardImg from "./CardImg";
import CardVoteIcons from "./CardVoteIcons";
import motionGraphic from "../../../../Assets/Images/motionGraphic.webp";
import CousresCardDetails from "../CousresCardDetails";
import { Stack } from "@mui/system";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate } from "react-router-dom";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import { useDispatch } from "react-redux";
import { ClearCart, addToCartRequest } from "services/Modules/Cart";
import { toast } from "react-toastify";
import ModelBox from "components/Atoms/Model/ModalBox";
import { AddToCartSuccess } from "services/Modules/Cart/Actions";
import { useSelector } from "react-redux";

export default function CoursesCard({
  studentSavedList,
  data,
  clickedSubject,
  url,
  flexWrap,
  ...rest
}) {
  const {
    final_rating_from_reviews,
    reviews_number,
    original_price,
    is_price_hidden,
    original_price_currency,
    discount_percentage,
    classified_product,
    classified_product: { instructors },
  } = data;
  let namesArray = [];
  instructors?.filter((name) => namesArray.push(name.name));
  const instructorsNames = namesArray.join();
  const { warmGray, primaryColor } = useThemePalette();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateUrl = `/website/courses-details/${classified_product?.course_id}`;
  const [openModel, setOpenModel] = useState(false);
  const { clearCartSuccess, AddToCartSuccess } = useSelector(
    (state) => state?.cart
  );

  const handleClick = () => {
    navigate(`${url ? url : navigateUrl}`);
  };
  const handleEnroll = (product_id) => {
    const requestData = {
      action: () => {
        setOpenModel(true);
      },
    };
    dispatch(ClearCart(requestData));
    dispatch(addToCartRequest({product_id}));
    setTimeout(() => {
      setOpenModel(false); 
      navigate(`user/cart`);
    }, 10);
 
};

  const handleAddToCart = (product_id) => {
    dispatch(addToCartRequest({product_id}));
  };

  const savedList = (product_id) => {
    let studentSavedListData = studentSavedList;
  };
  return (
    <Box
      boxShadow='0 3px 6px 0 rgba(0, 0, 0, 0.16)'
      borderRadius='20px'
      marginY='0.5rem'
      bgcolor='info.main'
      width='100%'
    >
      <ModelBox
        openVar={openModel}
        closeFunc={() => {
          setOpenModel(false);
        }}
        title='Are You Sure To Clear Your Cart'
        titleStyle={{ fontSize: "32px", fontWeight: "700", mb: "32px" }}
        px='30px'
      >
        <Stack>
          <Container>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  backgroundColor: primaryColor,
                  color: "white",
                  width: "30%",
                }}
                onClick={() => {
                  handleEnroll(data?.product_id);
                  setOpenModel(false);
                }}
              >
                Yes
              </Button>
              <Button
                style={{ width: "30%" }}
                onClick={() => {
                  setOpenModel(false);
                }}
              >
                No
              </Button>
            </Stack>
          </Container>
        </Stack>
      </ModelBox>
      {/* --------------------------- Top Img Container ------------------------- */}
      <CardImg
        imgSrc={
          classified_product?.image_url
            ? classified_product?.image_url
            : motionGraphic
        }
        product_id={data?.product_id}
        savedList={savedList}
      />
      {/* ----------------------------- Cart Content ---------------------------- */}
      <Box sx={{ px: "16px" }} pt='24px'>
        {/* -------------------------- Cart Title ----------------------------- */}
        <Typography
          minHeight='48px'
          fontWeight='700'
          onClick={(_) => handleClick(classified_product)}
          sx={{ cursor: "pointer" }}
        >
          {classified_product?.title?.slice(0, 30)}
        </Typography>
        {/* ---------------------------- Vote Icons Container ----------------- */}
        <CardVoteIcons
          final_rating_from_reviews_fontSize='14px'
          reviews_number_font_size='11px'
          final_rating_from_reviews={final_rating_from_reviews}
          reviews_number={`(${reviews_number})`}
        />
        {/* --------------------------------  Cart Description  ------------------------------------ */}
        <CousresCardDetails
          description={classified_product?.description}
          instructorsNames={instructorsNames}
        />
        {/* ----------------------------------- Cart Pricing ------------------------------ */}
        <Stack direction='row' spacing={1}>
          <Typography fontWeight='700'>
            {is_price_hidden
              ? ""
              : original_price_currency?.EGP
              ? original_price_currency?.EGP
              : original_price_currency}
            {is_price_hidden ? "Free" : original_price}
          </Typography>
          <Box position='relative'>
            <Typography
              color={warmGray}
              component='span'
              fontWeight='500'
              sx={{ textDecoration: "line-through" }}
            >
              {discount_percentage === 0
                ? ""
                : `${
                    original_price_currency?.EGP
                      ? original_price_currency?.EGP
                      : original_price_currency
                  } ${discount_percentage}`}
            </Typography>
          </Box>
        </Stack>
        {/* --------------------------------------- Cart Actions  -------------------------------------- */}
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={3}
        >
          {/* -------------------------------- Enroll Now Button ------------------------- */}
          <Button
            sx={{ my: "1.5rem", flexGrow: 1 }}
            variant='outlined'
            aria-label='enroll now'
            // onClick={() => handleEnroll(data?.product_id)}
            onClick={() => setOpenModel(true)}
          >
            Enroll Now
          </Button>
          {/* ---------------------------------------- icons  ---------------------------- */}
          <Button
            variant='outlined'
            sx={{ cursor: "pointer" }}
            aria-label='shopping card'
            onClick={() => handleAddToCart(data?.product_id)}
          >
            <AddShoppingCartIcon sx={{ cursor: "pointer" }} />
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
