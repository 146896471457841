import React from "react";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import LearningContent from "./LearningContent";
import { Stack } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";

export default function Learning() {
  return (
    <>
<CustomContainer>    
    <Stack 
        alignItems="center"
        sx={{ backgroundColor: "white" }}
        py={7}>
          
      {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
      <FrequentSkelton
        title="Learning Process"
        subtitle="Get to Know how to gain Know Ledge With Us With This Simple Instruction That You Can Follow"
      ></FrequentSkelton>
      {/* ------------------------------ Display Static Img and Title ----------------------- */}
         <LearningContent />
    </Stack>
    </CustomContainer> 
    </>
  );
}
