import React, { useState } from "react";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import { Container, Typography } from "@mui/material";
import CourseDetailsBox from "./CourseDetailsBox";
import BenefitsList from "../../Home/BenefitsList";
import DoneIcon from "@mui/icons-material/Done";
import Syllabus from "./Syllabus";
import Instrucrtors from "./Instrucrtors";
import CoursesDetailsQuizzes from "./CoursesDetailsQuizzes";
import CoursesDetailsAssignments from "./CoursesDetailsAssignments";
import { useNavigate, useParams } from "react-router-dom";
import playVideoIcon from "../../../../Assets/Images/playVideoIcon.svg";
import CoursesDetailsReviews from "./CoursesDetailsReviews";
import CoursesDetailsBoxTitle from "../../../Atoms/SharedComponents/CoursesDetailsBoxTitle";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import { Box, Stack, Skeleton } from "@mui/material";
import VimeoPlayer from "../../../Atoms/SharedComponents/VimeoPlayer";

export default function CoursesDetailsOverview({
  studentCoursesDetails,
  reviews,
  subjectName,
  subjectId,
}) {
  const { blackTwo, warmGray3, secondryColor } = useThemePalette();
  const navigate = useNavigate();

  const dotIcon = (
    <FiberManualRecordSharpIcon sx={{ width: "10px", color: blackTwo }} />
  );
  const checkIcon = <DoneIcon sx={{ color: secondryColor }} />;

  const requirementsList = [
    {
      title:
        "You will need a copy of Adobe XD 2019 or above. A free trial can be downloaded from Adobe",
      icon: dotIcon,
    },
    { title: "No previous design experience is needed.", icon: dotIcon },
    { title: "No previous Adobe XD skills are needed.", icon: dotIcon },
  ];

  const learnList = [
    { title: "Lorem ipsum dolor sit amet, consetetur", icon: checkIcon },
    { title: "Lorem ipsum dolor sit amet, consetetur", icon: checkIcon },
    {
      title:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
      icon: checkIcon,
    },
    { title: "Lorem ipsum dolor sit amet, consetetur", icon: checkIcon },
    { title: "Lorem ipsum dolor sit", icon: checkIcon },
    {
      title: "Lorem ipsum dolor sit amet, consetetur sadipscing",
      icon: checkIcon,
    },
    { title: "Lorem ipsum dolor sit amet, consetetur", icon: checkIcon },
    { title: "Lorem ipsum dolor amet", icon: checkIcon },
  ];
  const cardItems = [
    { title: "13 hours on-demand video", icon: "Group11751" },
    { title: "69 downloadable resources", icon: "Group11741" },
    { title: "Full lifetime access", icon: "Group11731" },
    { title: "Access on mobile and TV", icon: "Group11721" },
    { title: "Assignments", icon: "Group11711" },
    { title: "Certificate of completion", icon: "Group11701" },
  ];
  const checkType = (obj, content_type) => {
    if (obj?.assignment_id)
      return { id: obj?.assignment_id, type: "assignment" };
    else if (obj?.quiz_id) return { id: obj?.quiz_id, type: "quiz" };
    else if (obj?.video_url && content_type == "video")
      return { id: obj?.course_session_id, type: obj?.content_type };
    else if (obj?.book && content_type == "book")
      return { id: obj?.book?.id, type: obj?.content_type };
  };
  const { course_id } = useParams();

  const handlePreviewClick = (session, content_type) => {
    const { id } = checkType(session, content_type);
    if (content_type === "book") {
      return navigate(
        `/website/ebook/${course_id}/${session?.section_id}/${session?.course_session_id}/${id}?type=${content_type}&subject_name=${subjectName}&subject_id=${subjectId}`
      );
    }
    navigate(
      `/website/courses-details/${course_id}/${session?.section_id}/${id}?type=${content_type}&subject_name=${subjectName}&subject_id=${subjectId}`
    );
  };

  const [showControls, setShowControls] = useState(false);
  const [paused, setPaused] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const handlePlayVideo = () => {
    setLoading(true);
    setShowControls(true);
    setPaused(false);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="start">
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Box maxWidth={{ lg: "69%", xs: "95%" }}>
            <CourseDetailsBox
              title="About This Course"
              actionTitle="Show More"
              action
              description={
                studentCoursesDetails?.classified_product?.description
              }
              childrenMb="32px"
              pt="32px"
              px="36px"
            >
              <Typography color={blackTwo} fontWeight="500">
                {studentCoursesDetails?.classified_product?.description}
              </Typography>
            </CourseDetailsBox>
            <CourseDetailsBox
              title="Requirements"
              actionTitle="Show More"
              pt="32px"
              px="36px"
            >
              {requirementsList.map((item, i) => (
                <BenefitsList
                  textColor={warmGray3}
                  pl="5px"
                  mb="16px"
                  textMarginLeft="5px"
                  key={i}
                  ListIcon={item.icon}
                  fontSize="15px"
                >
                  {item.title}
                </BenefitsList>
              ))}
            </CourseDetailsBox>
            <CourseDetailsBox
              title="What you will learn"
              action
              actionTitle="Show More"
              pt="32px"
              px="36px"
            >
              <Stack direction="row" flexWrap="wrap">
                {learnList.map((item, i) => (
                  <Box width={{ md: "327px" }} key={i}>
                    <BenefitsList
                      textColor={warmGray3}
                      pl="5px"
                      mb="16px"
                      textMarginLeft="5px"
                      ListIcon={item.icon}
                      fontSize="15px"
                    >
                      {item.title}
                    </BenefitsList>
                  </Box>
                ))}
              </Stack>
            </CourseDetailsBox>
          </Box>
          {/* ----------------------------------------------- Courses Details Card ------------------------------------------------ */}
          <Box maxWidth={{ lg: "29%", xs: "95%" }}>
            <Stack
              ml="auto"
              sx={{ display: { lg: "block", xs: "none" }, my: "32px" }}
              boxShadow=" 1px 1px 6px 0 rgba(0, 0, 0, 0.16)"
              bgcolor="info.main"
              borderRadius="20px"
              height="fit-content"
            >
              <Box position="relative" height="225px">
                {!isLoading ? (
                  <VimeoPlayer
                    video_url={
                      studentCoursesDetails?.classified_product?.video_url
                        ? studentCoursesDetails?.classified_product?.video_url
                        : "https://vimeo.com/498071737"
                    }
                    paused={paused}
                    className="course-details"
                    autoplay={showControls}
                    showControls={showControls}
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"225px"}
                  />
                )}
                <Stack
                  position="absolute"
                  justifyContent="center"
                  onClick={handlePlayVideo}
                  width="100%"
                  height="100%"
                  sx={{
                    top: 0,
                    left: 0,
                    display: !showControls ? "flex" : "none",
                  }}
                  alignItems="center"
                >
                  <img
                    src={playVideoIcon}
                    width="64px"
                    style={{
                      cursor: "pointer",
                    }}
                    height="64px"
                    alt="play video icon"
                  />
                </Stack>
              </Box>

              <Box mt="32px" mb="28px">
                <Container maxWidth="xl">
                  <CoursesDetailsBoxTitle
                    title="This course includes :"
                    fontSize="20px"
                    mb="0"
                    letterSpacing="-0.77px"
                  />
                  <Stack direction="row" mt="28px" flexWrap="wrap">
                    {cardItems.map((item, i) => (
                      <Box width={{ md: "327px" }} key={i}>
                        <BenefitsList
                          textColor={warmGray3}
                          pl="5px"
                          iconMr="15px"
                          mb="16px"
                          textMarginLeft="5px"
                          imgSrc={item.icon}
                          fontSize="15px"
                        >
                          {item.title}
                        </BenefitsList>
                      </Box>
                    ))}
                  </Stack>
                </Container>
              </Box>
              {/* </Box> */}
            </Stack>
          </Box>
        </Stack>
        <Box width={{ md: "69%", xs: "95%" }}>
          <Syllabus
            studentCoursesDetails={studentCoursesDetails}
            handlePreviewClick={handlePreviewClick}
          />
        </Box>
        <Instrucrtors studentCoursesDetails={studentCoursesDetails} />
        <CoursesDetailsReviews
          final_rating_from_reviews={
            studentCoursesDetails?.final_rating_from_reviews
          }
          reviews_number={studentCoursesDetails?.reviews_number}
          studentCoursesDetails={studentCoursesDetails}
          reviews={reviews}
        />
        <CoursesDetailsQuizzes
          studentCoursesDetails={studentCoursesDetails}
          handlePreviewClick={handlePreviewClick}
        />
        <CoursesDetailsAssignments
          studentCoursesDetails={studentCoursesDetails}
          handlePreviewClick={handlePreviewClick}
        />
      </Box>
    </Stack>
  );
}
