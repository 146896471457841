import { Box, Container, Stack } from "@mui/material";
import React from "react";
import SideNavbar from "./SideNavbar";
import { Outlet } from "react-router-dom";
import StudentReviewForm from "../../Profile/StudentReviewForm";
import { useEffect } from "react";
import WalletFilterByForm from "components/Organisms/Profile/WalletFilterByForm";
import ChargeWalletForm from "components/Organisms/Profile/ChargeWalletForm";
import WalletChargedSuccessfully from "components/Organisms/Profile/WalletChargedSuccessfully";

export default function ProfileLayout({ logOutFun, setBgColor }) {
  useEffect(() => {
    setBgColor("rgba(0, 0, 0, 0.07)");
  }, []);
  return (
    <Container sx={{ my: 4 }}>
      <Stack direction="row" columnGap="22px">
        <Box sx={{ width: "25%" }}>
          <SideNavbar logOutFun={logOutFun} />
        </Box>
        <Box sx={{ width: { lg: "70%" } }}>
          <Outlet />
        </Box>
      </Stack>
      <StudentReviewForm />
      <WalletFilterByForm />
      <ChargeWalletForm />
      <WalletChargedSuccessfully/>
    </Container>
  );
}
