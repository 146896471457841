import * as Yup from 'yup';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const test = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{5}$/
const countryCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/gm

export const signupSchema = Yup.object({
    name: Yup.string().required('Required!'),
    email: Yup.string().email('Invalid email format').required('Required!'),
    // phone_number: Yup.string().required('Required!').matches(phoneRegExp, 'Phone number is not valid'),
    password: Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
})

export const loginSchema = Yup.object({
    // phone_number: Yup.string().required('Required!').matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email('Invalid email format').required('Required!'),
    password: Yup.string()
    // .required('No password provided.')
    // .min(8, 'Password is too short - should be 8 chars minimum.')
    // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
})

export const messageUsSchema = Yup.object({
    name: Yup.string().required('Required!'),
    email: Yup.string().email('Invalid email format').required('Required!'),
    country: Yup.string().required('Required!'),
    subject: Yup.string().required('Required!'),
    message: Yup.string().required('Required!'),
})


export const forgetPasswordByPhoneSchema = Yup.object({
    phone_number: Yup.string().required('Required!').matches(phoneRegExp, 'Phone number is not valid'),
    country_code: Yup.string(),
})

export const forgetPasswordByEmailSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required!'),

})

export const resetPasswordSchema = Yup.object({
    password: Yup.string().required('Password is required'),
    confirmation_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

export const changePasswordSchema = Yup.object({
    current_password: Yup.string(),
    password: Yup.string().required('Password is required'),
    confirmation_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

export const chargeByCodeSchema = Yup.object({
    method: Yup.string().required('method is required'),
    code: Yup.string().required('code is required'),
})


export const chargeByFawrySchema = Yup.object({
    method: Yup.string().required('method is required'),
    amount: Yup.string().required('amount is required'),
})

export const chargeByCardSchema = Yup.object({
    method: Yup.string().required('method is required'),
    card_number: Yup.string().required('card number is required'),
})
