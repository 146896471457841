import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSubjectsRequest } from "../../../services/Modules/Subjects";
import CoursesListTemplate from "../../../Template/Courses/CoursesList/CoursesListTemplate";
import { getCoursesListRequest } from "../../../services/Modules/Courses";

export default function CoursesListPage() {
  const [pageNumber, setPageNumber] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(3);

  const diptach = useDispatch();

  useEffect(() => {
    diptach(
      fetchAllSubjectsRequest({
        page_number: pageNumber,
        page_size: 5,
      })
    );
  }, [pageNumber]);

  const { allSubjects } = useSelector((state) => state.subjects);

  useEffect(() => {
    diptach(
      getCoursesListRequest({
        page_number: pageNumber,
        page_size: 3,
        school_class_id: 1,
      })
    );
  }, [pageNumber]);

  const { coursesList, isLoading } = useSelector((state) => state.courses);

  const PageChanging = (ClickedPage) => {
    setPageNumber(ClickedPage);
  };

  return (
    <CoursesListTemplate
      allSubjectData={allSubjects}
      allCoursesList={coursesList?.courses?.STEAM}
      PageChanging={PageChanging}
      dataPerPage={dataPerPage}
      isLoading={isLoading}
    />
  );
}
