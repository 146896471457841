import Types from "./Types";

const CART_INIT_STATE = {
  items: [],
  isLoading: {
    type: "",
    loading: false,
  },
  CartList: [],
  error: false,
  removeFromCart: {},
  AddToCartSuccess: {},
  checkOutSuccess: {},
  clearCartSuccess: {},
  PromoCodeSuccess:{},
};


export function cartReducer(state = CART_INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_CART_ITEMS_SUCCESS: {
      // console.log(payload);
      return {
        ...state,
        // CartList: payload?.data?.cartItems,
        CartList: payload?.data,
      };
    }
    case Types.GET_CART_ITEMS_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }

    case Types.CART_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.REMOVE_FROM_CART: {
      return {
        ...state,
        removeFromCart: payload,
      };
    }
    case Types.CLEAR_CART_SUCCESS: {
      return {
        ...state,
        clearCartSuccess: payload,
      };
    }

    
    case Types.ADD_TO_CART_SUCCESS: {
      console.log(payload);
      return {
        ...state,
        AddToCartSuccess: payload,
      };
    }

    case Types.CHECK_OUT_SUCCESS: {
      // console.log(payload, "checkout success");
      return {
        ...state,
        checkOutSuccess: payload,
      };
    }
    case Types.PROMO_CODE_SUCCESS: {
      return {
        ...state,
        PromoCodeSuccess: payload,
      };
    }

    default: {
      return state;
    }
  }
}

