import React from 'react';
import { Stack } from "@mui/material";
import OurFeatures from 'components/Molecules/LandingPage/Features/OurFeatures';
import GetInTouch from 'components/Molecules/LandingPage/Home/GetInTouch';

const FeaturesTemplate = () => {
    return (

        <Stack gap={10}>
          <OurFeatures/>
          <GetInTouch/>
        </Stack>
      );

};

export default FeaturesTemplate;
