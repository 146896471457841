import { Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import lockIcon from "../../../../Assets/Images/lockIcon.svg";
import videoIcon from "../../../../Assets/Images/videoIcon2.svg";
import quizIcon from "../../../../Assets/Images/quizIcon.svg";
import readingIcon from "../../../../Assets/Images/readingIcon.svg";
import { Box } from "@mui/system";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import NoDataYet from "../../../Atoms/NoDataYet";

export default function AccordionDetails({
  studentCoursesDetails,
  sessions,
  sections,
  handlePreviewClick,
}) {
  const [assignment, setAssignment] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [readingData, setReadingData] = useState([]);
  const [quizData, setQuizData] = useState([]);
  const { blackTwo, warmGray3, warmGray4 } = useThemePalette();

  useEffect(() => {
    setAssignment(sessions?.map((session) => session?.assignments)?.flat(1));
    setQuizData(sessions?.map((session) => session?.quizzes).flat(1));
    setVideosData(sessions?.filter((video) => video?.video_url?.length >= 1));
    setReadingData(sessions?.filter((reading) => reading?.content_type === "book"));
    // setReadingData(sessions?.filter((reading) => reading?.content_type === 'book'));
  }, [sessions]);

  const courseData = [
    {
      title: `${videosData?.length} Video`,
      data: videosData,
      icon: videoIcon,
      type: "video",
    },
    {
      title: `${readingData?.length} Reading`,
      data: readingData,
      icon: readingIcon,
      type: "book",
    },
    {
      title: `${quizData?.length} Quiz`,
      data: quizData,
      icon: quizIcon,
      type: "quiz",
    },
    {
      title: `${assignment?.length} Assignment`,
      data: assignment,
      icon: quizIcon,
      type: "assignment",
    },
  ];

  return (
    <>
      <Stack ml={{ md: "40px" }} mb="28px" justifyContent="space-between">
        {videosData?.length +
          readingData?.length +
          quizData?.length +
          assignment?.length ===
          0 && <NoDataYet data="Sessions" />}
        {courseData?.map((course, index) => {
          if (course?.data?.length > 0)
            return (
              <Stack
                key={index}
                direction="column"
                width={{ lg: "100%", md: "95.5% !important" }}
                mx={{ md: "0", xs: "15px" }}
                py={{ lg: "32px", xs: "10px" }}
              >
                <Stack direction="row">
                  <img
                    style={{ marginTop: "3px" }}
                    src={course?.icon}
                    alt="video icon"
                  />
                  <Typography
                    width={{ sm: "100%", md: "409px" }}
                    ml="8px"
                    sx={{ color: blackTwo }}
                    fontSize="18px"
                    fontWeight="700"
                  >
                    {course.title}
                  </Typography>
                </Stack>
                {course?.data?.map((quiz, i) => (
                  <Stack
                    ml="8px"
                    width="100%"
                    key={i}
                    direction="row"
                    mt="16px"
                    mb="33px"
                    justifyContent="space-between"
                    alignSelf="flex-start"
                  >
                    <Typography
                      minWidth={{ lg: "367px", xs: "50%" }}
                      mt="11px"
                      fontSize="15px"
                      color={warmGray3}
                      onClick={(_) => console.log(quiz?.expected_time)}
                    >
                      {quiz?.title}
                    </Typography>
                    <Typography mt="11px" fontSize="15px" color={warmGray3}>
                      {/* {course?.type === 'video' && dayjs(quiz?.expected_time).format('h:mm') } */}
                      {course?.type === "video" && quiz?.expected_time}
                    </Typography>

                    {studentCoursesDetails?.is_purchased_by_current_user ||
                    !studentCoursesDetails?.original_price ? (
                      <Box>
                        <Button
                          onClick={(_) =>
                            handlePreviewClick(quiz, course?.type)
                          }
                        >
                          Preview
                        </Button>
                      </Box>
                    ) : (
                      <img src={lockIcon} alt="lock" />
                    )}
                  </Stack>
                ))}
                <Divider style={{ width: "100%", color: warmGray4 }} />
              </Stack>
            );
        })}
      </Stack>
    </>
  );
}
