import { Typography } from '@mui/material'
import React from 'react'
import { useThemePalette } from '../../../common/hooks/useThemePalatte'

export default function CoursesDetailsBoxTitle({ title, mb, fontSize, letterSpacing, fontWeight }) {
    const { blackTwo } = useThemePalette()

    return <>
        <Typography
            color={blackTwo}
            fontSize={{ md: fontSize ? fontSize : '24px', xs: '18px' }}
            fontWeight={fontWeight ? fontWeight : 'bold'}
            mb={mb ? mb : '18px'}
            letterSpacing={letterSpacing}
        >
            {title}
        </Typography>
    </>
}
