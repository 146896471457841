import React, { useEffect, useState } from "react";
// import './e-book.css'
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentCoursesDetailsRequest,
  getStudentSectionsRequest,
} from "../../../services/Modules/Courses";
import {
  createBookMarkRequest,
  removeBookMarkRequest,
} from "../../../services/Modules/Ebook";
import EbookTemplate from "../../../Template/Courses/Ebook/EbookTemplate";
import { useThemePalette } from "../../../common/hooks/useThemePalatte";

const EbookPage = () => {
  const { course_id, section_id, session_id } = useParams();
  const [section, setSection] = useState([]);
  const [session, setSession] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [bookmarkedPages, setBookmarkedPages] = useState([]);
  const [isOnePage, setOnePage] = useState(true);
  const dispatch = useDispatch();
  const { primaryColor, blackTwo } = useThemePalette();
  const { bookMarks } = useSelector((state) => state?.bookMarks);
  const { sections, isLoading } = useSelector((state) => state?.courses);

  useEffect(() => {
    dispatch(getStudentSectionsRequest({ course_id }));
  }, []);

  useEffect(() => {
    const filter = sections?.find((data) => data?.section_id == section_id);
    setSection(filter);
  }, [sections]);

  useEffect(() => {
    if (section) {
      const filter = section?.sessions?.find(
        (data) => data?.course_session_id == session_id
      );
      setSession(filter);
    }
  }, [section]);

  useEffect(() => {
    if (course_id) dispatch(getStudentCoursesDetailsRequest({ course_id }));
  }, [course_id]);

  /** TO DISABLE SCREEN CAPTURE **/
  document.addEventListener("keyup", (e) => {
    e.preventDefault();
    if (e.key === "PrintScreen") {
      navigator.clipboard.writeText("");
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });

  /** TO DISABLE PRINTS WHIT CTRL+P **/
  document.addEventListener("keydown", (e) => {
    if (e.ctrlKey && e.key == "p") {
      alert("This section is not allowed to print or export to PDF");
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });

  const [searchParam] = useSearchParams();
  const subjectName = searchParam?.get("subject_name");
  const subjectId = searchParam?.get("subject_id");

  const breadcrumb = [
    { title: "Home", path: "/", active: true, activeColor: primaryColor },
    {
      title: "Course details",
      path: `/website/courses-details/${course_id}?subject_name=${subjectName}&subject_id=${subjectId}`,
      active: true,
      activeColor: primaryColor,
    },
    {
      title: section?.title,
      path: `/website/courses-details/${course_id}`,
      active: false,
      notActive: blackTwo,
    },
  ];

  const isPageBookmarked = (pageNumber) => {
    let bookmarkedPagesData = bookmarkedPages;
    const filter = bookmarkedPagesData?.filter(
      (val) => val?.page_number == pageNumber
    );
    if (filter?.length != 0) return true;
    else return false;
  };

  const createBookmark = async (page) => {
    let formData = {
      book_bookmark: {
        page_number: page,
        name: "book_bookmark",
      },
    };
    dispatch(
      createBookMarkRequest({ book_id: session?.book?.id, body: formData })
    );
  };

  const removeBookmark = async (page) => {
    dispatch(
      removeBookMarkRequest({
        book_id: page[0]?.book_id,
        book_bookmark_id: page[0]?.id,
      })
    );
  };

  useEffect(() => {
    bookMarks && setBookmarkedPages(bookMarks);
  }, [bookMarks]);

  return (
    <EbookTemplate
      scale={scale}
      setScale={setScale}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      numPages={numPages}
      setNumPages={setNumPages}
      setBookmarkedPages={setBookmarkedPages}
      bookmarkedPages={bookmarkedPages}
      session={session}
      isOnePage={isOnePage}
      breadcrumb={breadcrumb}
      section={section}
      setOnePage={setOnePage}
      isPageBookmarked={isPageBookmarked}
      removeBookmark={removeBookmark}
      createBookmark={createBookmark}
      isLoading={isLoading}
    />
  );
};

export default EbookPage;
