import {
    Container,
    FormControl,
    MenuItem,
    Select,
    ListSubheader,
    TextField,
    InputAdornment
  } from "@mui/material";
  import React, { useState, useMemo } from "react";
  import { getCountryCallingCode } from "react-phone-number-input";
  import { getCountries } from "react-phone-number-input";
  import TextFieldInput from "../TextField/TextFieldInput";
  import "./PhoneInput.css";
  import SearchIcon from "@mui/icons-material/Search";
  
  function PhoneInput({
    setPhone,
    clickEnter,
    placeholder,
    value,
    labels,
    onChange,
    select = true,
    codePlus,
    id,
    name,
    inputValue,
    error,
    inputBg,
    displayCountry,
    FormControlWidth,
    helperText,
  }) {
    const containsText = (text, searchText) =>
      text?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1;
    const allOptions = getCountries();
    const [searchText, setSearchText] = useState("");
    const displayedOptions = useMemo(
      () => allOptions?.filter((option) => containsText(labels[option], searchText)),
      [searchText]
    );
    return <>
      <Container style={{ display: "flex" }} fullWidth disableGutters>
        {select && <FormControl
          sx={{ width: FormControlWidth }}
          className={displayCountry ? '' :
            localStorage.getItem("lang") == "ar"
              ? "code_selectAR"
              : "code_selectEN"
          }
        >
          <Select
            value={value}
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            renderValue={_ => codePlus ? `+${value}` : value}
            onChange={(e) => onChange(e?.target?.value)}
            onClose={() => setSearchText("")}
          >
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                width="100%"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
  
                onChange={(e) => setSearchText(e?.target?.value)}
                onKeyDown={(e) => {
                  if (e?.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions?.map((country) =>
              displayCountry ?
                <MenuItem key={country} value={labels[country]}>
                  {labels[country]}
                </MenuItem>
                :
                <MenuItem key={country} value={getCountryCallingCode(country)}>
                  {labels[country]} +{getCountryCallingCode(country)}
                </MenuItem>
            )}
  
          </Select>
        </FormControl>}
  
        {!displayCountry && <TextFieldInput
          bgColor={inputBg}
          error={error}
          helperText={helperText}
          name={name}
          id={id}
          value={inputValue}
          fullWidth
          placeholder={placeholder}
          onChange={setPhone}
          className={"mb-20px"}
          onKeyDown={(e) => {
            if (e.key === "Enter") clickEnter();
          }}
        />}
      </Container>
    </>
  }
  
  export default PhoneInput;
  