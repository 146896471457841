import { Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import VideoContent from "../VideoContent/VideoContent";
import ChapterQuiz from "../QuizContent/ChapterQuiz";
import { useSearchParams } from "react-router-dom";
import AssignmentContent from "../AssignmentContent/AssignmentContent";

export default function ChapterRightSide({ sessionChossen }) {
  const [content, setContent] = useState("");
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  useEffect(() => {
    if (type === "video" || type === "video_and_text") {
      setContent(<VideoContent sessionChossen={sessionChossen} />);
    } else if (type === "quiz") {
      setContent(<ChapterQuiz quiz={sessionChossen} />);
    } else if (type === "assignment") {
      setContent(<AssignmentContent sessionChossen={sessionChossen} />);
    }
  }, [sessionChossen]);

  return (
    <Stack
      sx={{
        px: 2,
        py: 2,
        width: "100% ",
      }}
    >
      {content}
    </Stack>
  );
}
