import Types from "./Types";

const COURSES_INIT_STATE = {
  courses: [],
  error: false,
  sections: [],
  studentCoursesDetails: [],
  guestCourseDetails: [],
  sessionDetails: {},
  coursesList: [],
  sessions: [],
  submittedAssignment: {},
  studentEvaluation: [],
  startQuiz: [],
  answerQuiz: [],
  clearQuiz: [],
  submitQuiz: {},
  listAttemptsQuiz: [],
  studentSavedList: [],
  addCourseToSavedList: {},
  removeCourseFormSavedListFromProduct: {},
  studentCoursesList: [],
  allCourseReviews: [],
  addReview: [],
  isLoading: {
    type: "",
    loading: false,
  },
  reviews: [],
  sessionCompleted: {},
};

export function coursesReducer(state = COURSES_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ALL_COURSES_SUCCESS: {
      console.log(payload,'as');
      return {
        ...state,
        courses: payload?.data?.courses,
      };
    }
    case Types.COURSES_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.GET_ALL_COURSES_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    case Types.GET_STUDENT_COURSES_DETAILS_SUCCESS: {
      return {
        ...state,
        studentCoursesDetails: payload?.data,
      };
    }
    case Types.GET_GUEST_COUESE_DETAILS_SUCCESS: {
      return {
        ...state,
        guestCourseDetails: payload?.data,
      };
    }

    case Types.GET_STUDENT_SECTIONS_SUCCESS: {
      return {
        ...state,
        sections: payload?.data,
      };
    }
    case Types.GET_STUDENT_SESSIONS_SUCCESS: {
      return {
        ...state,
        sessions: payload?.data,
      };
    }

    case Types.GET_STUDENT_SESSION_DETAILS_SUCCESS: {
      return {
        ...state,
        sessionDetails: payload?.data,
      };
    }

    case Types.GET_ALL_REVIEWS_SUSSESS: {
      return {
        ...state,
        reviews: payload?.data,
      };
    }

    case Types.SUBMIT_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        submittedAssignment: payload?.data?.assignment_delivery,
      };
    }

    case Types.GET_STUDENT_EVALUATION_SUCCESS: {
      return {
        ...state,
        studentEvaluation: payload,
      };
    }

    case Types.GET_COURSES_LIST_SUCCESS: {
      return {
        ...state,
        coursesList: payload?.data,
      };
    }

    case Types.GET_COURSES_LIST_ERROR: {
      return {
        ...state,
        errors: payload,
      };
    }
    case Types.GET_STUDENT_ASSIGNMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        sessionDetails: payload?.data,
      };
    }

    case Types.GET_STUDENT_QUIZ_DETAILS_SUCCESS: {
      return {
        ...state,
        sessionDetails: payload?.data,
      };
    }

    case Types.STUDENT_START_QUIZ_SUCCESS: {
      return {
        ...state,
        startQuiz: payload?.data,
      };
    }

    case Types.STUDENT_ANSWER_QUIZ_SUCCESS: {
      return {
        ...state,
        answerQuiz: payload?.data,
      };
    }

    case Types.CLEAR_STUDENT_ANSWER_QUIZ_SUCCESS: {
      return {
        ...state,
        clearQuiz: payload?.data,
      };
    }

    case Types.STUDENT_SUBMIT_QUIZ_SUCCESS: {
      return {
        ...state,
        submitQuiz: payload?.data,
      };
    }

    case Types.GET_STUDENT_QUIZ_ATTEMPTS_SUCCESS: {
      return {
        ...state,
        listAttemptsQuiz: payload?.data,
      };
    }

    case Types.COMPLETE_SESSION_SUCCESS: {
      return {
        ...state,
        sessionCompleted: payload,
      };
    }

    case Types.GET_STUDENT_SAVED_LIST_SUCCESS: {
      return {
        ...state,
        studentSavedList: payload?.data,
      };
    }

    case Types.ADD_COURSE_TO_SAVED_LIST_SUCCESS: {
      return {
        ...state,
        addCourseToSavedList: payload?.data,
      };
    }

    case Types.REMOVE_COUESE_FORM_SAVED_LIST_FORM_PRODUCT_SUCCESS: {
      return {
        ...state,
        removeCourseFormSavedListFromProduct: payload?.data,
      };
    }

    case Types.GET_STUDENT_COURSES_LIST_SUCCESS: {
      return {
        ...state,
        studentCoursesList: payload?.data,
      };
    }

    case Types.SHOW_ALL_REVIEWS_FOR_CURRENT_COURSE_SUSSESS: {
      return {
        ...state,
        allCourseReviews: payload?.data,
      };
    }

    case Types.ADD_REVIEW_SUSSESS: {
      return {
        ...state,
        addReview: payload?.data,
      };
    }

    default: {
      return state;
    }
  }
}
