import { Box } from "@mui/material";
import React, { useState } from "react";
import LabelSvg from "../LabelSvg";
import { useDispatch } from "react-redux";
import {
  addCourseToSavedListRequest,
  removeCourseFromSavedListFromProductRequest,
} from "../../../../services/Modules/Courses";

export default function CardImg({ imgSrc, product_id, savedList }) {
  const [labelStyle, setLabelStyle] = useState({
    color: "#fff",
    bgcolor: "rgba(0, 0, 0, .2)",
  });

  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  return (
    <Box position="relative" width="100%" height="100%">
      <Box>
        <img
          src={imgSrc}
          alt=""
          className="w-100 cardImg"
          style={{ maxHeight: "170px", width: "100%" }}
        />
      </Box>
      {/* ------------------------ label icon div ----------------------- */}
      <Box
        sx={{ cursor: "pointer" }}
        position="absolute"
        top="16px"
        right="16px"
        onClick={() => {
          setIsActive(!isActive);
          savedList(product_id)
            ? dispatch(removeCourseFromSavedListFromProductRequest(product_id))
            : dispatch(addCourseToSavedListRequest({ product_id }));
        }}
      >
        <LabelSvg
          isActive={isActive}
          height={34}
          width={34}
          bgcolor={labelStyle?.bgcolor}
          color={labelStyle?.color}
        />
      </Box>
    </Box>
  );
}
