import React from "react";
import PaperComponent from "../../components/Atoms/Paper/PaperComponent";
import { Button, Divider, Stack } from "@mui/material";
import { useThemePalette } from "../../common/hooks/useThemePalatte";
import {
  changePasswodModal,
  deleteAccountModal,
} from "../../services/Modules/Models/Actions";
import { useDispatch } from "react-redux";

export default function SettingTemplate() {
  const { blackTwo } = useThemePalette();
  const dispatch = useDispatch();
  return (
    <>
      <PaperComponent py="40px" px="24px">
        <Stack>
          <Button
            sx={{
              width: "fit-content",
              fontWeight: 500,
              fontSize: "20x",
              color: blackTwo,
              mb: "20px",
            }}
            onClick={(_) => dispatch(changePasswodModal())}
          >
            Change password
          </Button>
          <Divider />
          <Button
            sx={{
              width: "fit-content",
              fontWeight: 500,
              fontSize: "20x",
              color: blackTwo,
              mt: "20px",
            }}
            onClick={(_) => dispatch(deleteAccountModal())}
          >
            Delete account
          </Button>
        </Stack>
      </PaperComponent>
    </>
  );
}
