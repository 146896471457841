import React, { useEffect, useState } from "react";
import ModelBox from "../../Atoms/Model/ModalBox";
import { useDispatch, useSelector } from "react-redux";
import { studentReviewModel } from "../../../services/Modules/Models/Actions";
import { addReviewRequest, getStudentCoursesDetailsRequest, getStudentCoursesListRequest, showAllReviewsForCurrentCourseRequest } from "../../../services/Modules/Courses";
import { Box, Button, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useThemePalette } from "../../../common/hooks/useThemePalatte";
import TextArea from "../../Atoms/Input/TextArea/TextArea";
import CardRating from "../../Atoms/SharedComponents/Cards/CardRating";
import { useSearchParams } from "react-router-dom";

export default function StudentReviewForm() {
    const dispatch = useDispatch();
    const { warmGray, transparentPrimaryColor } = useThemePalette();
    const { isStudentReviewModel } = useSelector((state) => state?.model);
    const [value, setValue] = useState();
    const [searchParams, setSearchParams] = useSearchParams()
    const course_id = searchParams.get('course_id')
    const [ratingValue, setRatingValue] = useState();
    const { studentCoursesDetails } = useSelector((state) => state?.courses);

    useEffect(() => {
        course_id && dispatch(getStudentCoursesDetailsRequest({ course_id }));
    }, [course_id]);
    useEffect(() => {
        if (isStudentReviewModel == false) {
            searchParams.delete('course_id')
            setSearchParams(searchParams)
        }
    }, [isStudentReviewModel])

    const handleChange = (e, newValue) => {
        setRatingValue(newValue)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(addReviewRequest({ product_id: studentCoursesDetails?.product_id, body: { "product_review": { "rating": ratingValue, "comment": value } } }))
    }
    return (
        <>
            <ModelBox
                openVar={isStudentReviewModel}
                closeFunc={() => {
                    dispatch(studentReviewModel());
                    setRatingValue()
                    searchParams.delete('course_id')
                    setSearchParams(searchParams)
                }}
                px="30px"
            >
                <form onSubmit={e => handleSubmit(e)}>
                    <Stack rowGap="24px">
                        <Typography color={warmGray} fontSize="18px"> How would you rate{" "}
                            <Typography component="span" sx={{ fontWeight: "700", fontSize: "18px" }} >
                                {studentCoursesDetails?.title}
                            </Typography>
                            {" "}
                            course?{" "}
                        </Typography>
                        <CardRating
                            iconEmptyColor="#E1E1E1"
                            iconSize={"large"}
                            final_rating_from_review={studentCoursesDetails?.final_rating_from_reviews}
                            handleChangeRatingChange={handleChange}
                            ratingValue={ratingValue}
                            defaultValue={studentCoursesDetails?.final_rating_from_reviews}
                            icon={
                                <StarIcon
                                    size="large"
                                    sx={{
                                        fontSize: "42px",
                                        "& .MuiRating-iconEmpty": {
                                            color: "secondary.main",
                                        },
                                    }}
                                />
                            }
                            emptyIcon={
                                <StarIcon
                                    style={{ opacity: 0.55 }}
                                    size="large"
                                    sx={{ fontSize: "42px" }}
                                />
                            }
                        />
                    </Stack>

                    <Box className="student-review" mt="40px">
                        <Typography component="span">Write your feedback </Typography>
                        <Typography sx={{ opacity: "50%" }} component="span">
                            (Optional)
                        </Typography>
                        <TextArea
                            style={{
                                borderRadius: "8px",
                                backgroundColor: "#E1E1E1",
                                border: "0 !important",
                            }}
                            value={value}
                            onchange={(val) => setValue(val)}
                        />
                    </Box>
                    <Button
                        disabled={ratingValue >= 0.5 || value?.length >= 1 ? false : true}
                        sx={{
                            mt: "40px",
                            mb: "10px",
                            ":disabled": {
                                color: "#fff !important",
                                backgroundColor: transparentPrimaryColor,
                            },
                        }}
                        variant="contained"
                        type="submit"
                        fullWidth
                    >
                        Submit
                    </Button>
                </form>
            </ModelBox>
        </>
    );
}
