import React from 'react'
import HomeHeader from '../../Molecules/Home/HomeHeader'
import Learning from '../../Molecules/Home/Learning'
import TopCategories from '../../Molecules/Home/TopCategories'
import LearningBenfits from '../../Molecules/Home/LearningBenfits'
import MostPopular from '../../Molecules/Home/MostPopular'
import OurClients from '../../Molecules/Home/OurClients'
import PopularCertificates from '../../Molecules/Home/PopularCertificates'
import WhatsIcon from '../../Atoms/SharedComponents/WhatsIcon'

export default function Home({
    loading,
    getSubjectRequest,
    fetchAllSubjectsRequest,
    fetchFilteredSubjectsRequest,
    isActive,
    setIsActive,
    finalData,
    categoriesSubjects,
    handleClick,
    clickedSubject,
    coursesArr,
    coursesLoading,
    title,
    subTitle,
    studentSavedList,
    setClickedSubject
}) {
    return <>
        <HomeHeader />
        <Learning />
        <TopCategories
            loading={loading}
            getSubjectRequest={getSubjectRequest}
            fetchAllSubjectsRequest={fetchAllSubjectsRequest}
            fetchFilteredSubjectsRequest={fetchFilteredSubjectsRequest}
            isActive={isActive}
            setIsActive={setIsActive}
            finalData={finalData}
            categoriesSubjects={categoriesSubjects}
            handleClick={handleClick}
            clickedSubject={clickedSubject}
            studentSavedList={studentSavedList}
            setClickedSubject={setClickedSubject}
        />
        <LearningBenfits />
        <MostPopular
            title={title}
            subTitle={subTitle}
            coursesArr={coursesArr}
            coursesLoading={coursesLoading}
        />
        <OurClients />
        <PopularCertificates />
        <WhatsIcon />
    </>
}
