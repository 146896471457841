import { Grid, Stack, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChapterLeftSide from "./ChapterLeftSide";
import ChapterRightSide from "./ChapterRightSide";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VideoSkeleton from "../../../Atoms/SharedComponents/VideoSkeleton";
import Breadcrumb from "../../../Atoms/Breadcrumbs/Breadcrumb";
import SessionPagination from "../../../Molecules/Courses/CourseDetails/SessionPagination";
// import { getStudentAssignmentDetailsRequest, getStudentQuizDetailsRequest, getStudentSessionDetailsRequest } from "../../../../Modules/Courses";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import {
  getStudentAssignmentDetailsRequest,
  getStudentQuizDetailsRequest,
  getStudentSessionDetailsRequest,
} from "../../../../services/Modules/Courses";

export default function ChapterDisplaying({ studentCoursesDetails }) {
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const [preBtnDisabled, setpreBtnDisabled] = useState(true);
  const { course_id, section_id, session_id } = useParams();
  const { primaryColor, blackTwo, lightGreen } = useThemePalette();
  const dispatch = useDispatch();
  const { sessionDetails } = useSelector((state) => state.courses);

  const [searchParam] = useSearchParams();
  const subjectName = searchParam?.get("subject_name");
  const subjectId = searchParam?.get("subject_id");
  const type = searchParam?.get("type");

  useEffect(() => {
    if (course_id && section_id && session_id)
      if (type === "video" || type === "video_and_text") {
        dispatch(
          getStudentSessionDetailsRequest({ course_id, section_id, session_id })
        );
        // setData(sessionDetails)
      } else if (type === "assignment") {
        dispatch(
          getStudentAssignmentDetailsRequest({
            course_id,
            assignment_id: session_id,
          })
        );
        // setData(sessionDetails)
      } else if (type === "quiz") {
        dispatch(
          getStudentQuizDetailsRequest({ course_id, quiz_id: session_id })
        );
        // setData(sessionDetails)
      }
  }, [course_id, section_id, session_id]);

  const changeSession = (i) => {
    // 1 for next , 0 for previous
    // if (i) {
    //     setpreBtnDisabled(false)
    //     if (videoChossen < accordianData.length - 1) {
    //         setVideoChossen(videoChossen + 1)
    //     }
    //     if (videoChossen == accordianData.length - 2) {
    //         setNextBtnDisabled(true)
    //     }
    // }
    // else {
    //     setNextBtnDisabled(false)
    //     if (!videoChossen == 0) {
    //         setVideoChossen(videoChossen - 1)
    //     }
    //     if (videoChossen == 1) {
    //         setpreBtnDisabled(true)
    //     }
    // }
  };

  const breadcrumb = [
    { title: "Home", path: "/", active: true, activeColor: primaryColor },
    {
      title: "Course details",
      path: `/website/courses-details/${course_id}?subject_name=${subjectName}&subject_id=${subjectId}`,
      active: true,
      activeColor: primaryColor,
    },
    {
      title: "Chapter 1",
      path: `/website/courses-details/${course_id}/${section_id}/${session_id}?type=${type}}`,
      active: false,
      notActive: blackTwo,
    },
  ];
  return (
    <VideoSkeleton>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Stack sx={{ width: 3 / 4 }}>
              {/* <Breadcrumb /> */}
              {breadcrumb && (
                <Breadcrumb data={breadcrumb} iconColor={lightGreen} />
              )}
            </Stack>
            <Stack sx={{ width: 1 / 4 }}>
              <SessionPagination
                changeSession={changeSession}
                disableNext={nextBtnDisabled}
                disablePre={preBtnDisabled}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Stack flexDirection={{ sm: "column", md: "row" }}>
        <Box width={{ sm: "100%", md: "30%" }} bgcolor="#F8F8F8">
          <ChapterLeftSide
            studentCoursesDetails={studentCoursesDetails}
            sessionChossen={sessionDetails}
            subjectId={subjectId}
            subjectName={subjectName}
          />
        </Box>
        <Box width={{ sm: "100%", md: "70%" }}>
          <ChapterRightSide sessionChossen={sessionDetails} />
        </Box>
      </Stack>
    </VideoSkeleton>
  );
}
