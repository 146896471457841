import React from 'react';
import { Container } from '@mui/material';

const CustomContainer = ({ children }) => {
  return (
    <Container
      sx={{
        maxWidth: { xl: "xl", md: "lg" },
      }}
      disableGutters
    >
      {children}
    </Container>
  );
};

export default CustomContainer;
