import ContactUsTemplate from "Template/LandingPageTemplate/Contact/ContactUsTemplate";
import React from "react";
import locationone from '../../../Assets/Images/Riyadh.png';
import locationtwo from '../../../Assets/Images/uae.png';
import locationthree from '../../../Assets/Images/egypt.png';

export default function ContactUsPage() {
    const ContactData = [
        {
          img: locationone,
          points: [
            "Qurtuba district, Eldmam Street, Qurtuba Square, 3rd-floor office no.44, Riyadh , KSA ",
            "(+966) 530411026",
            "(+966) 530411026",
            " sales@inovaeg.com",
          ]
        },
        {
          img: locationtwo,
          points: [
            " VD- Ground Floor Accelerator Building Masdar City, Abu Dhabi, UAE",
            "(+971) 564748843",
            "(+971) 564748843",
            "sales@inovaeg.com",
          ]
        },
        {
          img: locationthree ,
          points: [
            "Cairo: 30, Al Manteqah as Sadesah, Nasr City.",
            "Alexandria: 669 Gamal Abd El-Nasir Rd San Stefano.",
            "(+2)01025065179",
            "(+2)01025065179​",
            "sales@inovaeg.com",
            
          ],
        },
      ];
  
    return (
    <div>
        <ContactUsTemplate ContactData={ContactData}/>
    </div>
  );
};


