import Types from "./Types";

const COURSES_INIT_STATE = {
    uploadFile: [],
};

export function uploadFileReducer(state = COURSES_INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.UPLOAD_FILE_SUCCESS: {
            console.log(payload,'payload');
            return {
                ...state,
                uploadFile: payload,
            };
        }
        default: {
            return state;
        }
    }
}
