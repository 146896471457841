import Types from "./Types";

const AUTH_INIT_STATE = {
  userData: {},
  token: null,
  sendResetPasswod: {},
  isEmailExist: false,
  emailOtp: "",
  isEmailVerified: false,
  resetPasswordByEmail: {},
  resendConfirmationCode: "",
  logOut: {},
  changePassword: {},
  isLoading: {
    type: "",
    loading: false,
  },
};

export function authReducer(state = AUTH_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SIGN_UP_SUCCESS: {
      return {
        ...state,
        userData: payload?.data,
        token: payload?.data?.token,
      };
    }
    case Types.AUTH_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.LOGIN_SUCCESS: {
      return {
        ...state,
        userData: payload?.data,
        token: payload?.data?.token,
      };
    }

    case Types.SEND_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        sendResetPasswod: payload,
      };
    }

    case Types.CHECK_EMAIL_EXISTS_SUCCESS: {
      return {
        ...state,
        isEmailExist: payload,
      };
    }

    case Types.SEND_EMAIL_OTP_SUCCESS: {
      return {
        ...state,
        emailOtp: payload,
      };
    }

    case Types.VERIFY_EMAIL_OTP_SUCCESS: {
      return {
        ...state,
        isEmailVerified: payload,
      };
    }

    case Types.RESET_PASSWORD_BY_EMAIL_SUCCESS: {
      return {
        ...state,
        resetPasswordByEmail: payload,
      };
    }

    case Types.RESEND_CONFIEMATION_CODE_SUCCESS: {
      return {
        ...state,
        resendConfirmationCode: payload,
      };
    }

    case Types.LOG_OUT_SUCCESS: {
      return {
        ...state,
        logOut: payload,
      };
    }

    case Types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePassword: payload,
      };
    }

    default: {
      return state;
    }
  }
}
