import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import VideoOverview from "./VideoOverview";

export default function VideoViewTabs({ sessionChossen }) {
  const [value, setValue] = useState("1");

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const tabsData = [
    { label: "Overview", value: "1" },
    { label: "Discussion form", value: "2" },
  ];

  const tabPanelData = [
    { value: "1", panel: <VideoOverview sessionChossen={sessionChossen} /> },
    { value: "2", panel: "Discussion form" },
  ];

  return (
    <>
      <TabContext value={value}>
        <Paper
          elevation={0}
          sx={{ px: { md: "80px", xs: "5px" }, borderRadius: "10px" }}
        >
          <TabList
            aria-label="Tabs exmple"
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{ pt: "10px" }}
          >
            {tabsData?.map((tab) => (
              <Tab
                key={tab.value}
                sx={{ textTransform: "capitalize", fontWeight: "600" }}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </TabList>
        </Paper>
        <Box mx={{ md: "0px", xs: "0px" }} my="4px">
          {tabPanelData?.map((tabPanel) => (
            <TabPanel
              sx={{ p: 0, mt: "38px" }}
              key={tabPanel.value}
              value={tabPanel.value}
            >
              {tabPanel.panel}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </>
  );
}
