import React from 'react';
import { Stack, Box, Typography, Button} from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import AboutUsImg from "../../../../Assets/Images/aboutimg.png";
import CustomContainer from 'components/Atoms/SharedComponents/LandingPage/CustomContainer';

const About = ({ subtitle }) => {
  const { blackTwo } = useThemePalette();
 

  return (
    <CustomContainer>
      <Stack
        direction="row"
        alignItems="stretch"
        justifyContent="space-between"
        py={15}
      >
        <Stack direction="column"  justifyContent="space-between"  sx={{ width: '45%' }}>
          <Box>
            <Typography variant='h2'
                marginBottom='1rem'
                color='primary.main'
                fontSize='40px'
                fontWeight='600'
                marginRight='50px'>
                About Us       
            </Typography>
          </Box>
          <Typography variant="body1" color={blackTwo} sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '42px'}}>
                Inova is a mobile app and web development company, headquartered in Alexandria, Egypt. We have developed an extensive, rich and interactive experience in mobile app development, mobile games development and enterprise web apps since 2011. Our specialty is mobile app development; we support various mobile development SDKs and we have developed more than 60.
          </Typography>
          <Button variant="contained" color="primary"  sx={{ width: 'fit-content',  padding: '16px 26px', borderRadius: '10px' }}>Get Started</Button>
        </Stack>

        <Stack direction="column" alignItems="center"  justifyContent="space-between" sx={{ width: '45%' }}>
          <img
            src={AboutUsImg}
            alt="about-us"
            sx={{ marginTop: '100px', ml: '100px', mb: '50px' }}
          />  
        </Stack> 
      </Stack>
      </CustomContainer>
  );
};

export default About;
