import React from "react";
import ChapterDisplaying from "../../../components/Organisms/Courses/CourseDetails/ChapterDisplaying";

export default function CourseDetailsTemplate({ studentCoursesDetails }) {
  return (
    <ChapterDisplaying
      studentCoursesDetails={studentCoursesDetails}
      section_id={56}
      course_session_id={157}
    />
  );
}
