import { Box, Button, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useThemePalette } from "../../../common/hooks/useThemePalatte";
import IconComponent from "../../Atoms/SharedComponents/IconComponent";
import shoppingCard from "../../../Assets/Images/feather-shopping-cart.svg";
import notificationsIcon from "../../../Assets/Images/notificationsIcon.svg";
import profilePicPlaceHolder from "../../../Assets/Images/profilePicPlaceHolder.svg";
import AvatarData from "../../Atoms/SharedComponents/AvatarData";

export default function UserMenu({ menu_items, display }) {
  const { whiteTwo, blackTwo, transparentGray, secondryColor } =
    useThemePalette();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleCart = () => {
      navigate(`user/cart`);
    };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title='Account settings'>
          <Stack
            direction={{ md: "row", xs: "column-reverse" }}
            px={{ md: "0", xs: "20px" }}
            display={{ xs: display, md: "flex" }}
            justifyContent='center'
            alignItems='center'
          >
            <IconComponent
              iconSrc={notificationsIcon}
              style={{ width: "19px", height: "27.45px" }}
            />
            <IconComponent
              iconSrc={shoppingCard}
              style={{ width: "22.56px", height: "27.45px" }}
              onClick={() => handleCart()}
            />
            <Box onClick={handleClick} sx={{ cursor: "pointer" }} px='12px'>
              <AvatarData
                avatarImg={profilePicPlaceHolder}
                avatarBgColor='rgba(211, 211, 211, 0.62)'
              />
            </Box>
          </Stack>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          borderRadius: "10px !important",
          "& .MuiButtonBase-root ": {
            backgroundColor: whiteTwo,
            // borderRadius: "10px !important",
          },
        }}
        PaperProps={{
          elevation: 0,
          borderRadius: "10px !important",
          sx: {
            overflow: "visible",
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            mt: 1.5,
            borderRadius: "10px !important",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 22,
              width: 10,
              height: 10,
              bgcolor: whiteTwo,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menu_items?.map((item, i) => (
          <MenuItem
            key={i}
            sx={{
              px: "30px",
              bgcolor: whiteTwo,
              py: "10px",
              borderBottom:
                i == menu_items.length - 1
                  ? ""
                  : `1px solid ${transparentGray}`,
              borderRadius:
                i == 0
                  ? "10px 10px 0 0"
                  : i == menu_items.length - 1
                  ? "0 0 10px 10px"
                  : "",
            }}
            onClick={() => {
              handleClose();
              item?.fun ? item.fun() : navigate(item.path);
            }}
          >
            <Stack
              flexDirection={"row"}
              gap={"9px"}
              justifyContent='center'
              alignItems={"center"}
            >
              <img src={item?.icon} width='23px' height='17px' alt='icon' />
              <Button
                variant='text'
                disableRipple
                sx={{
                  fontWeight: 500,
                  fontSize: "17px",
                  p: 0,
                  color: pathname == item.path ? secondryColor : blackTwo,

                  bgcolor: "transparent",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }}
              >
                {item.title}
              </Button>
            </Stack>
          </MenuItem>
        ))}
      </Menu>

      {/* <Box sx={{ position: 'relative' }}>
            <Tooltip title="Open settings">
                <Stack direction={{ md: 'row', xs: 'column-reverse' }} px={{ md: '0', xs: '20px' }} display={{ xs: display, md: "flex" }} justifyContent='center' alignItems='center' >
                    <IconComponent iconSrc={notificationsIcon} style={{ width: '19px', height: '27.45px' }} />
                    <IconComponent iconSrc={shoppingCard} style={{ width: '22.56px', height: '27.45px' }} />
                    <Box onClick={handleOpenUserMenu} sx={{ cursor: 'pointer' }} px='12px'>
                        <AvatarData avatarImg={profilePicPlaceHolder} avatarBgColor='rgba(211, 211, 211, 0.62)' />
                    </Box>
                </Stack >
            </Tooltip>

            <Menu
                sx={{
                    mt: "60px", borderRadius: "10px",
                    "& .MuiPaper-root": {
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        left: { lg: '70% !important' },
                        // top: { lg: '2.3% !important' }
                    }
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {menu_items?.map((item, i) => (
                    <MenuItem
                        key={i}
                        sx={{ paddingX: "24px", bgcolor: whiteTwo, py: '10px', borderBottom: i == menu_items.length - 1 ? '' : `1px solid ${transparentGray}` }}
                        onClick={() => {
                            handleCloseUserMenu();
                            item?.fun ? item.fun() : navigate(item.path);
                        }}
                    >
                        <Stack flexDirection={"row"} gap={"9px"} justifyContent='center' alignItems={"center"}>
                            <img src={item?.icon} width='23px' height='17px' alt='icon' />
                            <Button
                                variant="text"
                                disableRipple
                                sx={{
                                    fontWeight: 500,
                                    fontSize: "18px",
                                    p: 0,
                                    color: blackTwo,
                                    bgcolor: "transparent",
                                }}
                            >
                                {item.title}
                            </Button>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </Box > */}
    </>
  );
}
