import {
  getAllCoursesSuccess,
  getAllReviewsSussess,
  getCoursesListSuccess,
  getQuizAttemptsSuccess,
  getStudentAssignmentDetailsSuccess,
  getStudentCoursesDetailsSuccess,
  getStudentEvaluationSuccess,
  getStudentQuizDetailsSuccess,
  getStudentSectionsSuccess,
  getStudentSessionDetailsSuccess,
  getStudentSessionSuccess,
  submitAssignmentSuccess,
  answerQuizSuccess,
  startQuizSuccess,
  submitQuizSuccess,
  clearQuizAnswerSuccess,
  getCourseDetailsToGuestSuccess,
  completeSessionSuccess,
  addCourseToSavedListSuccess,
  getStudentSavedListSuccess,
  removeCourseToSavedListFromProductSuccess,
  removeCourseToSavedListFromProfileSuccess,
  getStudentCoursesListSuccess,
  coursesLoading,
  showAllReviewsForCurrentCourseSuccess,
  addReviewSuccess,
  getListTransactionsSuccess,
  getListTransactionswithFilterSuccess,
} from "./Actions";
import { reduxRequest } from "../../../common/utils/reduxRequest";

export const getCoursesRequest = ({
  page_number,
  page_size,
  school_class_id,
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getAllCoursesSuccess,
    url: `courses?page_number=${page_number}&page_size=${page_size}&school_class_id=${school_class_id}`,
    loadingType: "allCourses",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  

  };
};

export const getStudentCoursesDetailsRequest = ({ course_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentCoursesDetailsSuccess,
    url: `my-learning/courses/${course_id}`,
    loadingType: "StudentCourses",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getGuestCourseDetailsRequest = ({ course_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getCourseDetailsToGuestSuccess,
    url: `courses/${course_id}`,
    loadingType: "GuestCourses",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentSectionsRequest = ({ course_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentSectionsSuccess,
    url: `my-learning/courses/${course_id}/materials/sections`,
    loadingType: "section",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentSessionsRequest = ({ course_id, section_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentSessionSuccess,
    url: `my-learning/courses/${course_id}/materials/sections/${section_id}/sessions`,
    loadingType: "sessions",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentSessionDetailsRequest = ({
  course_id,
  section_id,
  session_id,
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentSessionDetailsSuccess,
    url: `my-learning/courses/${course_id}/materials/sections/${section_id}/sessions/${session_id}`,
    loadingType: "sessionDetails",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getAllReviewsRequest = ({ product_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getAllReviewsSussess,
    url: `products/${product_id}/reviews`,
    loadingType: "reviwes",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getCoursesListRequest = ({
  page_number,
  page_size,
  school_class_id,
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getCoursesListSuccess,
    url: `courses?page[number]=${page_number}&page[size]=${page_size}&school_class_id=${school_class_id}`,
    loadingType: "courses list",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentAssignmentDetailsRequest = ({
  course_id,
  assignment_id,
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentAssignmentDetailsSuccess,
    url: `my-learning/courses/${course_id}/assignments/${assignment_id}?page=1`,
    loadingType: "assignmentDetails",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentQuizDetailsRequest = ({ course_id, quiz_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentQuizDetailsSuccess,
    url: `my-learning/courses/${course_id}/quizzes/${quiz_id}`,
    loadingType: "quizDetails",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const submitAssignmentRequest = ({
  course_id,
  assignment_id,
  ...variables
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: submitAssignmentSuccess,
    url: `my-learning/courses/${course_id}/assignments/${assignment_id}/deliver`,
    loadingType: "submitAssignment",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentEvaluationRequest = ({
  course_id,
  assignment_id,
  assignment_delivery_id,
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentEvaluationSuccess,
    url: `my-learning/courses/${course_id}/assignments/${assignment_id}/deliveries/${assignment_delivery_id}/evaluation`,
    method: "get",
    loadingType: "studentEvaluation",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const startQuizRequest = ({ course_id, quiz_id, ...variables }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: startQuizSuccess,
    url: `courses/${course_id}/quizzes/${quiz_id}/start_quiz`,
    loadingType: "startQuiz",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const answerQuizRequest = ({ course_id, quiz_id, ...variables }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: answerQuizSuccess,
    url: `courses/${course_id}/quizzes/${quiz_id}/save_answers`,
    loadingType: "answerQuiz",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const clearQuizAnswerRequest = ({
  course_id,
  quiz_id,
  ...variables
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: clearQuizAnswerSuccess,
    url: `courses/${course_id}/quizzes/${quiz_id}/clear_answer`,
    method: "put",
    loadingType: "clearAnswer",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const submitQuizRequest = ({ course_id, quiz_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: submitQuizSuccess,
    url: `my-learning/courses/${course_id}/quizzes/${quiz_id}/submit-and-evaluate`,
    loadingType: "submitAnswer",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getQuizAttemptsRequest = ({ course_id, quiz_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getQuizAttemptsSuccess,
    url: `my-learning/courses/${course_id}/quizzes/${quiz_id}/attempts`,
    method: "get",
    loadingType: "quizAttempts",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const completeSessionRequest = ({
  course_id,
  section_id,
  course_session_id,
  ...variables
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: completeSessionSuccess,
    url: `my-learning/courses/${course_id}/materials/sections/${section_id}/sessions/${course_session_id}/completed`,
    loadingType: "completeSessions",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getStudentSavedListRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentSavedListSuccess,
    url: `user/saved-list`,
    loadingType: "savedList",
    method: "get",
    ...variables,
  };
  console.log(variables);
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const addCourseToSavedListRequest = ({ product_id, ...variables }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: addCourseToSavedListSuccess,
    url: `products/${product_id}/add-to-my-saved-list`,
    loadingType: "addToSavedlist",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const removeCourseFromSavedListFromProductRequest = ({ product_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: removeCourseToSavedListFromProductSuccess,
    url: `products/${product_id}/remove_from-my-saved-list`,
    loadingType: "removeSavedlistProduct",
    method: "delete",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const removeCourseFromSavedListFromProfileRequest = ({
  bookmark_id,
}) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: removeCourseToSavedListFromProfileSuccess,
    url: `user/saved-list/${bookmark_id}`,
    loadingType: "removeSavedlistProfile",
    method: "delete",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentCoursesListRequest = ({ page_number, page_size }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: getStudentCoursesListSuccess,
    url: `my-learning/courses?page[number]=${page_number}&page[size]=${page_size}`,
    loadingType: "getStudentCoursesList",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const showAllReviewsForCurrentCourseRequest = ({ product_id }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: showAllReviewsForCurrentCourseSuccess,
    url: `products/${product_id}/reviews`,
    loadingType: "allCourseReviews",
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const addReviewRequest = ({ product_id, ...variables }) => {
  const requestData = {
    isLoading: coursesLoading,
    successFunction: addReviewSuccess,
    url: `products/${product_id}/reviews`,
    loadingType: "addReview",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
