import { Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { useThemePalette } from '../../../common/hooks/useThemePalatte'

export default function PrivacyAndTermsSkleton({ pageTitle, content }) {
    const { blackTwo, warmGray } = useThemePalette()
    return <>
        <Container className='footer' sx={{ my: '40px' }}>
            <Typography color={blackTwo} fontSize='24px' fontWeight='700'>{pageTitle}</Typography>
            <Paper sx={{ p: '24px', mt: '26px', mb: '40px', boxShadow: 'none', borderRadius: '0' }} >
                <Paper sx={{ p: '0 24px 0 0', height: '555px', overflowY: 'scroll', boxShadow: 'none' }} >
                    <Typography color={warmGray}>
                        {content}
                    </Typography>
                </Paper>
            </Paper>
        </Container>
    </>
}
