import { Box, Stack } from "@mui/material";
import logo from "../../../../Assets/Images/inclass.svg";
import { Typography } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from '@mui/icons-material/Instagram';

export default function FooterDescription() {
  
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box >
        <img src={logo} className="w-100 footer-logo" alt="InClass logo" />
      </Box>
      <Box>
        <Typography fontWeight="400" fontSize="20px" my="2.5rem">
          Lorem Ipsum has been <br/>
          the industry's standard <br/>
          dummy 1500s, when an <br/>
          unknown printer took a <br/>
          galley.
        </Typography>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        color="primary.main"
        width="100%"
      >
         <a href="https://www.facebook.com/inovaeg" target="_blank" rel="noopener noreferrer">
          <FacebookRoundedIcon sx={{ color: 'primary.main' }}/>
        </a>
        <a href="https://twitter.com/inovaeg" target="_blank" rel="noopener noreferrer">
          <TwitterIcon sx={{ color: 'primary.main' }} />
        </a>
        <a href="https://www.youtube.com/@Inovaeg" target="_blank" rel="noopener noreferrer">
          <YouTubeIcon sx={{ color: 'primary.main' }} />
        </a>
        <a href="https://www.instagram.com/inovaeg" target="_blank" rel="noopener noreferrer">
          <InstagramIcon sx={{ color: 'primary.main' }} />
        </a>
        <a href="https://www.linkedin.com/company/inovaeg" target="_blank" rel="noopener noreferrer">
          <LinkedInIcon sx={{ color: 'primary.main' }}/>
        </a>
      </Stack>
    </Stack>
  );
}
