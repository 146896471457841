import CustomContainer from 'components/Atoms/SharedComponents/LandingPage/CustomContainer';
import React, { useState, useEffect } from 'react';
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useThemePalette } from 'common/hooks/useThemePalatte';
import { Stack, Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment, Select, MenuItem, FormHelperText, } from '@mui/material';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import en from '../../../../../node_modules/react-phone-number-input/locale/en.json';
import PhoneInput from 'components/Atoms/Input/PhoneInput/PhoneInput';
import { signupSchema } from 'components/Organisms/Auth/validation';
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import Checkbox from '@mui/material/Checkbox';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import GoogleLogo from "../../../../Assets/Images/Google.png";
import InstaLogo from "../../../../Assets/Images/Instagram.png";
import Xlogo from "../../../../Assets/Images/Vector.png";
import FacebookLogo from "../../../../Assets/Images/facebook.png";
import setAuthToken from "../../../../services/Modules/utils/handel_api";
import {signupRequest} from "../../../../services/Landing Page/Signup"

export default function SignUp() {
    const { warmGray, blackTwo } = useThemePalette();
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [searchParam, setSearchParams] = useSearchParams();

    const configObj = {
        phoneNumber: true,
        email: false,
      };
    const initialValues= {
        name: "",
        lastname:"",
        email: "",
        phone_number: "",
        password: "",
        country_code: "20",
        organization: "",
    }
    const onSubmit = (values) => {
        const action = () => {
            setAuthToken(token);
            localStorage?.setItem("userToken", token);
          };
        const requestData = {
            body:{
                user: {
                    "name":values?.name,
                    "email": values?.email,
                    "password": values?.password,
                    "country_code": values?.country_code,
                    "phone_number": values?.phone_number,
                    },
                device: {
                    "uid": '231',
                    "fcm_token": "d"
                }
            },
            action
        };
          dispatch(signupRequest({ ...requestData}));
    };
      
    
    
    useEffect(() => {
      token && localStorage?.setItem("userToken", token);
    }, [token]);
    
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: signupSchema
    })
    const [selectedCountry, setSelectedCountry] = useState("");

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        formik.setFieldValue("country", event.target.value);
    };

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    return (
        <CustomContainer>
            <Stack direction="column" justifyContent="space-between" py={15}>
                <Typography variant='h2' marginBottom='1rem' color='primary.main' fontSize='40px' fontWeight='600' marginRight='50px'>
                    Sign Up
                </Typography>
                <Typography variant="body1" color={warmGray} sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '36px'}}>
                    Create Your Account In Seconds
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" width='50%' justifyContent='space-between' flexWrap='wrap' >
                    <Box width={{ lg: '48%', sm: '100%' }}>
                                <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                    First Name
                                </Typography>
                                <TextFieldInput
                                    id="name"
                                    placeholder="Enter your First name"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    name="name"
                                    value={formik.values.name}
                                    error={formik.errors.name}
                                    sx={{width:'100%'}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box  sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <AccountCircleOutlinedIcon sx={{ color: {blackTwo}, mr: 1 }}/>
                                                    <Box sx={{ borderRight: '1px solid #ccc', height: 20, mr: 1 }} />
                                                </Box>
                                            </InputAdornment>
                                        ),
                                        
                                    }}
                                />
                                {formik.touched.name && Boolean(formik.errors.name) && (
                                    <FormHelperText sx={{ color: "#ef5350", marginTop: "5px" }}>
                                        {formik.errors.name}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Box width={{ lg: '48%', sm: '100%' }}>
                                <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                    Last Name
                                </Typography>
                                <TextFieldInput
                                    id="lastname"
                                    placeholder="Enter your Last name"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    name="lastname"
                                    value={formik.values.lastname}
                                    error={formik.errors.lastname}
                                    sx={{width:'100%'}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                              <Box  sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AccountCircleOutlinedIcon sx={{ color: {blackTwo}, mr: 1 }}/>
                                                <Box sx={{ borderRight: '1px solid #ccc', height: 20, mr: 1 }} />
                                                </Box>
                                            </InputAdornment>
                                        ),
                                        
                                    }}
                                />
                                {formik.touched.lastname && Boolean(formik.errors.lastname) && (
                                    <FormHelperText sx={{ color: "#ef5350", marginTop: "5px" }}>
                                        {formik.errors.lastname}
                                    </FormHelperText>
                                )}
                        </Box>
                        <Box width={{ lg: '48%', sm: '100%' }}>
                                <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                    Enter your email
                                </Typography>
                                <TextFieldInput
                                    id="email"
                                    placeholder="Enter your email"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    name="email"
                                    value={formik.values.email}
                                    error={formik.errors.email}
                                    sx={{width:'100%'}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                 <Box  sx={{ display: 'flex', alignItems: 'center' }}>
                                                <EmailOutlinedIcon sx={{ color: {blackTwo}, mr: 1 }}/>
                                                <Box sx={{ borderRight: '1px solid #ccc', height: 20, mr: 1 }} />
                                                </Box>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {formik.touched.email && Boolean(formik.errors.email) && (
                                    <FormHelperText sx={{ color: "#ef5350", marginTop: "5px" }}>
                                        {formik.errors.email}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Box width={{ lg: '48%', sm: '100%' }}>
                            <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                Phone Number
                            </Typography>
                            <FormControl variant="outlined">
                            <PhoneInput
                                    id={"phone_number"}
                                    name={"phone_number"}
                                    className="normal-textField user-phone"
                                    selectId={"search-select"}
                                    inputValue={formik?.values?.phone_number}
                                    inputType={"number"}
                                    setPhone={(e) =>
                                    formik?.setValues({
                                        ...formik?.values,
                                        phone_number: e.target.value,
                                    })
                                    }
                                    placeholder={"Phone number"}
                                    labels={en}
                                    value={formik?.values?.country_code}
                                    onChange={(value) =>
                                    formik?.setValues({
                                        ...formik?.values,
                                        country_code: value,
                                    })
                                    }
                                    codePlus={true}
                                    error={
                                    formik?.touched?.phone_number &&
                                    Boolean(formik?.errors?.phone_number)
                                    }
                                    helperText={
                                    formik?.touched?.phone_number && formik?.errors?.phone_number
                                    }
                                />
                                   
                                {formik.touched.phone_number && Boolean(formik.errors.phone_number) && (
                                    <FormHelperText sx={{ color: "#ef5350", marginTop: "5px" }}>
                                        {formik.errors.phone_number}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        <Box width={{ lg: '48%', sm: '100%' }}>
                            <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                Country or Region
                            </Typography>
                            <PhoneInput
                                id={"country"}
                                FormControlWidth="100% "
                                name={"country"}
                                selectId={"search-select"}
                                displayCountry={true}
                                labels={en}
                                value={formik?.values?.country}
                                onChange={(value) =>
                                    formik?.setValues({
                                        ...formik?.values,
                                        country: value,
                                    })
                                }
                                error={formik?.touched?.country && Boolean(formik?.errors?.country)}
                                helperText={formik?.touched?.country && formik?.errors?.country}
                            />
                        </Box>
                        <Box width={{ lg: '48%', sm: '100%' }}>
                                <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                    Organization
                                </Typography>
                                <TextFieldInput
                                    id="organization"
                                    placeholder="Enter your organization"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    name="organization"
                                    value={formik.values.organization}
                                    sx={{width:'100%'}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                 <Box  sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <BusinessOutlinedIcon sx={{ color: {blackTwo}, mr: 1 }}/>
                                                    <Box sx={{ borderRight: '1px solid #ccc', height: 20, mr: 1 }} />
                                                </Box>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box width={{ lg: '48%', sm: '100%' }}>
                            <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                Password
                            </Typography>
                            <FormControl variant="outlined" sx={{width:'100%'}}>
                                <OutlinedInput
                                    placeholder="Enter your password"
                                    id="password"
                                    onChange={formik.handleChange}
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    error={formik?.errors?.error}
                                    startAdornment={
                                        <InputAdornment position="start">
                                             <Box  sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <LockOutlinedIcon sx={{ color: {blackTwo}, mr: 1 }}/>
                                                    <Box sx={{ borderRight: '1px solid #ccc', height: 20, mr: 1 }} />
                                             </Box>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                          >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                />
                            </FormControl>
                            {formik.touched.password && Boolean(formik.errors.password) && (
                                <FormHelperText sx={{ color: "#ef5350", marginTop: "5px" }}>
                                    {formik.errors.password}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box width={{ lg: '48%', sm: '100%' }}>
                            <Typography py={2} sx={{ pb: "16px" }} color={blackTwo} fontWeight="600">
                                Confirm Password
                            </Typography>
                            <FormControl variant="outlined" sx={{width:'100%'}}>
                                <OutlinedInput
                                    placeholder="Confirm your password"
                                    id="password"
                                    onChange={formik.handleChange}
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    error={formik?.errors?.error}
                                    startAdornment={
                                        <InputAdornment position="start">
                                             <Box  sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <LockOutlinedIcon sx={{ color: {blackTwo}, mr: 1 }}/>
                                                    <Box sx={{ borderRight: '1px solid #ccc', height: 20, mr: 1 }} />
                                             </Box>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                          >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                />
                            </FormControl>
                            {formik.touched.password && Boolean(formik.errors.password) && (
                                <FormHelperText sx={{ color: "#ef5350", marginTop: "5px" }}>
                                    {formik.errors.password}
                                </FormHelperText>
                            )}
                        </Box>
                        <Stack py={5}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails"  />}
                                label="I agree To The Terms of Service and Privacy Policy."
                                sx={{ color: 'gray', fontSize: '20px', fontWeight: 600 }}
                             />
                        </Stack>
                          <Button variant="contained" type='submit' color="primary" sx={{width: '50%' }}>Sign Up</Button>
                    </Stack>
                </form>
                <Stack direction="row" alignItems="center" py={5}>
                    <Typography fontSize="20px" fontWeight="bold">
                        Already have an account?
                    </Typography>
                    <Button sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}>
                        Log in
                    </Button>
                </Stack>
                <Box sx={{ width:'50%',display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <Box sx={{ width: '25%', borderBottom: '1px solid #ccc', mr: '1rem' }} />
                    <Typography variant="body1"  fontWeight="600" color="gray" lineHeight='30px'>
                        Or Continue With
                    </Typography>
                    <Box sx={{ width: '25%', borderBottom: '1px solid #ccc', ml: '1rem' }} />
                </Box>
            </Stack >
        <Stack
            direction="row"
            width='35%'
            justifyContent="space-between"
            display='flex'
            mb={10}
        >
            <img src={GoogleLogo} alt="Google" />
            <img src={InstaLogo} alt="Instagram" />
            <img src={Xlogo} alt="Vector" />
            <img src={FacebookLogo} alt="Facebook" />
        </Stack>
     </CustomContainer >
    );
}
