import React from 'react';
import { Typography, Button, Box, Stack } from '@mui/material';

const PricingContent = ({ title, price, points,subtitleText }) => {
    return (
        
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="stretch"
            height="100%"
            boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
            borderRadius="50px"
            bgcolor="info.main"
            width= "100%"
        >
            {/* ----------------------------- Cart Content ---------------------------- */}
            <Box sx={{ px: '16px', py: '16px', flex: 1 }}>
                {/* -------------------------- Cart Title ----------------------------- */}
                <Typography
                    fontWeight="700"
                    fontSize={{ xs: "20px", md: "24px" }}
                    textAlign="center"
                >
                    {title}
                </Typography>
                {/* --------------------------------  Price  ------------------------------------ */}
                <Box sx={{ textAlign: 'center', mt: '0.5rem' }}>
                    {/* -------------------------------- Price -------------------------------- */}
                    <Typography fontSize={{ xs: "32px", md: "40px" }} fontWeight="700" color="primary">
                        {price}
                    </Typography>
                    {/* -------------------------------- Subtitle -------------------------------- */}
                    <Typography variant="caption" textAlign="center" mt="0.25rem">
                        {subtitleText && subtitleText }
                    </Typography>
                    {/* -------------------------------- WarmGray Line -------------------------------- */}
                    <Box borderTop="1px solid" borderColor="gray" mt="0.5rem" />
                </Box>

                {/* --------------------------------  Cart Description  ------------------------------------ */}
                <Box color="gray" textAlign="center" flex={1}>
                    {Array.isArray(points) ? (
                        <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                            {points.map((point, index) => (
                                <li key={index}>
                                    <Typography 
                                        fontWeight='400'
                                        color="gray"
                                        fontSize={{ xs: "16px", md: "20px" }}
                                        marginY="0.5rem"
                                    >
                                        {point}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <Typography component="div" fontSize="11px" marginY="0.5rem">
                            {points}
                        </Typography>
                    )}
                </Box>
            </Box>

            {/* --------------------------------------- Cart Actions  -------------------------------------- */}
            <Box sx={{ px: '16px', pb: '16px' }}>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "flex-start", md: "center" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                    spacing={2}
                >
                    {/* -------------------------------- Enroll Now Button ------------------------- */}
                    <Button
                        sx={{ my: { xs: "0.5rem", md: "1.5rem" }, width: 'auto' }}
                        variant="contained"
                        aria-label="buy now"
                    >
                        Buy Now
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default PricingContent;
