import React from 'react';
import { Typography, Box, Stack } from '@mui/material';

const ContactUsCards = ({ data, img, points }) => {
    return (
       
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="stretch"
            height="100%"
            boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
            borderRadius="50px"
            bgcolor="info.main"
            width= "100%"
        >
            {/* ----------------------------- Cart Content ---------------------------- */}
            <Box>
                    <img src={data.img} alt="" width="100%"  />
            </Box>

                {/* --------------------------------  Cart Description  ------------------------------------ */}
                <Box color="gray" textAlign="center" px={3} flex={1}>
                    {Array.isArray(points) ? (
                        <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                            {points.map((point, index) => (
                                <li key={index}>
                                    <Typography 
                                        fontWeight='400'
                                        color="gray"
                                        fontSize={{ xs: "16px", md: "25px" }}
                                        marginY="0.5rem"
                                    >
                                        {point}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <Typography component="div" fontSize="11px" marginY="0.5rem">
                            {points}
                        </Typography>
                    )}
                </Box>
            </Box>

    );
};

export default ContactUsCards;
