import React from 'react';
import { Stack } from "@mui/material";
import GetInTouch from 'components/Molecules/LandingPage/Home/GetInTouch';
import Blog from 'components/Molecules/LandingPage/Blog/Blog';


const BlogTemplate = ({BlogData, t}) => {
    return (

        <Stack gap={10}>
          <Blog BlogData={BlogData} t={t}/>
          <GetInTouch/>
        </Stack>
      );

};

export default BlogTemplate;
