import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { Stack } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useThemePalette } from '../../../common/hooks/useThemePalatte';

export default function FooterContent({ title, list, ...rest }) {
    const navigate = useNavigate()
    const [isActive, setIsActive] = useState()
    const { secondryColor, blackTwo } = useThemePalette()
    const { pathname } = useLocation()
    return <>
        <Stack {...rest}>
            <Typography
                fontSize='20px'
                fontWeight='600'
                color='black'
            >
                {title}
            </Typography>
            <List>
                {list && list.map((item, i) =>
                    <ListItem key={i} sx={{ pl: '0' }}>
                        <ListItemText
                            sx={{ m: '0', cursor: 'pointer', color: isActive == i && pathname == item?.path ? secondryColor : blackTwo }}
                            primary={item.listText}
                            onClick={_ => {
                                navigate(item?.path)
                                setIsActive(i)
                            }}
                        />
                    </ListItem>
                )}
            </List>
        </Stack>
    </>
}