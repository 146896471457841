import WalletTemplate from 'Template/Profile/Wallet.Template'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getListTransactionsRequest } from 'services/Modules/Wallet'
// import { getListTransactionsRequest } from 'services/Modules/Courses'

export default function WalletPage() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getListTransactionsRequest({ page_number: 1, page_size: 3 }))
    }, [])

    const { transactionsData } = useSelector(state => state?.wallet)

    return <WalletTemplate transactionsData={transactionsData} />
}
