import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Stack,
  TextField,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { signupSchema } from "./validation";
// import PhoneInput from 'react-phone-input-2';
import {
  loginModel,
  signupModel,
  verificationModel,
} from "../../../services/Modules/Models/Actions";
import setAuthToken from "../../../services/Modules/utils/handel_api";
import { useThemePalette } from "../../../common/hooks/useThemePalatte";
import ModelBox from "../../Atoms/Model/ModalBox";
import { ExampleRequest, signupRequest } from "../../../services/Modules/Auth";
import PhoneInput from "../../Atoms/Input/PhoneInput/PhoneInput";
import en from "../../../../node_modules/react-phone-number-input/locale/en.json";
import { useSearchParams } from "react-router-dom";
import { DeviceUUID } from "device-uuid";

export default function SignupForm() {
  const { blackTwo, babyBlue } = useThemePalette();
  const { isOpenSignup } = useSelector((state) => state.model);
  const { token } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [searchParam, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const configObj = {
    phoneNumber: true,
    email: false,
  };

  const initialValues = {
    name: "",
    email: "",
    phone_number: "",
    password: "",
    country_code: "20",
  };

  const onSubmit = (values) => {
    console.log(values?.phone_number);
    console.log(values);
    const obj = {
      user: {
        name: values?.name,
        email: values?.email,
        password: values?.password,
      },
      device: {
        uid: "262222",
        fcm_token: "i",
      },
    };

    const action = () => {
      dispatch(signupModel());
      setAuthToken(token);
      localStorage?.setItem("userToken", token);
      localStorage.setItem(
        "configData",
        configObj?.phoneNumber ? "mobile" : "email"
      );
      dispatch(verificationModel());
      setSearchParams(
        configObj?.phoneNumber
          ? {
            phoneNumber: values?.phone_number,
            country_code: formik?.values.country_code,
            signUp: "true",
          }
          : { email: values?.email, signUp: "true" }
      );
    };
    dispatch(signupRequest({ body: obj, action }));
  };

  useEffect(() => {
    token && localStorage?.setItem("userToken", token);
  }, [token]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: signupSchema,
  });

  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <ModelBox
        openVar={isOpenSignup}
        closeFunc={() => {
          dispatch(signupModel())
          // formik?.setValues({...initialValues})
        }}
        title="Sign up"
        titleStyle={{ fontSize: "32px", fontWeight: "700", mb: "64px" }}
      >
        <form onSubmit={formik?.handleSubmit}>
          <Stack sx={{ pb: "16px" }}>
            <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
              Full Name
            </Typography>
            <TextField
              id="outlined-basic"
              hiddenLabel
              placeholder="Enter your full name"
              variant="outlined"
              onChange={formik?.handleChange}
              name="name"
              value={formik?.values?.name}
              error={formik?.errors?.name}
            />
            {formik?.touched?.name && Boolean(formik?.errors?.name) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                {formik?.errors?.name}
              </FormHelperText>
            )}
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
              Enter your email
            </Typography>
            <TextField
              id="outlined-basic"
              hiddenLabel
              placeholder="Enter your email"
              variant="outlined"
              onChange={formik?.handleChange}
              name="email"
              value={formik?.values?.email}
            />
            {formik?.touched?.email && Boolean(formik?.errors?.email) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                {formik?.errors?.email}
              </FormHelperText>
            )}
          </Stack>

          <Stack sx={{ pb: "16px" }}>
            <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
              Phone Number
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <PhoneInput
                id={"phone_number"}
                name={"phone_number"}
                className="normal-textField user-phone"
                selectId={"search-select"}
                inputValue={formik?.values?.phone_number}
                inputType={"number"}
                setPhone={(e) =>
                  formik?.setValues({
                    ...formik?.values,
                    phone_number: e.target.value,
                  })
                }
                placeholder={"Phone number"}
                labels={en}
                value={formik?.values?.country_code}
                onChange={(value) =>
                  formik?.setValues({
                    ...formik?.values,
                    country_code: value,
                  })
                }
                codePlus={true}
                error={
                  formik?.touched?.phone_number &&
                  Boolean(formik?.errors?.phone_number)
                }
                helperText={
                  formik?.touched?.phone_number && formik?.errors?.phone_number
                }
              />
            </Stack>
          </Stack>
          <Stack sx={{ pb: "16px" }}>
            <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
              Password
            </Typography>
            <FormControl variant="outlined">
              <OutlinedInput
                placeholder="Enter your password"
                id="outlined-adornment-password"
                onChange={formik?.handleChange}
                name="password"
                type={showPassword ? "text" : "password"}
                value={formik?.values?.password}
                error={formik?.errors?.error}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {formik?.touched?.password && Boolean(formik?.errors?.password) && (
              <FormHelperText
                margin="0"
                sx={{
                  color: "#ef5350",
                  alignItems: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                {formik?.errors?.password}
              </FormHelperText>
            )}
          </Stack>
          <Stack>
            <Button type="submit" variant="contained">
              Sign Up
            </Button>
          </Stack>
          <Stack
            direction="row"
            sx={{ m: "auto", width: "fit-content", mt: "32px", mb: "10px" }}
            justifyContent="cenetr"
            alignItems="center"
          >
            <Typography
              fontFamily="Montserrat"
              color={babyBlue}
              fontWeight="300"
              fontSize="14px"
            >
              Already have an account?
            </Typography>
            <Button
              sx={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
              onClick={(_) => {
                dispatch(loginModel());
                dispatch(signupModel());
              }}
            >
              Login
            </Button>
          </Stack>
        </form>
      </ModelBox>
    </>
  );
}
