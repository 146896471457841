import { Button, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import cartImage from "../../../Assets/Images/Pattern-fill-b53e7856bc8eb9fcd6abc7acf8e412e6.png";
import emptyCart from "../../../Assets/Images/empty-cart.svg";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PromoCodeInput from "./PromoCodeInput";
import CardVoteIcons from "components/Atoms/SharedComponents/Cards/CardVoteIcons";
import { useThemePalette } from "common/hooks/useThemePalatte";
import { useNavigate } from "react-router-dom";

export default function CartBody({ cartItems, removeFromCart }) {
  const { lightGreen, yellow, gray, primaryColor } = useThemePalette();
  const navigate = useNavigate();
  const exploreCourses = () => {
    navigate("/website");
  };

  return (
    <>
      {cartItems?.CartList?.cart_items?.length > 0 ? (
        cartItems?.CartList?.cart_items?.map((cart) => (
          <>
            <Container
              key={cart?.cart_id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              {/* Left Section */}
              <Stack
                sx={{
                  flexDirection: "row",
                  flexGrow: 0,
                  width: { xs: "100%", sm: "60%" },
                  marginRight: { xs: 0, sm: 2 },
                  marginBottom: { xs: 2, sm: 0 },
                }}
              >
                <img
                  src={
                    cart?.product?.classified_product?.image_url
                      ? cart?.product?.classified_product?.image_url
                      : cartImage
                  }
                  alt=''
                  style={{ width: "50%", marginRight: 2, borderRadius: "30px" }}
                />
                <Stack sx={{ marginLeft: 2 }}>
                  <Typography variant='h4' lineHeight={2}>
                    {cart?.product?.classified_product?.title}
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      lineHeight={4}
                      variant='body2'
                      sx={{ marginRight: "4px", color: yellow }}
                    >
                      4.7
                    </Typography>
                    <CardVoteIcons
                      sx={{ margin: "0 2px" }}
                      final_rating_from_reviews_fontSize='14px'
                      reviews_number_font_size='11px'
                    />
                    <Typography variant='body2' sx={{ margin: 0 }}>
                      ({cart?.product?.reviews_number})
                    </Typography>
                  </Stack>

                  <Typography variant='body1' sx={{ color: gray }}>
                    {cart?.product?.classified_product?.description
                      ?.split(" ")
                      .slice(0, 20)
                      .join(" ")}
                  </Typography>
                </Stack>
              </Stack>

              {/* ========= Right Section ============= */}
              <Stack
                sx={{
                  width: { xs: "100%", sm: "auto" },
                }}
                spacing={7}
              >
                <Stack
                  sx={{ flexDirection: "row", justifyContent: "space-between" }}
                >
                  <Typography style={{ marginRight: "10px" }}>
                    {cart?.final_price?.amount}
                    {cart?.product?.original_price_currency?.EGP
                      ? cart?.product?.original_price_currency?.EGP
                      : cart?.product?.original_price_currency}
                  </Typography>
                  <Typography
                    style={{
                      textDecorationLine: "line-through",
                      color: lightGreen,
                    }}
                  >
                    {cart?.product?.original_price}
                    {cart?.product?.original_price_currency?.EGP
                      ? cart?.product?.original_price_currency?.EGP
                      : cart?.product?.original_price_currency}
                  </Typography>
                </Stack>
                <Button
                  onClick={() => removeFromCart(cart?.product?.product_id)}
                >
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </Stack>
            </Container>
            <Container>
              <Stack sx={{ marginTop: "40px" }}>
                <Typography variant='h5' sx={{ marginBottom: "10px" }}>
                  Promo Code
                </Typography>
                <PromoCodeInput cartItems={cartItems} cart={cart} />
              </Stack>
              <Divider
                variant='fullWidth'
                sx={{
                  borderBottom: `1px solid ${lightGreen} `,
                  mt: 2,
                  mb: 2,
                }}
              />
            </Container>
          </>
        ))
      ) : (
        <Container
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={emptyCart}
            alt=''
            style={{ marginBottom: "20px", marginRight: "30px" }}
          />
          <Typography style={{ marginBottom: "20px" }} variant='h4'>
            Your Cart Is empty
          </Typography>
          <Button
            sx={{
              backgroundColor: primaryColor,
              color: "white",
              "&:hover": {
                backgroundColor: primaryColor,
              },
              paddingInline: "50px",
              marginBottom: "20px",
            }}
            onClick={() => exploreCourses()}
          >
            Explore courses
          </Button>
        </Container>
      )}
    </>
  );
}
