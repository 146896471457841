import { Stack, Container } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import CoursesCard from "../../../Atoms/SharedComponents/Cards/CoursesCard";
import CustomSlider from "../../../Atoms/SharedComponents/CustomSlider";
import Loading from "../../../Atoms/Loading/Loading";
import CoursesDetailsBoxTitle from "../../../Atoms/SharedComponents/CoursesDetailsBoxTitle";
import CoursesDetailsHeader from "../../../Molecules/Courses/CoursesOverview/CoursesDetailsHeader";
import CustomTabs from "../../../Molecules/Courses/CoursesOverview/CustomTabs";
import { useSearchParams } from "react-router-dom";

export default function CoursesOverview({
  studentCoursesDetails,
  reviews,
  studentCoursesDetailsLoading,
  filterdSubjectData,
}) {
  const { whiteTwo } = useThemePalette();
  const { isLoading } = useSelector((state) => state.courses);
  const [subjectName, setSubjectName] = useState();
  const [subjectId, setSubjectId] = useState();
  const [type, setType] = useState();
  const [searchParam] = useSearchParams();
  
  useEffect(() => {
    setSubjectName(searchParam.get("subject_name"));
    setSubjectId(searchParam.get("subject_id"));
    setType(searchParam?.get("type"));
  }, []);

  return (
    <>
      {isLoading?.type === "details" ? (
        <Stack
          width="100%"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Stack>
      ) : (
        <Stack bgcolor={whiteTwo}>
          <Container maxWidth="xl">
            <CoursesDetailsHeader
              instructors={
                studentCoursesDetails?.classified_product?.instructors
              }
              studentCoursesDetailsLoading={studentCoursesDetailsLoading}
              studentCoursesDetails={studentCoursesDetails}
              type={type}
              subjectId={subjectId}
              subjectName={subjectId}
            />
            <Stack>
              <CustomTabs
                studentCoursesDetails={studentCoursesDetails}
                studentCoursesDetailsLoading={studentCoursesDetailsLoading}
                reviews={reviews}
                subjectName={subjectName}
                subjectId={subjectId}
              />
            </Stack>
            <Box bgcolor="#fff" mt="32px" py="30px">
              <Container maxWidth="xl">
                <Box textAlign={{ md: "start", xs: "center" }}>
                  <CoursesDetailsBoxTitle
                    title={
                      subjectName === "All"
                        ? "All Courses"
                        : `More ${subjectName?.toLowerCase()} courses`
                    }
                  />
                </Box>
                <CustomSlider
                  className="courses-details"
                  slides1024={3}
                  slides1020={1}
                  slides600={1}
                >
                  {filterdSubjectData.map((data, i) => (
                    <Stack key={i} maxWidth="272px">
                      <CoursesCard data={data} />
                    </Stack>
                  ))}
                </CustomSlider>
              </Container>
            </Box>
          </Container>
        </Stack>
      )}
    </>
  );
}
