import React from 'react';
import { Stack } from "@mui/material";
import GetInTouch from 'components/Molecules/LandingPage/Home/GetInTouch';
import ContactUs from 'components/Molecules/LandingPage/Contact/ContactUs';


const ContactUsTemplate = ({ContactData}) => {
    return (

        <Stack gap={10}>
          <ContactUs ContactData={ContactData}/>
          <GetInTouch/>
        </Stack>
      );

};

export default ContactUsTemplate;
