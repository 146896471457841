import { Stack, Typography } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getMeetingInfoRequest, getZoomCredRequest } from 'services/Modules/ZoomIntegration';
// import { ZoomMtg } from '@zoomus/websdk';

// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.12.2/lib', '/av');

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load('en-US')
// ZoomMtg.i18n.reload('en-US')

export default function ZoomMeeting() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getZoomCredRequest())
        dispatch(getMeetingInfoRequest({
            body: {
                "course_session_id": 201,
                "session_id": 189
            }
        }))
    }, [])

    const { zoomCred, meetingInfo } = useSelector(state => state.zoom)
    // console.log(meetingInfo?.session, 'meetingInfo');
    // console.log(meetingInfo?.session?.meeting_id, 'sssss');
    // console.log(meetingInfo?.session?.meeting_password, 'meeting_password');

    // let payload = {
    //     meetingNumber: "73253339536",
    //     role: 0,
    //     sdkKey: '3h0i2BOQoxdSMrLVEbHWEYYDDhP8TZgK1Ycp',
    //     sdkSecret: 'cDKwdyUYYuNySSkygxyJK9KxA0A99tK0dT2z',
    //     passWord: '1jD0v4',
    //     leaveUrl: 'http://localhost:3000',
    //     userName: 'hager',
    //     userEmail: ''
    // }

    let payload = {
        meetingNumber: meetingInfo?.session?.meeting_id,
        role: 0,
        sdkKey: zoomCred?.sdk_key,
        sdkSecret: zoomCred?.sdk_secret,
        passWord: meetingInfo?.session?.meeting_password,
        leaveUrl: 'http://localhost:3000',
        userName: 'hager',
        userEmail: ''
    }

    const getCases = async () => {
        const { ZoomMtg } = await import('@zoomus/websdk');
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.12.2/lib', '/av')
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
        ZoomMtg.i18n.load('en-US')
        ZoomMtg.i18n.reload('en-US')
        ZoomMtg.record(({
            record: false
        }))
        ZoomMtg.showRecordFunction({
            show: false
        });
        ZoomMtg.generateSDKSignature({
            meetingNumber: payload?.meetingNumber,
            role: payload?.role,
            sdkKey: payload?.sdkKey,
            sdkSecret: payload?.sdkSecret,
            success: function (signature) {
                ZoomMtg.init({
                    leaveUrl: payload?.leaveUrl,
                    success: (success) => {
                        console.log(success, 'aa')
                        ZoomMtg.join({
                            signature: signature,
                            meetingNumber: payload?.meetingNumber,
                            userName: payload?.userName,
                            sdkKey: payload?.sdkKey,
                            sdkSecret: payload?.sdkSecret,
                            userEmail: payload?.userEmail,
                            passWord: payload?.passWord,
                            success: (success) => {
                                console.log(success, 'succ')
                            },
                            error: (error) => {
                                console.log(error, 'err')
                            }
                        })
                    },
                    error: (error) => {
                        console.log(error, 'err2')
                    }
                })
            },
            error: function (error) {
                console.log(error, 'err3');
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        payload && getCases()
    }, [payload])

    return <>
        <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.12.2/css/bootstrap.css" />
        <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.12.2/css/react-select.css" />
    </>
}
