import { reduxRequest } from "common/utils/reduxRequest";
import { getMeetingInfoSuccess, getZoomCredSuccess, zoomLoading } from "./Actions";


export const getZoomCredRequest = () => {
  const requestData = {
    isLoading: zoomLoading,
    successFunction: getZoomCredSuccess,
    url: `zoom/credentials`,
    loadingType: "credentials",
    method:'get',
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const getMeetingInfoRequest = ({...variables}) => {
  const requestData = {
    isLoading: zoomLoading,
    successFunction: getMeetingInfoSuccess,
    url: `/zoom/get_session`,
    loadingType: "meetingInfo",
    ...variables
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

