import React from 'react'
import ModelBox from '../../Atoms/Model/ModalBox'
import { useDispatch, useSelector } from 'react-redux'
import { createNewPasswodModal } from '../../../services/Modules/Models/Actions'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useFormik } from 'formik';
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { useThemePalette } from '../../../common/hooks/useThemePalatte';
import { useState } from 'react';
import { resetPasswordRequest } from '../../../services/Modules/Auth';
import { resetPasswordSchema } from './validation';
import { useSearchParams } from 'react-router-dom';


export default function CreateNewPasswordForm() {
    const { isCreatedNewPassword } = useSelector(state => state?.model)
    const dispatch = useDispatch()
    const { blackTwo } = useThemePalette();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const email = searchParams.get('email')
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmationPassword = () => setShowConfirmationPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // const test = (event) => {
    //     event.preventDefault();
    // };

    const initialValues = {
        password: "",
        confirmation_password: '',
        email: email
    }

    const onSubmit = (values) => {
        const obj = {
            "user": {
                "password": values?.password,
                "password_confirmation": values?.confirmation_password,
                "email": values?.email
            },
            "device": {
                "uid": '15136',
                "fcm_token": "il"
            }
        }
        dispatch(resetPasswordRequest({ body: obj }))
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: resetPasswordSchema
    })
    return <>
        <ModelBox
            openVar={isCreatedNewPassword}
            closeFunc={() => { dispatch(createNewPasswodModal()) }}
            title="Create new password"
            titleStyle={{ fontSize: "32px", fontWeight: "700", mb: '32px' }}
            px='30px'
        >
            <form onSubmit={formik?.handleSubmit}>
                <Stack sx={{ pb: '16px' }}>
                    <Typography sx={{ pb: '16px' }} color={blackTwo} fontWeight='500'>Enter new password</Typography>
                    <FormControl variant="outlined" >
                        <OutlinedInput
                            placeholder='Enter password'
                            id="outlined-adornment-password"
                            onChange={formik?.handleChange}
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            value={formik?.values?.password}
                            error={formik?.errors?.error}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '&:hover': {
                                                bgcolor: 'transparent'
                                            }
                                        }}
                                    >
                                        {showPassword ? <VisibilityOutlinedIcon sx={{ color: 'black' }} /> : <VisibilityOffOutlinedIcon sx={{ color: 'black' }} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {formik?.touched?.password &&
                        Boolean(formik?.errors?.password) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.password}
                            </FormHelperText>
                        )}
                </Stack>
                <Stack sx={{ pb: '16px' }}>
                    <Typography sx={{ pb: '16px' }} color={blackTwo} fontWeight='500'>Re-enter new password</Typography>
                    <FormControl variant="outlined" >
                        <OutlinedInput
                            placeholder='Re-enter new password'
                            id="confirmation_password"
                            onChange={formik?.handleChange}
                            name='confirmation_password'
                            type={showConfirmationPassword ? 'text' : 'password'}
                            value={formik?.values?.confirmation_password}
                            error={formik?.errors?.error}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmationPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '&:hover': {
                                                bgcolor: 'transparent'
                                            }
                                        }}
                                    >
                                        {showConfirmationPassword ? <VisibilityOutlinedIcon sx={{ color: 'black' }} /> : <VisibilityOffOutlinedIcon sx={{ color: 'black' }} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {formik?.touched?.confirmation_password &&
                        Boolean(formik?.errors?.confirmation_password) && (
                            <FormHelperText
                                margin="0"
                                sx={{
                                    color: "#ef5350",
                                    alignItems: "start",
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}
                            >
                                {formik?.errors?.confirmation_password}
                            </FormHelperText>
                        )}
                </Stack>
                <Stack>
                    <Button type='submit' variant='contained'>Confirm</Button>
                </Stack>
            </form>
        </ModelBox >
    </>
}
