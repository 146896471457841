import React from 'react';
import { Stack } from "@mui/material";
import About from 'components/Molecules/LandingPage/About/About';
import Events from 'components/Molecules/LandingPage/About/Events';
import GetInTouch from 'components/Molecules/LandingPage/Home/GetInTouch';


const AboutUsTemplate = ({eventsData}) => {
    return (

        <Stack gap={10}>
          <About/>
          <Events eventsData={eventsData}/>
          <GetInTouch/>
        </Stack>
      );

};

export default AboutUsTemplate;
