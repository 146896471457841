import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import FooterDescription from "../../../Molecules/LandingPage/Footer/FooterDescription";
import FooterContent from "components/Molecules/LandingPage/Footer/FooterContent";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import EmailIcon from "../../../../Assets/Images/Envelope.svg";
import PhoneIcon from "../../../../Assets/Images/Phone.svg";
import CLogo from "../../../../Assets/Images/CLogo.svg"
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";

export default function Footer() {
  const { blackTwo, whiteTwo } = useThemePalette();

  const sitemapList = [
    { listText: "Features", path: '/features' },
    { listText: "Pricing", path: '/pricing' },
    { listText: "About", path: '/about-us' },
    { listText: "Blog", path: '/blog' },
    { listText: "Contact us", path: '/contact-us' },
  ];
  const SettingsList = [
    { listText: "Terms And Services" },
    { listText: "Privacy" },
    { listText: "Support" },
    { listText: "License" },
  ];

  const supportList = [
    { listText: "Email Address:", icon: <img src={EmailIcon} alt="icon" />, value: "sales@inovaeg.com", isContactInfo: true },
    { listText: "Phone Number:", icon: <img src={PhoneIcon} alt="icon" />, value: "(+2) 01025065179", isContactInfo: true },
  ];

  const lists = [
    { title: "Home", list: sitemapList },
    { title: "Legal", list: SettingsList },
    { title: "Contact Info", list: supportList },
  ];

  return (
    <Box
      height="auto"
      position="relative"
      right="0"
      left="-3px"
    >
      <Box bgcolor="#F3F3F3" height="auto">
        <CustomContainer>
          <Grid container columnSpacing={1} rowSpacing={3} color={blackTwo}>
            <Grid item xs={12} lg={4} sm={6} sx={{ marginTop: { xs: '20px', sm: '50px' }, marginBottom: { xs: '20px', sm: '50px' } }}>
              <Box display="flex" alignItems="center" height="auto">
                <FooterDescription />
              </Box>
            </Grid>
            {lists.map((list, i) => (
              <Grid key={i} item xs={12} lg sm={6} sx={{ marginTop: { xs: '20px', sm: '50px' } }}>
                <Box display="flex" alignItems="center" height="auto">
                  <FooterContent title={list.title} list={list.list} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </CustomContainer>
      </Box>

      <Box bgcolor={blackTwo} >
        <CustomContainer>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <img src={CLogo} alt="Copyrights Logo" />
            <Typography color={whiteTwo} style={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '19px',
              letterSpacing: '0em',
              textAlign: 'left'
            }}>
              2023 Inova All Rights Reserved
            </Typography>
          </Stack>
        </CustomContainer>
      </Box>
    </Box>
  );
}
