import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
export default function CustomAccordian({
  accordianTitle,
  accordianDetails,
  sectionId,
}) {
  const [expand, setExpand] = useState();
  const { section_id } = useParams();

  useEffect(() => {
    section_id == sectionId && setExpand(true);
  }, [sectionId]);

  return (
    <Accordion
      defaultExpanded={section_id == sectionId ? true : false}
      expanded={expand}
      sx={{ boxShadow: 0 }}
      style={{ background: "#F8F8F8" }}
    >
      <AccordionSummary
        onClick={() => setExpand(!expand)}
        expandIcon={<ExpandMoreIcon sx={{ color: "#28A19C" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
          {accordianTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {accordianDetails}
      </AccordionDetails>
    </Accordion>
  );
}
