import React from "react";
import FrequentSkelton from "components/Atoms/SharedComponents/LandingPage/FrequentSkelton";
import { Stack } from "@mui/material";
import CustomContainer from "components/Atoms/SharedComponents/LandingPage/CustomContainer";
import AllFeaturesContent from "../Home/AllFeaturesContent";

export default function OurFeatures() {
  return (
    <>
<CustomContainer>    
    <Stack 
        alignItems="center"
        sx={{ backgroundColor: "white" }}
        py={15}>
          
      {/*   ----------------- Custom Component Adding bg-color and some styles -----------------  */}
      <FrequentSkelton
        title="Our Features"
        subtitle="Inova E-learning application allows you to seamlessly create online courses under your brand and sell them. Backed with the best teaching and marketing tools, you can scale up your business and boost profits, no matter how many students you are teaching"
      ></FrequentSkelton>

        <AllFeaturesContent/>

    </Stack>
    </CustomContainer> 
    </>
  );
}
