import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import CoursesListSlider from "../../../Molecules/Courses/CoursesList/CoursesListSlider";
import DropDown from "../../../Molecules/Courses/CoursesList/DropDown";
import Loading from "../../../Atoms/Loading/Loading";
import CoursesCard from "../../../Atoms/SharedComponents/Cards/CoursesCard";
import PaginationComponent from "../../../Molecules/Courses/CoursesList/Pagination";
import dataBase from "../../../../db/edugramdb.json";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import { useSearchParams } from "react-router-dom";

export default function CoursesList({
  allSubjectData,
  PageChanging,
  dataPerPage,
  allCoursesList,
  isLoading,
}) {
  const pageCount = Math.ceil(allSubjectData.length / dataPerPage);
  const [filters, setFilters] = useState();
  const [existedchecks, setExistedchecks] = useState([]);
  const { whiteTwo, secondryColor } = useThemePalette();
  const [searchParams] = useSearchParams();

  const PaginationPage = (ClickedPage) => {
    PageChanging(ClickedPage);
  };

  const onApply = (filter) => {
    setFilters(...filter);
  };

  const modalChecked = (ele) => {
    if (existedchecks.indexOf(ele) === -1) {
      setExistedchecks([...existedchecks, ele]);
    } else {
      const Delete = existedchecks.filter((check, index) => {
        return check !== ele;
      });
      setExistedchecks(Delete);
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: whiteTwo, py: "5%" }}>
        <Container maxWidth='xl'>
          <Stack className='coursesListSlider'>
            <CoursesListSlider allSubjectData={allSubjectData} />
          </Stack>

          {/* ========================filter header======================== */}
          <Stack px='3%'>
            <Typography
              sx={{
                py: 3,
                fontSize: "23px",
                lineHeight: "45px",
                color: secondryColor,
              }}
            >
              {" "}
              Filtered By
            </Typography>
          </Stack>
          <Stack
            direction='row'
            gap={2}
            justifyContent='center'
            flexWrap='wrap'
          >
            {dataBase.filterCategory.map((item, index) => {
              return (
                <Stack sx={{ width: { xs: "100%", md: "15%" } }}>
                  <DropDown
                    data={item}
                    key={index}
                    onApply={onApply}
                    modalChecked={modalChecked}
                    existedchecks={existedchecks}
                  />
                </Stack>
              );
            })}
          </Stack>

          {/* ==========  cards  ========================== */}

          <Stack
            justifyContent='center'
            direction='row'
            sx={{
              mt: 2,
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            {isLoading?.type == "courses list" && isLoading?.loading ? (
              <Loading />
            ) : (
              allCoursesList?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      width: { xs: "100%", md: "22%" },
                    }}
                  >
                    <CoursesCard
                      data={item}
                      url={`/website/courses-details/${
                        item?.classified_product?.course_id
                      }?subject_name=${searchParams.get(
                        "subject_name"
                      )}&subject_id=${searchParams.get("subject_id")}`}
                    />
                  </Box>
                );
              })
            )}
          </Stack>

          {/* ======================Pagination===================== */}
          <Stack alignItems='center' sx={{ mt: "4%" }}>
            <PaginationComponent
              PaginationPage={PaginationPage}
              pageCount={pageCount}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
}
