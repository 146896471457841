import Types from "./Types";

export const getSubjecsSuccess = (payload) => ({
  type: Types.GET_SUBJECTS_SUCCESS,
  payload,
});

export const subjecsLoading = (payload) => ({
  type: Types.SUBJECTS_LOADING,
  payload,
});

export const getAllSubjecsSuccess = (payload) => ({
  type: Types.GET_ALL_SUBJECTS_SUCCESS,
  payload,
});

export const getFilteredSubjectsSuccess = (payload) => ({
  type: Types.GET_FILTERED_SUBJECTS_SUCCESS,
  payload,
});

export const getFilteredCoursesSuccess = (payload) => ({
  type: Types.GET_FILTERED_COURSES_SUCCESS,
  payload,
});
