import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CourseDetailsTemplate from "../../../Template/Courses/CourseDetails/CourseDetailsTemplate";
import { getStudentCoursesDetailsRequest } from "../../../services/Modules/Courses";
import { useParams } from "react-router-dom";

export default function CourseDetailsPage({setBgColor}) {
  const dispatch = useDispatch();
  const { course_id } = useParams();

  // console.log(course_id, 'course_id');

  useEffect(() => {
    dispatch(getStudentCoursesDetailsRequest({ course_id }));
    setBgColor("rgba(0, 0, 0, 0.07)")

  }, []);

  const { studentCoursesDetails } = useSelector((state) => state?.courses);
  // console.log("studentCoursesDetails", studentCoursesDetails);

  return (
    <CourseDetailsTemplate studentCoursesDetails={studentCoursesDetails} />
  );
}
