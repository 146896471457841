import React from "react";
import ModelBox from "../../Atoms/Model/ModalBox";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewPasswodModal,
  forgetPasswodModal,
  verificationModel,
} from "../../../services/Modules/Models/Actions";
import { useFormik } from "formik";
import {
  forgetPasswordByPhoneSchema,
  forgetPasswordByEmailSchema,
} from "./validation";
import { useThemePalette } from "../../../common/hooks/useThemePalatte";
import {
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "../../Atoms/Input/PhoneInput/PhoneInput";
import en from "../../../../node_modules/react-phone-number-input/locale/en.json";
import {
  checkEmailExistRequest,
  sendEmailOtpRequest,
  sendResetPasswordRequest,
} from "../../../services/Modules/Auth";
import CreateNewPasswordForm from "./CreateNewPasswordForm";
import { useSearchParams } from "react-router-dom";

export default function ForgetPasswordForm() {
  const { isOpenForgetPassword } = useSelector((state) => state?.model);
  const dispatch = useDispatch();
  const { blackTwo } = useThemePalette();
  const [searchParam, setSearchParams] = useSearchParams();

  const congigObj = {
    mobile: false,
    email: true,
  };

  const phoneInitialValues = {
    phone_number: "",
    country_code: "20",
  };

  const emailInitialValues = {
    email: "",
  };

  const onSubmit = (values) => {
    const obj = {
      user: {
        ...values,
      },
      device: {
        uid: "45156",
        fcm_token: "il",
      },
    };
    const sendEmailOtpData = {
      user: {
        ...values,
      },
    };

    // Third step ==> finally if everything ok it will close forgetpassword model and open verification model
    const sendEmailOtpAction = () => {
      dispatch(verificationModel()); // it will open verification model
      setSearchParams({ email: values?.email });
      dispatch(forgetPasswodModal()); // it will close this model
    };
    // Second Step ==> it will dispatch send otp request
    const emailOptAction = () => {
      dispatch(
        sendEmailOtpRequest({
          body: sendEmailOtpData,
          action: sendEmailOtpAction,
        })
      ); // it will send otp code
    };
    // First Step ==> it will check verfication by mobile or email and send request depens on this condition
    congigObj?.mobile
      ? dispatch(sendResetPasswordRequest({ body: obj }))
      : dispatch(checkEmailExistRequest({ body: obj, action: emailOptAction }));
  };

  const formik = useFormik({
    initialValues: congigObj?.mobile ? phoneInitialValues : emailInitialValues,
    onSubmit,
    validationSchema: congigObj?.mobile
      ? forgetPasswordByPhoneSchema
      : forgetPasswordByEmailSchema,
  });

  return (
    <>
      <ModelBox
        openVar={isOpenForgetPassword}
        closeFunc={() => dispatch(forgetPasswodModal())}
        title="Forget password?"
        titleStyle={{ fontSize: "32px", fontWeight: "700", mb: "64px" }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginBottom: "24px" }}
        >
          <Typography fontSize="14px" textAlign="center">
            Please enter the mobile number associated with your account to send
            you a verification code
          </Typography>
        </Stack>
        <form onSubmit={formik?.handleSubmit}>
          {congigObj?.mobile && (
            <Stack sx={{ pb: "16px" }}>
              <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
                Phone Number
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <PhoneInput
                  id={"forget_password-phone_number"}
                  name={"phone_number"}
                  className="normal-textField user-phone"
                  selectId={"search-select"}
                  inputValue={formik?.values?.phone_number}
                  inputType={"number"}
                  setPhone={(e) =>
                    formik?.setValues({
                      ...formik?.values,
                      phone_number: e.target.value,
                    })
                  }
                  placeholder={"Phone number"}
                  labels={en}
                  value={formik?.values?.country_code}
                  onChange={(value) =>
                    formik?.setValues({
                      ...formik?.values,
                      country_code: value,
                    })
                  }
                  codePlus={true}
                  error={
                    formik?.touched?.phone_number &&
                    Boolean(formik?.errors?.phone_number)
                  }
                  helperText={
                    formik?.touched?.phone_number &&
                    formik?.errors?.phone_number
                  }
                />
              </Stack>
            </Stack>
          )}
          {congigObj?.email && (
            <Stack sx={{ pb: "16px" }}>
              <Typography sx={{ pb: "16px" }} color={blackTwo} fontWeight="500">
                Enter your email
              </Typography>
              <TextField
                id="outlined-basic"
                hiddenLabel
                placeholder="Enter your email"
                variant="outlined"
                onChange={formik?.handleChange}
                name="email"
                value={formik?.values?.email}
              />
              {formik?.touched?.email && Boolean(formik?.errors?.email) && (
                <FormHelperText
                  margin="0"
                  sx={{
                    color: "#ef5350",
                    alignItems: "start",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {formik?.errors?.email}
                </FormHelperText>
              )}
            </Stack>
          )}
          <Stack>
            <Button
              type="submit"
              variant="contained"
              sx={{ py: "10px", my: "30px" }}
            >
              Send
            </Button>
          </Stack>
        </form>
      </ModelBox>
    </>
  );
}
