
import React from 'react'
import CartHeader from 'components/Molecules/Cart/CartHeader';
import CartBody from 'components/Molecules/Cart/CartBody';
import PaymentSection from 'components/Molecules/Cart/PaymentMethods';
import PricingSummary from 'components/Molecules/Cart/PricingSummary';
import Payment from 'components/Molecules/Cart/Payment';

export default function Cart({ cartItems, removeFromCart }) {
  return (
    <>
      <CartHeader />
      <CartBody cartItems={cartItems} removeFromCart={removeFromCart} />
      {cartItems?.CartList?.cart_items?.length > 0 ? (
        <Payment cartItems={cartItems}  />
      ) : (
        ""
      )}
    </>
  );
}

 