import React from "react";
import nounPage2 from "../../../../Assets/Images/noun-page-2.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoDataYet from "../../../Atoms/NoDataYet";
import LabelSvg from "../../../Atoms/SharedComponents/LabelSvg";
import nounPage1 from "../../../../Assets/Images/noun-page-1.svg";
import { useThemePalette } from "../../../../common/hooks/useThemePalatte";
import Breadcrumb from "../../../Atoms/Breadcrumbs/Breadcrumb";
import OutlineTree from "../../../Molecules/Courses/EbookContent/OutlineTree";
import BookControls from "../../../Molecules/Courses/EbookContent/BookControls";
import Controles from "../../../Molecules/Courses/EbookContent/Controls";

export default function Ebook({
  scale,
  setScale,
  pageNumber,
  setPageNumber,
  setNumPages,
  setBookmarkedPages,
  bookmarkedPages,
  session,
  isOnePage,
  breadcrumb,
  setOnePage,
  numPages,
  removeBookmark,
  createBookmark,
  isPageBookmarked,
  isLoading,
}) {
  const {
    primaryColor,
    lightGreen,
    blackTwo,
    transparentGray,
    transparentBlackTwo,
    grayTwo,
    grayThree,
  } = useThemePalette();
  return (
    <>
      <Container sx={{ width: "100%", pb: "40px" }}>
        <Stack
          direction="row"
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: "bold",
            // padding: '20px !important',
          }}
        >
          <Box mt="33px" mb="13px">
            <Breadcrumb
              data={breadcrumb}
              breadcrumbsBgColor={""}
              iconColor={lightGreen}
            />
          </Box>
        </Stack>
        <Stack direction="row">
          <Stack sx={{ width: "30%" }} rowGap="32px">
            <Accordion
              sx={{ borderRadius: "6px", py: "12px", boxShadow: "none" }}
            >
              <AccordionSummary
                sx={{ height: "30px", minHeight: "30px !important" }}
                expandIcon={<ExpandMoreIcon sx={{ color: primaryColor }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  color={primaryColor}
                  fontSize="20px"
                  fontWeight="500"
                >
                  Content
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  {session?.book?.book_outlines?.map((val, index) => {
                    return (
                      <OutlineTree
                        data={val}
                        setPageNumber={setPageNumber}
                        pagesNumber={session?.book?.num_of_pages}
                        index={index}
                        pageNumber={pageNumber}
                        length={
                          index == session?.book?.book_outlines.length - 1 &&
                          index
                        }
                      />
                    );
                  })}
                </>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                borderRadius: "6px",
                py: "12px",
                boxShadow: "none",
                border: "none",
                "&:before": {
                  height: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: primaryColor }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography color={primaryColor}>Book marks</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  {bookmarkedPages?.length > 0 ? (
                    bookmarkedPages?.map((page, index) => {
                      return (
                        <Stack justifyContent="space-between" marginTop="8px">
                          <Stack
                            onClick={() => {
                              setPageNumber(page?.page_number);
                            }}
                            style={{
                              height: "100%",
                              marginLeft: "15px",
                              cursor: "pointer",
                              display: "flex",
                              borderBottom:
                                bookmarkedPages?.length - 1 != index &&
                                `1px solid ${transparentGray}`,
                            }}
                          >
                            <Typography
                              sx={{
                                colorL: blackTwo,
                                fontWeight: "600",
                                marginInline: "5px",
                              }}
                            >
                              Page {page?.page_number}
                            </Typography>
                            <Typography
                              sx={{
                                marginInline: "5px",
                                fontSize: "15px",
                                color: transparentBlackTwo,
                                pb: "24px",
                              }}
                            >
                              {page?.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    })
                  ) : (
                    <NoDataYet data={"book marks"} py={"1rem"} />
                  )}
                </>
              </AccordionDetails>
            </Accordion>
          </Stack>
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------- */}
          <Stack px={{ md: "80px" }} width="100%">
            <Stack
              my="16px"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography fontWeight="600" fontsize="18px">
                Page {pageNumber}
              </Typography>
              <Stack direction="row" columnGap={"16px"}>
                <Button
                  disableElevation
                  variant="outlined"
                  onClick={() => setOnePage(!isOnePage)}
                  style={{
                    width: "135px",
                  }}
                >
                  <img src={nounPage1} alt="logo" />
                  {isOnePage && (
                    <img src={nounPage2} style={{ marginLeft: "8px" }} alt="" />
                  )}
                </Button>
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="contained"
                    sx={{
                      fontWeight: "500",
                      bgcolor: isPageBookmarked(pageNumber) === true && grayTwo,
                      "&:hover": {
                        bgcolor:
                          isPageBookmarked(pageNumber) === true && grayTwo,
                      },
                    }}
                    onClick={() => {
                      !isPageBookmarked(pageNumber)
                        ? createBookmark(pageNumber)
                        : removeBookmark(
                            bookmarkedPages?.filter(
                              (val) => val?.page_number == pageNumber
                            )
                          );
                    }}
                    disableElevation
                  >
                    <LabelSvg
                      bgcolor={"transparent"}
                      color={"#fff"}
                      height={25}
                      width={25}
                    />
                    Bookmark
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Stack className="flex-column align-items-end px-lg-4">
              <Stack
                style={{ backgroundColor: grayThree }}
                className="col-sm-12 col-md-11 col-lg-12"
              >
                <BookControls
                  scale={scale}
                  setScale={setScale}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  numPages={numPages}
                  setNumPages={setNumPages}
                  setBookmarkedPages={setBookmarkedPages}
                  bookmarkedPages={bookmarkedPages}
                  session={session}
                  isOnePage={isOnePage}
                />
              </Stack>
              <Stack
                width="100%"
                className="col-sm-12 col-md-11 col-lg-9 p-4 align-self-center"
              >
                <Controles
                  bookmarkedPages={bookmarkedPages}
                  setBookmarkedPages={setBookmarkedPages}
                  setPageNumber={setPageNumber}
                  setScale={setScale}
                  pageNumber={pageNumber}
                  session={session}
                  numPages={numPages}
                  isLoading={isLoading}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
