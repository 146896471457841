import React from 'react';
import { Stack, Box, Typography, Grid, Link} from '@mui/material';
import { useThemePalette } from 'common/hooks/useThemePalatte';
import CustomContainer from 'components/Atoms/SharedComponents/LandingPage/CustomContainer';
import ContactUsCards from './ContactUsCards';
import FindUsForm from './FindUsForm';

const ContactUs = ({ ContactData , t}) => {
  const { blackTwo, babyBlue } = useThemePalette();
 

  return (
    <CustomContainer>
      <Stack
        alignItems="stretch"
        justifyContent="space-between"
        py={15}
      >
        <Stack direction="column"  justifyContent="space-between"   width={{ lg: '45%' , sm:"100%" }}>
            <Typography variant='h2'
                marginBottom='1rem'
                color='primary.main'
                fontSize='40px'
                fontWeight='600'
                marginRight='50px'>
                Contact Us       
            </Typography>
          <Grid container justifyContent="flex-start">
              <Grid item>
                <Link href="#" variant="body1" color={babyBlue}>
                     Send Us A direct Message.
                </Link>
              </Grid>
            </Grid>
            <Typography variant='h2'
                        marginBottom='1rem'
                        color='primary.main'
                        fontSize='30px'
                        fontWeight='600'
                        py={5}
                        >
                        Reach Us       
             </Typography>
             </Stack>
            <Stack
               direction={{ xs: 'column', sm: 'row' }} 
               justifyContent="space-between"
               flexWrap='wrap'
               width='100%'
             >
                {ContactData?.map((data, i) => (
                    <Stack key={i} width={{  md: '32%', xs: '100%',sm:'48%' }} mb="20px">
             
                      <ContactUsCards
                       img={data.img}
                       points={data.points}
                       data={data} 
                       t={t} />
                    </Stack>
                ))}
            </Stack>
            <Stack sx={{ width: { lg: '100%' } }}>
                <FindUsForm />
            </Stack>
      </Stack>
      </CustomContainer>
  );
};

export default ContactUs;
