import React from "react";
import { Typography, Stack } from "@mui/material";

export default function FooterContent({ title, list }) {
  return (
    <div>
      <Typography variant="h6" fontWeight="bold" marginBottom="30px">{title}</Typography>
      {list.map((item, index) => (
        <div key={index} style={{ marginBottom: "2rem" }}>
          <Stack direction="row" alignItems="flex-start" spacing={1}>
            {item.icon}
            <Stack>
              <Typography 
                variant="body1" 
                fontWeight={item.isContactInfo ? "bold" : "normal"}
                sx={{
                  fontSize: '20px',
                  fontWeight: 400,
                  lineHeight: '30px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                }}
              >
                {item.listText}
              </Typography>
              <Typography variant="body1">{item.value}</Typography>
            </Stack>
          </Stack>
        </div>
      ))}
    </div>
  );
}
