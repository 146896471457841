import { Box, Typography } from "@mui/material";
import React from "react";
import ChapterVideo from "../../../Molecules/Courses/VideoContent/ChapterVideo";
import NoDataYet from "../../../Atoms/NoDataYet";

export default function VideoContent({ sessionChossen }) {
  return (
    <>
      <Box>
        <Typography
          sx={{ color: "rgb(52, 52, 52)", fontWeight: 600, fontSize: "1.6rem" }}
        >
          {sessionChossen?.video_url ? (
            sessionChossen?.text.slice(0, 65)
          ) : (
            <NoDataYet data="video" />
          )}
        </Typography>
      </Box>
      <Box sx={{ my: 2, width: "100%" }}>
        <ChapterVideo
          closed={sessionChossen?.is_blocked}
          sessionChossen={sessionChossen}
        />
      </Box>
    </>
  );
}
